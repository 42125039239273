import Vue from 'vue'
import AlertVm from './index.vue';
const VueComponent = Vue.extend(AlertVm);

let vm = null;
const alertBox = function (opt) {
  vm = new VueComponent().$mount();
  document.body.appendChild(vm.$el);
  vm && Object.assign(vm, opt);
  return vm.show();
}

export default alertBox;
