/*
 * @Author: 赵伟
 * @Date: 2021-11-29 13:42:54
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-05-10 16:53:55
 * @Description: 白名单
 */

const whiteNames = [
  '/dashboard/plus',
  '/dashboard/overview-self',
  '/order/list',
  '/self-order/list',
  '/refund/list',
  '/user/list',
  '/user/list',
  '/dashboard/premium-settlement'
]

export const bShowBreadTitle = (val) => {
  if(whiteNames.includes(val)) return false;
  return true;
}
