/*
 * @Author: 建辉
 * @Date: 2021-08-06 11:00:46
 * @LastEditors: 建辉
 * @LastEditTime: 2021-08-06 11:10:48
 * @Description: 管理day.js
 */


import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/zh-cn';

dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.locale('zh-cn')


export default dayjs;
