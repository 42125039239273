<!--
 * @Author: 建辉
 * @Date: 2020-09-23 18:52:33
 * @LastEditors: 赵顺陈
 * @LastEditTime: 2021-04-07 17:59:45
 * @Description: 单个文件上传，btn样式
-->
<template lang="pug">
el-upload.wp-100.hp-100(
    v-bind="$attrs"
    action="#"
    :show-file-list="false"
    :before-upload="fnHandleBeforeUpload"
    :http-request="fnHandleHttpRequest"
  )
    el-button.wp-100.hp-100()
      slot
      //- .fs-14.mt-7.text-left(
        v-if="$slots.tip"
        style="color: #606266;")
        slot(name="tip")
</template>

<script>
import {mapState} from 'vuex'
// import { uploadCompanyFile, uploadGhFile} from '@/api'
import { uploadFile } from '@/api';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    waterMarkFlag: {
      type: Boolean,
      default: true // 默认需要水印
    },
    fileStorageType: { // 是否要保存到oss
      type: String,
      default: ''
    },
    fileTypeName:{
      type:String,
      default:''
    }
  },
  computed: {
    ...mapState({
      roles: state => state.permission.roles
    })
  },
  methods: {
    fnHandleBeforeUpload(file){
      this.file = file;
    },
    async fnHandleHttpRequest(){
      const [err, data] = await uploadFile({
        data: {
          file: this.file,
          waterMarkFlag: this.waterMarkFlag,
          fileStorageType: this.fileStorageType || '',
          fileTypeName: this.fileTypeName
        }
      })
      if(err) return;
      this.$emit('input', data)
      return data;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-upload {
  width: 100%;
  height: 100%;
}
</style>

<style>
.wp-100{width:100%;}.hp-100{height:100%;}.fs-14{font-size:14px;}.mt-7{margin-top:7px;}</style>
