<!--
 * @Author: 聂涛
 * @Date: 2022-06-07 16:59:56
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-05 18:49:06
 * @Description: 
-->
<template lang="pug">
el-dialog(
    width="400px"
    :visible="show"
    append-to-body
    :show-close="false"
    title="新增标签"
    center
  )
    .text-center
      el-select.w-300(
        filterable
        v-model="groupId"
        placeholder="请选标签组"
        clearable
      )
        el-option(
          v-for="(item, i) in groupList"
          :key="i"
          :label="item.groupName"
          :value="item.groupId"
        )
      el-input.w-300.mt-15(
        placeholder="请输入标签名称"
        v-model="groupName"
      )
      .text-center.mt-30
        xh-btn.mx-15(@click="fnClickClose") 取消
        xh-btn.mx-15(@click="fnAddGroupTag" type="primary") 确定
</template>

<script>
import { tagAdd, tagV2TagCateList } from '@/api'
export default {
  props: {
    show: {
      type: Boolean,
      default: () => true
    },
    // -标签类型
    tagCate: {
      type: String,
      default: ''
    },
    // -雷达专属
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      groupId: '',
      groupName: '',
      groupList: []
    }
  },
  watch: {
    tagCate: {
      handler() {
        this.fnGetGroupList()
      }
    }
  },
  methods: {
    async fnGetGroupList() {
      const [err, data] = await tagV2TagCateList({
        regInfo: {
          tagCate: this.tagCate
        }
      })
      if (err) return
      this.groupList = data || []
    },
    async fnAddGroupTag() {
      const [err, data] = await tagAdd({
        data: {
          groupId: this.groupId,
          orderNum: 0,
          tagId: "",
          tagName: this.groupName
        }
      })
      this.fnClickClose()
      this.$emit('success')
      this.$message.success('增加成功')
    },
    fnClickClose() {
      this.groupId = ''
      this.groupName = ''
      this.$emit('update:show', false)
    }
  },
}
</script>

<style>

</style>
<style>
.w-300{width:300px;}.mt-15{margin-top:15px;}.mt-30{margin-top:30px;}.mx-15{margin-left:15px;margin-right:15px;}</style>
