/*
 * @Author: 建辉
 * @Date: 2021-05-08 15:49:45
 * @LastEditors: 建辉
 * @LastEditTime: 2021-08-12 17:27:53
 * @Description: 客户端存储store数据
 */

import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  window.onNuxtReady(() => {
    createPersistedState({
      key: 'scrm',
      storage: window.sessionStorage,
      paths: ['client', 'user.menu.topActiveTabIndex',
      ]
    })(store)
  })
}
