<template lang="pug">
  div
    transition(name="fade-transform" mode="out-in")
      nuxt(:key="$route.fullpath")
</template>

<script>
export default {
  name: 'empty'
}
</script>

<style lang="scss" scoped>

</style>

<style>
</style>
