<template lang="pug">
  div
    div(v-if="error.statusCode === 404")
      NotFound(:notIndex="error.notIndex")
    div(v-else) {{ error }}
</template>

<script>
import NotFound from '@/components-pages/error/not-found';

export default {
  name: 'error',
  components: {
    NotFound
  },
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style>
</style>
