<!--
 * @Author: 赵伟
 * @Date: 2021-06-23 14:26:21
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-07-17 18:07:12
 * @Description: file content
-->
<template lang="pug">
el-form.no-border(
    :model="form"
    :rules="rules"
    inline
    ref="form"
  )
    el-form-item(label="标签组名: " prop="groupName")
      el-input.w-300(placeholder="请输入标签组名" v-model="form.groupName" maxlength="15" show-word-limit clearable)
    el-form-item(label="标签顺序: " prop="order")
      el-input.w-300(placeholder="请输入标签顺序" v-model="form.order" clearable)
    el-form-item(label="组内标签: " prop="tag")
      div(:style="{width: type === 'dialog' ? '300px' : '100%'}")
        el-tag.cs-pt.mr-10(
          v-if="! inputVisible" @click="fnClickAddTag"
          effect="dark"
        ) + 标签
        el-input.w-300.mr-10(ref="addrefs" v-if="inputVisible" @blur="fnBsnAddTag" v-model="tagName" @keyup.enter.native="fnBsnAddTag" maxlength="15" show-word-limit clearable)
        el-tag.mb-10.mr-10(v-for="(item, idx) in form.tag" closable @close="fnClickRmTag(idx)" :type="item.name" :key="item.tagId") {{item.name}}

    .ml-80
      xh-btn(@click="fnClickSave" type="primary") 确定
      xh-btn.ml-20(@click="fnClickCancel") 取消
</template>

<script>
import { qywxEditGroupTag } from "@/api"
import { validFormInteger } from '@/utils/form-validate'

export default {
  name: 'tags-group',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'list' // dialog 还是 list
    }
  },
  data() {
    const validTag = (rule, value, callback) => {
      // case:1 没有标签
      if (!value.length) {
        // case:1 不显示输入框
        if(!this.inputVisible){
          callback(new Error('请输入标签'));
        }
        // case:2 显示了输入框 但是输入框为空
        if(this.inputVisible && !this.tagName){
          callback(new Error('请输入标签'));
        }
      }
      // case:2 有标签
      // 有输入框并且input不为空的情况
      // if (this.inputVisible && !this.tagName) {
      //   callback(new Error('请输入标签'));
      //   return;
      // }
      callback();
    }
    return {
      oForm: {}, // 表单 暂存
      rules: {
        groupName : [
          { message:"请输入标签组名", required: true }
        ],
        order: [
          { message:"请输入标签顺序", required: true },
          {
            message:"顺序只允许输入纯数字",
            validator: validFormInteger,
            required: true
          }
        ],
        tag: [
          { required: true, validator: validTag }
        ]
      },
      tagName: "", // 临时存储标签名
      inputVisible: false // 显示input输入
    }
  },
  watch: {
    form: {
      // - 赋值操作
      handler(val){
        this.oForm = val;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // - 删除标签
    fnClickRmTag(idx) {
      this.oForm.tag.splice(idx, 1);
      this.$emit("update:form", this.oForm);
    },
    // - 增加子标签
    fnBsnAddTag() {
      if (!this.tagName) {
        // 如果有标签列表>0 && tagName为空
        if(this.form.tag.length) this.inputVisible = false
        return;
      }
      this.oForm.tag.push({
        orderNum: this.form.tag.length + 1,
        name: this.tagName
      });
      this.$emit("update:form", this.oForm);
      // 重置
      this.tagName = "";
      this.inputVisible = false;
    },
    // - 增加标签接口
    fnClickSave() {
      this.$refs.form.validate(async valide => {
        if (valide) {
          const params = { ...this.form }
          const [err, data] = await qywxEditGroupTag({
            data:params
          })
          if (err) return
          this.$message.success("操作成功");
          this.fnBsnUpdate();
          this.$emit('sucess');
        }
      })
    },
    // - 点击 增加标签
    fnClickAddTag(){
      this.inputVisible = !this.inputVisible;
      // 当有输入框的时候 默认焦点
      if(this.inputVisible){
        this.$nextTick(() => {
          this.$refs.addrefs.focus();
        })
      }
    },
    // - 点击 取消
    fnClickCancel() {
      this.fnBsnUpdate();
      this.$emit("cancel");
    },
    // - 重置操作
    fnBsnUpdate() {
      this.oForm = {
        groupName: "",
        order: "",
        tag	: []
      };
      this.$emit("update:form", this.oForm);
      this.$emit('update:innerVisible', false)
      this.$nextTick(() => {
        this.inputVisible = false;
        this.$refs.form.resetFields();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  display: flex;
}
::v-deep .el-form-item__label {
  flex-shrink: 0;
}
</style>

<style>
.w-300{width:300px;}.mr-10{margin-right:10px;}.mb-10{margin-bottom:10px;}.ml-80{margin-left:80px;}.ml-20{margin-left:20px;}</style>
