/*
 * @Author: 聂涛
 * @Date: 2023-07-20 15:24:04
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-01-08 11:16:03
 * @Description: 
 */
import Vue from 'vue';
import Bpmn from '@fostars/h-ui/lib/bpmn'
import hFileUploader from '@fostars/h-ui/lib/file-uploader'

const components = [
  Bpmn,
  hFileUploader
]

components.forEach(component => {
  Vue.use(component)
})
