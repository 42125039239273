<!--
 * @Author: 建辉
 * @Date: 2021-08-05 17:59:38
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-06-01 17:39:02
 * @Description: 加载更多封装
-->

<template lang="pug">
listen-scrollbar(
    @scrollBottom="scrollBottom"
    @scrollTop="scrollTop"
    ref="listen-scrollbar"
    :spaceVal="spaceVal"
  )
    div
      div.text-center.fs-14.h-20.lh-20
        span(v-show="loadTopLoading")
          i.el-icon-loading
          span 加载更多...
    slot
    div
      div.text-center.fs-14.h-20.lh-20
        span(v-show="loadBottomLoading")
          i.el-icon-loading
          span 加载更多...
</template>

<script>
import _ from 'lodash'
export default {
  name: 'list',
  props: {
    bshowBottom: {
      type: Boolean,
      default: false,
    },
    bshowTop: {
      type: Boolean,
      default: false,
    },
    loadTopLoading: {
      type: Boolean,
      default: false
    },
    loadBottomLoading: {
      type: Boolean,
      default: false
    },
    spaceVal: {
      type: Number,
      default: 30
    }
  },
  data(){
    return {
    }
  },
  mounted(){
    this.fnDebounceScrollBottom = this.debounceScrollBottom();
    this.fnDebounceScrollTop = this.debounceScrollTop();
  },
  methods: {
    scrollToBottom(){
      this.$refs['listen-scrollbar'].scrollToBottom()
    },
    scrollToSpaceTop(){
      this.$refs['listen-scrollbar'].scrollToSpaceTop()
    },
    debounceScrollBottom(){
      return _.debounce(() => {
        this.$emit('loadBottom');
      }, 700)
    },
    debounceScrollTop(){
      return _.debounce(() => {
        this.$emit('loadTop');
      }, 700)
    },
    scrollBottom(){
      this.fnDebounceScrollBottom();
    },
    scrollTop(){
      this.fnDebounceScrollTop();
    }
  }
}
</script>

<style>
.fs-14{font-size:14px;}.h-20{height:20px;}.lh-20{line-height:20px;}</style>
