<!--
 * @Author: 建辉
 * @Date: 2020-10-10 14:16:57
 * @LastEditors: 建辉
 * @LastEditTime: 2021-06-23 15:43:17
 * @Description: 按钮权限控制
-->
<template lang="pug">
.dspl-inbl(v-if="bShowSlot")
    slot
</template>

<script>
import {mapState} from 'vuex'
export default {
  name: 'fn-render',
  props: {
    // 按钮名称
    code: {
      type: String,
      default: ''
    },
  },
  computed: {
    ...mapState({
      btnCodeList: state => state.user.permission && state.user.permission.btnCodeList || []
    }),
    bShowSlot(){
      if(this.btnCodeList.includes(this.code)){
        return true
      }
      return false;
    }
  }
}
</script>

<style>
</style>
