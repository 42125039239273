<!--
 * @Author: 建辉
 * @Date: 2020-10-17 10:40:42
 * @LastEditors: 建辉
 * @LastEditTime: 2020-10-24 14:28:08
 * @Description: 渲染一个label，或者一个内部组件
-->
<template lang="pug">
component(
    v-on="$listeners"
    :is="component"
    v-bind="$attrs"
  )
</template>

<script>
export default {
  props: {
    component: {
      type: String,
      default: 'el-input'
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
