/*
 * @Author: 赵顺陈
 * @Date: 2020-09-02 09:50:12
 * @LastEditors: 建辉
 * @LastEditTime: 2021-05-24 15:25:09
 * @Description:
 */
import { request } from './http'
// 获取用户列表
export const fetchUsers = options => {
  return request('post', '/gh/admin/user/list.json', options)
}
// 获取部门列表
export const fetchOrgs= options => {
  return request('post', '/gh/core/user/org.json', options)
}
// 获取职务子类型
export const fetchChildrenPosts= options => {
  return request('post', '/gh/core/dict/viewChildren.json', options)
}
// 添加用户
export const addUser = options => {
  return request('post', '/gh/admin/user/add.json', options)
}
// 删除用户
export const delUser = options => {
  return request('post', '/gh/admin/user/delete.json', options)
}
// 更新/新增用户
export const updateUser = (options) => {
  return request('post', '/gh/admin/user/update.json', options)
}

export const createUser = (options) => {
  return request('post', '/gh/admin/user/add.json', options)
}
// 修改密码
export const changePassword = (options) => {
  return request('post', '/gh/admin/user/changePassword.json', options)
}
// 根据id获取列表
export const getRolesForUid = (options, uid) => {
  return request('post', `/gh/admin/user/role/list.json?userId=${uid}`, options)
}
// 删除角色
export const delRoleForUid = (options) => {
  return request('post', '/core/role/user/remove', options)
}
// 新增角色
export const addUserManagementRole = (options) => {
  return request('post', '/gh/admin/user/role/add.json', options)
}
// 获取所有角色
export const getRoleTypes = (options) => {
  return request('post', '/gh/admin/role/all.json', options)
}

export const getListForTag = (options) => {
  return request('post', '/gh/admin/dict/type/list.json', options)
}

// 业务经办员 免密登录企业端后台
export const getGhNoPwdLogin = (options) => {
  return request('post', '/gh/auth/union/agent/ent/login/:entId', options)
}
