<!--
 * @Author: xiaoguang_10@qq.com
 * @LastEditors: 建辉
 * @Date: 2021-05-17 15:10:14
 * @LastEditTime: 2021-06-21 11:47:02
-->
<template lang="pug">
.cs-pt(@click.capture="fnClick" :class="[isBlock ? '' : 'dspl-inbl']")
    slot
</template>

<script>
// 节流btn，防止多次点击
import _ from 'lodash'
export default {
  name: 'throttle-btn',
  props: {
    isBlock: {
      type: Boolean,
      default: false
    }
  },
  mounted(){
    this.throttleFn();
  },
  methods: {
    throttleFn(){
      this.throttleClick = _.throttle(() => {
        this.$emit('clickEmit')
      }, 500, {
        // 先调用，后等待
        'leading': true,
        'trailing': false
      })
    },
    fnClick(){
      this.throttleClick();
    }
  }
}
</script>

<style>
</style>
