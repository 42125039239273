<!--
 * @Author: 建辉
 * @Date: 2021-11-08 16:37:05
 * @LastEditors: 建辉
 * @LastEditTime: 2021-11-08 17:20:38
 * @Description: 表单组件渲染
-->

<template lang="pug">
component(:is="formItem.component" :placeholder="formItem.placeholder" :value="formItem.val"
  @input="fnHandleInput"
  v-bind="formItem.bindProps"
  clearable
)
</template>

<script>
import dayjs from '@/utils/dayjs';
export default {
  props: {
    formItem: {
      type: Object,
      default(){
        return {}
      }
    },
    index: {
      type: Number,
      default: null
    }
  },
  methods: {
    fnHandleInput(val){
      switch (this.formItem.component){
      case 'el-date-picker':
        {
          const format = this.formItem.format || 'YYYY-MM-DD'
          const isRangeTime = this.formItem.bindProps?.type?.includes('range');
          if(isRangeTime){
            this.$emit('input', {
              index: this.index,
              val: val && val.map((item) => {
                return dayjs(item).format(format);
              }) || []
            })
          } else {
            this.$emit('input', {
              index: this.index,
              val: dayjs(val).format(format)
            })
          }
        }
        break;
      default:
        this.$emit('input', {
          index: this.index,
          val
        })
        break;
      }
    }
  }
}
</script>

<style>
</style>
