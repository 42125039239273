<!--
 * @Author: 建辉
 * @Date: 2020-08-12 13:17:51
 * @LastEditors: 聂涛
 * @LastEditTime: 2021-07-26 17:31:04
 * @Description: 选择时间
-->
<template lang="pug">
el-date-picker(
    v-bind="$attrsAll"
    :type="type"
    v-model="val"
    @input="fnChange"
    :picker-options="oPickerOptions"
    key="el-date-picker"
    clearable
  )
</template>

<script>
import dayjs from 'dayjs';
import _ from 'lodash'
export default {
  name: 'date-pick-sync',
  inheritAttrs: false,
  props: {
    end: {
      type: String,
      default() {
        return ''
      }
    },
    start: {
      type: String,
      default() {
        return ''
      }
    },
    type: {
      type: String,
      default: 'daterange' // datetime year/month/date/week/ datetime/datetimerange/daterange
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd' // yyyy-MM-dd HH:mm:ss
    },
    // 设置最大时间
    maxDate: {
      type: [Date, String],
      default: ''
    }
  },
  data() {
    return {
      val: '',
      oPickerOptions: {
        disabledDate: (date) => {
          // 月的情况不得取23:59:59 不然多算一天
          if (this.maxDate !== '' ) {
            // 取当天的时间 23:59:59
            const ymd = dayjs(this.maxDate).format('YYYY-MM-DD')
            const maxEndDate = new Date(`${ymd} 23:59:59`)
            return date.getTime() > maxEndDate.getTime()
          } else {
            return false
          }
        }
      }
    }
  },
  computed: {
    value(){
      return [this.start, this.end]
    },
    selfProp(){
      if(['datetimerange', 'daterange'].includes(this.type)){
        return {
          'start-placeholder': "开始日期",
          'end-placeholder' : "结束日期",
          'range-separator': '至',
          'default-time': ['00:00:00', '23:59:59']
        }
      }else if(['monthrange'].includes(this.type)){
        return {
          'start-placeholder': "开始日期",
          'end-placeholder' : "结束日期",
          'range-separator': '至',
          'default-time': ['00:00:00', '00:00:00']
        }
      } else {
        return {}
      }
    },
    $attrsAll() {
      const options = {
        placeholder: '请选择时间',
        ...this.selfProp,
        ...this.$attrs,
      }
      return {
        'value-format': 'yyyy-MM-dd HH:mm:ss',
        format: this.format,
        ...options,
      }
    }
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if(!this.type.includes('range') && val){
          this.val = dayjs(val).format('YYYY-MM-DD HH:mm:ss');
          return;
        }
        this.val = val
      },
      immediate: true
    }
  },
  methods: {
    fnChange(val) {
      if(this.val === null){
        if(this.type.includes('range')){
          this.$emit('update:start', '')
          this.$emit('update:end', '')
          return;
        }else{
          this.$emit('input', '')
          this.$emit('change', '')
        }
      }
      this.$emit('update:start', val[0])
      this.$emit('update:end', val[1])
    }
  }
}
</script>

<style>
</style>
