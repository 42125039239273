// check login
import { whiteRoutes } from "@/utils";
export default async function(context) {
  const {
    isHMR,
    app,
    store,
    req,
    route,
    params,
    error,
    redirect
  } = context;
  const selectItem = whiteRoutes.find(item => route.path.includes(item));
  if(!selectItem){
    const [err, data] = await store.dispatch('user/fetchUserInfo');
    if(err){
      // 获取用户信息失败
      return redirect('/login')
    }
    if(!context.bsnData) context.bsnData = {}
    context.bsnData.userInfo = data;
  }
}
