/*
 * @Author: xiaoguang_10@qq.com
 * @LastEditors: 聂涛
 * @Date: 2021-05-17 17:22:45
 * @LastEditTime: 2022-12-01 12:03:07
 */
export const whiteRoutes = [
  '/login',
  '/login/m',
  '/demo'
];

export const isInt = process.env.FEENV === 'int'
export const isDev = process.env.FEENV === 'development'
export const isProd = process.env.FEENV === 'production'

// 聊天菜单配置
// 0、客服
// 1、转化
export const chatMenuList = () => {
  let isTransfer = null
  let urlEnum = null
  let currentCount = 0
  let allUnReadNum = 0
  let groupUnReadNum = 0
  let privateUnReadNum = 0
  // 客户端
  if (process.client) {
    isTransfer = window.localStorage.getItem('isTransfer')
    // 单聊
    if (window.location?.href.includes('/chat?isGroup=0')) {
      urlEnum = '1v1'
    } else if (window.location?.href.includes('/chat?isGroup=1')) {
      urlEnum = 'group'
    } else if (window.location?.href.includes('/chat?isGroup=2')) {
      urlEnum = 'all'
    } else {
      urlEnum = 'other'
    }
    currentCount = window.__NUXT__?.state?.chat?.unReadMsgCount || 0
    allUnReadNum = window.__NUXT__?.state?.chat?.allUnReadNum || 0
    groupUnReadNum = window.__NUXT__?.state?.chat?.groupUnReadNum || 0
    privateUnReadNum = window.__NUXT__?.state?.chat?.privateUnReadNum || 0
  }
  const menuList = []
  // 聊天模块
  menuList.push({
    children:[
      {
        name:"全部",
        data:{
          name:"全部",
          icon:"",
          accessUrl:"/chat?isGroup=2",
          disabled: true,
          unread: allUnReadNum > 0 ? allUnReadNum : 0,
        },
        "id":"1.1"
      },
      {
        name:"单聊",
        data:{
          name:"单聊",
          icon:"",
          disabled: true,
          unread: privateUnReadNum > 0 ? privateUnReadNum : 0,
          accessUrl:"/chat?isGroup=0",
        },
        "id":"1.2"
      },
      {
        name:"群聊",
        data:{
          name:"群聊",
          icon:"",
          disabled: true,
          accessUrl:"/chat?isGroup=1",
          unread: groupUnReadNum > 0 ? groupUnReadNum : 0,
        },
        "id":"1.3"
      }
    ],
    name:"消息",
    data:{
      name:"消息",
      icon:"xh-message-out-l",
      disabled: true,
      accessUrl:"/chat",
    },
    "id":"1"
  })
  if (!isTransfer) {
    menuList.push({
      children:[
      ],
      name:"通讯录",
      data:{
        name:"通讯录",
        icon:"xh-contacts-Out-l",
        accessUrl:"/chat/persons",
        disabled: true,
      },
      "id":"2"
    })
  }
  menuList.push({
    children:[
    ],
    name:"聊天记录",
    data:{
      name:"聊天记录",
      icon:"xh-record-out-l",
      disabled: true,
      accessUrl:"/chat/historys",
    },
    "id":"3"
  })
  return menuList
}