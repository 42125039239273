<!--
 * @Author: 赵伟
 * @Date: 2021-05-28 11:21:07
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-05-28 13:43:58
 * @Description: file content
-->
<template lang="pug">
el-popover(
    v-bind="$attrs"
    :placement="placement"
    :width="width"
    :trigger="trigger"
    :title="title"
    :content="content"
  )
    xh-btn(:icon="btnIcon" slot="reference" @click="fnClickEmit") {{ btnName }}
    slot
</template>

<script>
export default {
  props: {
    // 触发方式
    trigger:{
      type: String,
      default: "manual" // click/focus/hover/manual
    },
    // 标题
    title:{
      type: String,
      default: ""
    },
    // 内容
    content:{
      type: String,
      default: ""
    },
    // 宽度
    width: {
      type: [Number, String],
      default: "400"
    },
    // 出现的位置
    placement: {
      type: String,
      default: "right" // top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end
    },
    // 按钮 icon
    btnIcon:{
      type: String,
      default: ""
    },
    // 按钮 名字
    btnName:{
      type: String,
      default: ""
    }
  },
  data () {
    return {
      val: ""
    }
  },
  watch: {
    value:{
      handler(newVal){
        this.val = newVal
      },
      immediate: true
    }
  },
  methods: {
    // 提交
    fnClickEmit(){
      this.val = true;
      this.$emit("input", true)
    }
  }
}
</script>

<style>

</style>

<style>
</style>
