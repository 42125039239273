<!--
 * @Author: 建辉
 * @Date: 2020-09-16 14:54:30
 * @LastEditors: 建辉
 * @LastEditTime: 2021-06-22 13:55:55
 * @Description: 用户组织-级联选择器
-->
<template lang="pug">
//- 任意一级
el-cascader.org-cascader(
  v-if="checkStrictly"
  ref="oCascaderRef"
  v-model="val"
  :options="aList"
  @change="handleChange"
  :props="propsKey"
  v-bind="$attrs"
  clearable
)
  div(slot-scope='{node,data}' @click='fnClickNode(node, data)') {{ data.name }}

//- 最后一级
el-cascader.org-cascader(
  v-else
  v-model="val"
  :options="aList"
  @change="handleChange"
  :props="propsKey"
  v-bind="$attrs"
)

</template>

<script>
import {fetchAgentOrgTree} from '@/api';
export default {
  name: 'select-agent-org',
  props: {
    value: {
      type: [Number, String],
      default() {
        return 0;
      }
    },
    type: {
      type: String,
      default: ''
    },
    // 是否可选任意一级
    checkStrictly: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      aList: [],
      // val 为 number类型，但后端接口搜索列表时 空时为'' 而不是0
      val: '',
      defaultPropsKey: {
        expandTrigger: 'hover',
        checkStrictly: true, // 可选任意一级
        label: 'name',
        value: 'id',
        emitPath: false // 只返回组织 叶子节点的id
      }
    }
  },
  computed: {
    propsKey(){
      return {
        ...this.defaultPropsKey,
        checkStrictly: this.checkStrictly
      }
    }
  },
  watch: {
    value: {
      handler(val){
        // val 为 number类型，但后端接口搜索列表时 空时为'' 而不是0
        this.val = val || ''
        // 任意一级时 选择后可隐藏 cascader
        if (this.$refs.oCascaderRef) this.$refs.oCascaderRef.dropDownVisible = false;
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetOrgTree();
  },
  methods: {
    getTargetItem(key){
      const targetKey = this.value;
      let targetIterm = null
      const findTargetItem = (aList, key) => {
        aList.forEach(item => {
          if(item.id === key){
            targetIterm = item;
          }else{
            item.children && findTargetItem(item.children, key)
          }
        })
      }
      findTargetItem(this.aList || [], key)
      console.log(targetIterm)
      if(targetIterm){
        this.$emit('update:item', targetIterm)
        this.$emit('update:desc', targetIterm.name)
      }
    },
    fnClickNode(node, data) {
      this.val = data.id
      this.handleChange(this.val)
    },
    handleChange(aVal){
      // val 为 number类型，但后端接口搜索列表时 空时为'' 而不是0
      const sVal = aVal || '';
      this.$emit('input', sVal)
      this.$emit('change', sVal)
      this.getTargetItem(sVal)
      const parent = this.$parent;
      if(aVal && parent && parent.clearValidate){
        parent.clearValidate();
      }
    },
    async fnNetGetOrgTree(){
      const [err, data] = await fetchAgentOrgTree()
      if(err) {
        return;
      }
      // 递归数据，如果children 长度为0，就删除
      function loopData(arr) {
        arr.forEach(item => {
          if(item.children && item.children.length){
            item.children = loopData(item.children)
          }else{
            delete item.children;
          }
        })
        return arr;
      }
      const result = loopData([data])
      this.aList = result
      // 针对开户申请页面获取对应item时不触发方法、此处手动触发一次，未直接触发handleChange方法，因会在搜索时触发两次
      if(this.val) this.getTargetItem(this.val)
    },

    // 获取 Cascader 全id, 加了emitPath后此函数可不用了。
    fnBsnQueryCascader(value, children, valueArr = []) {
      for (let i = 0; i < children.length; i++) {
        const item = children[i];
        if (item.id === value) {
          return valueArr.concat([value]);
        } else if (item.children) {
          const result = this.fnBsnQueryCascader(value, item.children, valueArr.concat([item.id]));
          if (result) {
            return result;
          }
        }
      }
    }
  }
}
</script>

<style>
</style>
