<!--
 * @Author: 建辉
 * @Date: 2020-09-19 14:05:12
 * @LastEditors: 建辉
 * @LastEditTime: 2021-06-18 09:53:15
 * @Description: 封装cell的 mouse效果
-->
<template lang="pug">
throttle-btn(@clickEmit="fnClickEmit" style="margin-right: 0;")
  .flex.flex-row.flex-middle.mx-5.py-5.theme-hover
    icon(:icon="icon" :size="size" :bPoint="true")
    .fs-15.fw-400.lh-16.ml-4 {{$slots.default[0] && $slots.default[0].text || ''}}
</template>


<script>
const themeColor = process.env.THEME_COLOR
const warnginColor = process.env.WARNING_COLOR
export default {
  name: 'cell-cover',
  props: {
    icon: {
      type: String,
      default: ''
    },
    // 字体
    size: {
      type: [String, Number],
      default: '16'
    },
    color: {
      type: [String, Number],
      default: themeColor
    },
  },
  data(){
    return {
    }
  },
  mounted(){
  },
  methods: {
    fnClickEmit(){
      this.$emit('click')
    }
  }
}
</script>

<style>
.mx-5{margin-left:5px;margin-right:5px;}.py-5{padding-top:5px;padding-bottom:5px;}.fs-15{font-size:15px;}.fw-400{font-weight:400;}.lh-16{line-height:16px;}.ml-4{margin-left:4px;}</style>
