<!--
 * @Author: wangyaqiang
 * @Date: 2022-05-17 16:57:05
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-02-02 11:35:48
 * @Description: 商机状态
-->
<template lang="pug">
<el-cascader :options="businessStatusOptions" :key="cascaderIdx" collapse-tags :props="props" v-model="statusValue" @input="fnClickSearch" :disabled="disabled" :placeholder="placeholder" clearable :style="{width: defaultWidth}"></el-cascader>

</template>
<script>
import {
  businessLifeCycleAllMainLifeCycle,
  businessOpportGetBusProductConfig,
  businessOpportGetBusStatusEnum
} from '@/api';
import { mapState } from 'vuex'

export default {
  name: 'bus-status',
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    listObj: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "all",
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    defaultWidth: {
      type: String,
      default: '100%'
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    other: {
      type: Boolean,
      default: false // 其他的特殊情况，只显示一次
    }
  },
  data() {
    return {
      cascaderIdx: 0, // 防止 cascader 报错
      statusValue: this.value,
      businessStatusOptions: [],
      props: {
        value: 'code',
        label: 'desc',
        children: 'subState',
        checkStrictly: false,
        multiple: this.multiple,
        expandTrigger: 'hover',
      },
    }
  },
  computed: {
    ...mapState({
      businessProductList: state => state.enum.enumMap.businessProductList
    }),
  },
  watch: {
    'listObj.productCode': {
      handler (value) {
        if (!value) return
        this.cascaderIdx++;
        this.fnGetBusinessStatus('watch');
      },
      immediate: true,
      deep: true,
    },
    value(val) {
      this.statusValue = val;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.fnGetBusinessStatus('mounted');
    })
  },
  methods: {
    // input 事件
    fnClickSearch(value) {
      this.$emit('update:value', value);
      this.$emit('input', value);
      this.$emit('change', value);
    },
    // 获取商机状态
    async fnGetBusinessStatus(source) {
      const type = this.type;
      const { productCode, lifeCycle, productType } = this.listObj;
      let params = { productType }
      if (type === 'all' && !productCode) return;
      if (type === 'all') {
        const filterList = this.businessProductList?.filter( (item) => {
          return productCode.includes(item.productCode)
        })
        const productType = filterList?.map(item => item.productForm)
        if (productType && productType.length === 1) {
          params = {
            productType: productType[0]
          }
        } else {
          return
        }
      }
      if (type === 'next') {
        params.curState = lifeCycle;
      }
      if (this.other) {
        const [err, data] = await businessLifeCycleAllMainLifeCycle({
          data: {
            ...params
          }
        })
        if (err) return
        this.businessStatusOptions = data || []
      } else {
        const [err, data] = await businessOpportGetBusStatusEnum({
          regInfo: {
            type
          },
          data: {
            ...params
          }
        })
        if (err) return
        this.businessStatusOptions = data || []
      }
    },
  }
}
</script>
<style scoped lang='scss'>
  
</style>

<style>
</style>
