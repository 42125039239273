<!--
 * @Author: 建辉
 * @Date: 2021-05-07 14:15:12
 * @LastEditors: xiaoguang_10@qq.com
 * @LastEditTime: 2021-06-08 11:42:25
 * @Description: list layout页面
-->

<template lang="pug">
.bg-white.p-10
  slot
</template>

<script>
export default {
  name: 'box-layout',
}
</script>

<style>
.p-10{padding:10px;}</style>
