<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:03:43
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-09 17:22:08
 * @Description: Description
-->
<template lang="pug">
div(style="min-height:236px;")
  upload(ref="editorFile" width="152" height="152" accept="audio" type="SingleFile" v-model="defaultItemURL")
  .text-center(v-if="!defaultItemURL")
    .fs-14 请先点击
      span.theme-color.cs-pt.fw-500(@click="fnClickAddMp3") 【立即生成】
      span 按钮录制音频，下载后再重新上传。
    .fw-500(style="color: red") 特别注意:必须在系统内录制，否则微信将接收异常
  client-only
    Recording(
      :show.sync="bShowRCT"
      :download="true"
    )
</template>
<script>

import Recording from '@/components-pages/chat/recording/index.vue'

export default {
  name: 'h-mp3',
  components: {
    Recording
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    bShowRCT: false,
    defaultItem: {
      file: '',
    },
    defaultItemURL: '',
  }),
  created() {},
  mounted() {},
  methods: {
    fnSetEditor(val) {
      if (val.materialType === 'file') {
        this.defaultItemURL = val.content
      }
    },
    // 获取输入的内容
    fnGetEditor() {
      return this.defaultItemURL || '';
    },
    // 清除输入的内容
    fnClearEditor() {
      // if (this.$refs.editorFile) this.$refs.editorFile.fnClickDel();
      this.defaultItemURL = '';
      // console.log('defaultItem:---', this.$refs.editorFile, this.defaultItem, this.$options.data().defaultItem)
    },
    fnClickAddMp3() {
      this.bShowRCT = true
    }
  }
}
</script>
<style scoped lang='scss'>
</style>

<style>
.fs-14{font-size:14px;}.fw-500{font-weight:500;}</style>
