<template lang="pug">
div
  el-popover(
    @show="onShow"
  )
    el-input(clearable v-model="keyword" placeholder="请输入关键字" @input="fnSearch")
    .h-500.ovfl-y-auto.select-chat-user.w-290
      el-checkbox-group(
        :value="userIdList"
        @input="onChange"
      )
        h-space.pt-10
          el-checkbox(
            v-for="item in getUserList"
            :key="item.qywxUserId"
            :label="item.qywxUserId"
            )
            .flex.flex-middle
              img.w-20(:src="item.avatar" v-show="item.avatar")
              emoji-text.ml-5(:text="item.qywxUserName")
    div(
      slot="reference"
    )
      slot
  h-space.mt-10(direction="horizontal")
    el-tag(
      closable
      size="medium"
      class="tag"
      v-for="(user, i) in list"
      :key="i"
      @close="fnCloseMember(user)"
    )
      img.w-20.vtal-md(:src="user.avatar" v-show="user.avatar")
      emoji-text.vtal-md.ml-8(:text="user.qywxUserName")
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data(){
    return {
      keyword: '',
      userList: [],
      filterUsers: []
    }
  },
  computed: {
    ...mapState({
      aUserList: state => state.user.addPerson,
      filterKey: state => state.user.filterKey,
    }),
    ...mapGetters('user', ['getUserList']),
    userIdList(){
      return this.list.map(item => {
        return item.qywxUserId
      })
    }
  },
  mounted(){
    this.$store.dispatch("user/fetchAddPersonList");
  },
  methods: {
    fnSearch(){
      this.$store.commit('user/UPDATE_USER_FILTER_KEY_WORDS', this.keyword)
    },
    onChange(vals){
      const users = vals.map(item => {
        return this.aUserList.find(u => {
          return u.qywxUserId === item
        })
      })
      this.$emit('updateList', users)
    },
    onShow(){
      this.keyword = this.filterKey
    },
    fnCloseMember(user){
      const users = this.list.filter(item => {
        return item.qywxUserId !== user.qywxUserId
      })
      this.$emit('updateList', users)
    }
  }
}
</script>

<style lang="scss">
.select-chat-user {
  .el-checkbox__label {
    vertical-align: middle;
  }
}
</style>

<style>
.h-500{height:500px;}.w-290{width:290px;}.pt-10{padding-top:10px;}.w-20{width:20px;}.ml-5{margin-left:5px;}.mt-10{margin-top:10px;}.ml-8{margin-left:8px;}</style>
