/*
 * @Author: 聂涛
 * @Date: 2021-05-13 11:45:15
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-15 16:53:20
 * @Description:
 */
// 自定义组件 ssr
// 展现形式的table，不是依赖数据结构的
import Vue from 'vue';

import emojiTxt from '@/components-pages/chat/msg-type/msg-emoji.vue'
import SelectTag from '@/components/select-custom-tag/index.vue'
import MsgView from '@/components-pages/chat/task/msgView.vue'
import BusStatus from '@/components-pages/chat/business/status.vue'

const requireComponent = require.context('../components', true, /index.vue$/)
requireComponent.keys().forEach((fileName) => {
  // 将被注册的组件名字,对获取的组件文件名进行处理
  const componentName = fileName
    .replace(/^\.\/_/, '')
    .replace(/\/index\.\w+$/, '')
    .split('./')
    .join('')
  const componentConfig = requireComponent(fileName)
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.component('emoji-chat-txt', emojiTxt)
Vue.component('select-tag', SelectTag)
Vue.component('MsgView', MsgView)
Vue.component('bus-status', BusStatus)
