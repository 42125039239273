<!--
 * @Author: 建辉
 * @Date: 2020-08-12 15:17:14
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-02-21 11:00:39
 * @Description: 单个文件上传，显示
-->
<template lang="pug">
component(
    @input="fnHandleInput"
    @showChange="fnHandleShowChange"
    :is="type"
    v-bind="$attrs"
  )
    slot
</template>

<script>
import SingleImg from './SingleImg';
import SingleBtn from './SingleBtn'
import MultiImg from './MultiImg'
import IdCard from './IdCard'
import UploadFile from './UploadFile'
import SingleFile from './SingleFile.vue'

const themeColor = process.env.THEME_COLOR

export default {
  components: {
    SingleImg,
    SingleBtn,
    IdCard,
    MultiImg,
    UploadFile,
    SingleFile
  },
  props: {
    type: {
      type: String,
      default: 'SingleImg'
    },
    imgType:{ // 当前字段只针对图片类型
      type: String,
      default: 'THUMBNAIL'
    }
  },
  data() {
    return {
    }
  },
  methods: {
    fnHandleBeforeUpload(file){
      console.log(file)
    },
    fnHandleHttpRequest(){
      console.log('request')
    },
    fnHandleInput(val){
      // tips
      if(this.type === 'UploadFile'|| this.type === 'SingleFile' ){ // 因为上传文件地址不一样，故直接返回文件，由使用者主动调用接口上传
        this.$emit('input', val)
      }else{
        // 当存在图片标识且不需要缩略图的情况下，显示原图。
        this.$emit('input', (val.includes("fileSizeType=THUMBNAIL") && this.imgType !== 'THUMBNAIL') ? val.replace("fileSizeType=THUMBNAIL", "fileSizeType=SOURCE") : val)
      }
    },
    fnHandleShowChange(val){
      this.$emit('update:show', val)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}
::v-deep .avatar-noborder .el-upload{
  border: 0;
  border-radius: 0;
  cursor: pointer;
  overflow: hidden;
}

// 滑动到上面背景色
::v-deep .avatar-uploader .el-upload:hover {
  // border-color: #409EFF;
  border-color: none;
}

::v-deep .imgTips{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  text-align: center;
  color: #fff;
  background-color: rgba(0,0,0,.5);
  transition: opacity .3s;
}
img{
  display: block;
}
</style>

<style>
</style>
