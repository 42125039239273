/*
 * @Author: 聂涛
 * @Date: 2021-05-13 16:39:57
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-08 16:09:56
 * @Description: 
 */
// 扩展、自定义element ui 组件
import Vue from 'vue';
import confirm from '@/components/confirm-dialog/confirm.js';
import Alert from '@/components/alert/alert';

// https://www.npmjs.com/package/vue-clipboard2
import VueClipboard from 'vue-clipboard2'

Vue.prototype.$alert = Alert;
Vue.prototype.$confirm = confirm;
// 重置confirm
Vue.use(VueClipboard)



