<!--
 * @Author: 聂涛
 * @Date: 2022-09-01 14:21:24
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-01 14:30:32
 * @Description: 会话的头部
-->
<template lang="pug">
.flex.flex-middle.mb-20
  .l-tag
  .fs-15.fw-400.ml-8 {{ label }}
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.l-tag {
  width: 5px;
  height: 23px;
  background: #2878FF;
  border-radius: 2px;
}

</style>
<style>
.mb-20{margin-bottom:20px;}.fs-15{font-size:15px;}.fw-400{font-weight:400;}.ml-8{margin-left:8px;}</style>
