<!--
 * @Author: 建辉
 * @Date: 2020-08-12 13:17:51
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-04-15 17:10:45
 * @Description: 选择时间
-->
<template lang="pug">
el-date-picker(
    v-bind="$attrsAll"
    :type="type"
    v-model="val"
    @input="fnChange"
    :picker-options="oPickerOptions"
    key="el-date-picker"
    clearable
  )
</template>

<script>
import dayjs from 'dayjs';
import _ from 'lodash'
/***
 * 暂时只处理了 daterange 情况，再遇到 追加封装。
 */
export default {
  name: 'date-pick',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Array],
      default() {
        return []
      }
    },
    type: {
      type: String,
      default: 'daterange' // datetime year/month/date/week/ datetime/datetimerange/daterange
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd' // yyyy-MM-dd HH:mm:ss
    },
    // 设置最大时间
    maxDate: {
      type: [Date, String],
      default: ''
    },
    minDate: {
      type: [Date, String],
      default: ''
    },
    selectableRange: {
      type: String,
      default: '00:00:00 - 23:59:59'
    },
    rangeDay: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      val: '',
      minTempVal: '',
      oPickerOptions: {
        firstDayOfWeek: 1,
        onPick: date => {
          this.minTempVal = new Date(date.minDate).getTime()
        },
        disabledDate: (date) => {
          // 这里出现两种情况
          // 1存在时间范围
          if (this.rangeDay && this.rangeDay > 0 && this.minTempVal){
            console.log(this.minTempVal);
            const range = this.rangeDay * 24 * 3600 * 1000
            const max = this.minTempVal + range - 1000
            const min = this.minTempVal - range + 1000
            return date.getTime() > max || date.getTime() < min
          } else {
            // 判断是否有限制
            // eslint-disable-next-line no-lonely-if
            if ( this.maxDate !== '') {
              // 取当天的时间 23:59:59
              const ymd = dayjs(this.maxDate).format('YYYY-MM-DD')
              const maxEndDate = new Date(`${ymd} 23:59:59`)
              return date.getTime() > maxEndDate.getTime()
            } else if ( this.minDate !== '') {
              // 取当天的时间 23:59:59
              const ymd = dayjs(this.minDate).format('YYYY-MM-DD')
              const minDate = new Date(`${ymd} 00:00:00`)
              return minDate > date
            } else {
              return false
            }
          }
 
        },
        selectableRange: this.selectableRange
      }
    }
  },
  computed: {
    selfProp(){
      if(['datetimerange', 'daterange', 'monthrange'].includes(this.type)){
        return {
          'start-placeholder': "开始日期",
          'end-placeholder' : "结束日期",
          'range-separator': '至',
          'default-time': ['00:00:00', '23:59:59']
        }
      } else {
        return {}
      }
    },
    $attrsAll() {
      const options = {
        placeholder: '请选择时间',
        ...this.selfProp,
        ...this.$attrs,
      }
      return {
        'value-format': 'yyyy-MM-dd HH:mm:ss',
        format: this.format,
        ...options,
      }
    }
  },
  watch: {
    value: {
      handler(val, oldVal) {
        if(!this.type.includes('range') && val){
          this.val = dayjs(val).format('YYYY-MM-DD HH:mm:ss');
          return;
        }
        this.val = val
      },
      immediate: true
    }
  },
  methods: {
    fnChange(val) {
      if(this.val === null){
        if(this.type.includes('range')){
          this.$emit('input', [])
          this.$emit('change', [])
          return;
        }else{
          this.$emit('input', '')
          this.$emit('change', '')
        }
      }
      this.$emit('input', this.val)
      this.$emit('change', this.val)
    }
  }
}
</script>

<style>
</style>
