<!--
 * @Author: 聂涛
 * @Date: 2022-06-08 11:24:00
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-05-30 16:22:27
 * @Description: 素材内容Icon
-->
<template lang="pug">
div.pst-rlt(v-if="data")
  .bg-color.p-15(
    v-if="layout === 'column'"
    @click.stop="fnReadItem"
  )
    img.cover(
      :src="data.previewContent"
      :class=" inDialog ? 'w-300' : 'contain' "
    )
    .mt-10.fw-bold.text-ellipsis.fs-15.text-ellipsis {{data.name}}
    .mt-14.pb-10.fs-14.text-ellipsis(style="color: #666666" :class="inDialog ? '' :'bottom-1' ") {{data.keyword}}
    .flex.flex-between.mt-10
      //- 收藏
      div(v-if="!inDialog")
        xh-icon(v-if="data.collectId" style="color: #FAA500" icon="shoucang" size="18" @click.native.stop="fnClickUnCollect")
        xh-icon(v-else icon="shoucang" size="18" @click.native.stop="fnClickCollect")
      el-popover(
        v-if="!inDialog"
        placement="bottom"
        trigger="hover"
      )
        .flex
          el-tag.mr-5.mb-5(
            closable
            v-for="(data, idx) in data.tagList"
            :key="idx"
            @close="fnTagDel(data)"
          ) {{ data.tagName }}
          div(v-if="!data.tagList || data.tagList.length === 0") 暂无标签
        xh-icon(slot="reference"  :class="data.tagList && data.tagList.length ? 'theme-color' : ''  " icon="biaoqian1" size="18" @click.native.stop="fnCloseTagAdd")
      el-popover(trigger="hover" v-if="!inDialog" placement="bottom")
        div.text-center {{ isTop ? '置顶' : '取消置顶' }}
        xh-icon.cs-pt(:icon="isTop ? 'zhidingmian' : 'quxiaozhiding'" slot="reference" size="18" @click.native.stop="fnClickItemTop")
      //- 更多
      el-tag {{data.materialTypeModel.desc}}
      el-tag(type="danger") {{data.scopeTypeModel.desc}}
  .flex.bg-color.p-15.flex-1(
    v-if="layout === 'row'"
    style="width: 100%"
    @click.stop="fnReadItem"
  )
    img.w-240.h-135.cover.flex-shrink-0(:src="data.previewContent")
    .flex.flex-column.ml-15.flex-between.flex-1
      .fw-bold.fs-15.pt-8.lh-20.text-ellipsis-2 {{data.name}}
      .fs-14.lh-18.text-ellipsis-2(style="color: #666666") {{data.keyword}}
      .flex.flex-middle.pb-8
        div(v-if="!inDialog")
          //- 收藏
          xh-icon.mr-30(v-if="data.collectId" style="color: #FAA500" icon="shoucang" size="22" @click.native.stop="fnClickUnCollect")
          xh-icon.mr-30(v-else icon="shoucang" size="22" @click.native.stop="fnClickCollect")
        //- 标签
        el-popover(
          placement="bottom"
          trigger="hover"
          v-if="!inDialog"
        )
          .flex
            el-tag.mr-5.mb-5(
              closable
              @close="fnTagDel(data)"
              v-for="(data, idx) in data.tagList"
              :key="idx"
            ) {{ data.tagName }}
            div(v-if="!data.tagList || data.tagList.length === 0") 暂无标签
          xh-icon(slot="reference" :class="data.tagList && data.tagList.length ? 'theme-color' : ''  " icon="biaoqian1" size="22" @click.native.stop="fnCloseTagAdd")
        el-popover(trigger="hover" v-if="!inDialog" placement="bottom")
          div.text-center {{ isTop ? '置顶' : '取消置顶' }}
          xh-icon.cs-pt.ml-30.mr-15(:icon="isTop ? 'zhidingmian' : 'quxiaozhiding'" slot="reference" size="22" @click.native.stop="fnClickItemTop")
        el-tag {{data.scopeTypeModel.desc}}
        el-tag.ml-15(type="danger") {{data.materialTypeModel.desc}}
  xh-icon.pst-absl.t-0.l-0(v-if="!isTop" icon="zhiding1" slot="reference" size="36")
  TagAdd(:show.sync="showTagAdd" :id="data.id" @fnTagSuccess="fnTagSuccess" tagCate="MATERIAL" )

</template>

<script>
import { materialCollect, materialRemove, materialUnCollect, tagV2Remove, materialTop } from '@/api'
import TagAdd from "@/components-pages/operation-center/tag/add.vue";

export default {
  components: {
    TagAdd
  },
  props: {
    // 排列组合 row/column
    layout: {
      type: String,
      default: () => 'row'
    },
    data: {
      type: Object,
      default: () => null
    },
    // 弹窗内容不显示操作
    inDialog: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      showTagAdd: false,

    }
  },
  computed: {
    isTop () {
      return this.data.top === 0; // true 非置顶状态 false 置顶状态
    }
  },
  methods: {
    fnCloseTagAdd() {
      this.showTagAdd = true
    },
    fnTagSuccess(list) {
      this.$emit('refresh')
    },
    fnClickDel() {
      this.$confirm({
        title: "确认删除",
        msg: "您是否要删除当前素材？",
        beforeClose: async (instance, done) => {
          this.loading = true
          const [err, data] = await materialRemove({
            data: {
              id: this.data.id
            }
          })
          this.loading = false
          if (err) return
          this.$message.success('删除成功')
          this.$emit('refresh')
          done()
        }
      })
    },
    fnClickEdit() {
      this.$emit('edit-item', this.data)
    },
    // -查看详情
    fnReadItem() {
      this.$emit('read-item', this.data)
    },
    async fnClickCollect() {
      const [err, data] = await materialCollect({
        data: {
          id: this.data.id
        }
      })
      if (err) return
      this.$message.success('收藏成功')
      this.$emit('refresh')
    },
    async fnClickUnCollect() {
      const [err, data] = await materialUnCollect({
        data: {
          id: this.data.id
        }
      })
      if (err) return
      this.$message.success('取消收藏成功')
      this.$emit('refresh')
    },
    async fnTagDel(tag) {
      const [err, data] = await tagV2Remove({
        data: {
          id: this.data.id,
          tagId: tag.tagId,
          tagName: tag.tagName,
          tagCate: 'MATERIAL',
          businessType: 'MATERIAL'
        }
      })
      if (err) return
      this.$message.success('删除成功')
      this.$emit('refresh')
    },
    // 置顶操作
    async fnClickItemTop() {
      const { id } = this.data;
      let top = this.isTop ? 1 : 0; // 非置顶就置顶，反之
      const [err, data] = await materialTop({
        data: {
          id,
          top
        }
      })
      if (err) return
      this.$message.success('操作成功')
      this.fnTagSuccess()
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-color {
  background: #F7F7F7;
  border-radius: 5px;
}
.bottom-1 {
  border-bottom: 1px solid #E4E7ED;
}
.contain {
  background-size: contain;
  width: 100%;
  height: calc((100vh - 120px - 75px - 270px) * 0.32 );
  min-height: 100px;
}

.w-300 {
  width: 100%;
  height: 100px;
}

.cover {
  object-fit: scale-down;
  background: white;
}

</style>
<style>
.p-15{padding:15px;}.mt-10{margin-top:10px;}.fs-15{font-size:15px;}.mt-14{margin-top:14px;}.pb-10{padding-bottom:10px;}.fs-14{font-size:14px;}.mr-5{margin-right:5px;}.mb-5{margin-bottom:5px;}.w-240{width:240px;}.h-135{height:135px;}.ml-15{margin-left:15px;}.pt-8{padding-top:8px;}.lh-20{line-height:20px;}.lh-18{line-height:18px;}.pb-8{padding-bottom:8px;}.mr-30{margin-right:30px;}.ml-30{margin-left:30px;}.mr-15{margin-right:15px;}.t-0{top:0px;}.l-0{left:0px;}</style>
