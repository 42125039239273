<!--
 * @Author: 建辉
 * @Date: 2021-06-10 18:06:18
 * @LastEditors: 建辉
 * @LastEditTime: 2021-06-11 11:06:12
 * @Description: 选择头像
-->

<template lang="pug">
div.select-avatar
    el-select(:value="val" multiple placeholder="请选择")
      el-option(
        v-for="item in list"
        :key="item[valKey]"
        :label="item[labelKey]"
        :value="item[valKey]"
      )
        .flex.flex-between(@click="fnClickAdd(item)")
          div
            .dspl-inbl.w-30.h-30.rouned-5
              img.img-contain(:src="item[avatarKey]")
            span.ml-10 {{ item[labelKey] }}
          icon(
            v-if="avatarList.find(info => info[valKey] === item[valKey])"
            icon="xuanze" color="#00CF90"
          )
    //- .w-60.h-60.pst-rlt.rounded-5.dspl-inbl.mr-10(v-for="(item, i) in avatarList" :key="item[valKey]")
      img.img-contain(:src="item[avatarKey]")
      .fs-20.w-20.h-20.pst-absl(style="top: -15px; right: -15px;z-index: 30;")
        icon(icon="close")
      .fs-12. {{ item[labelKey] }}

      //- .w-55.h-55.bg-red.pst-rlt.mr-10.dspl-inbl(v-for="(item, i) in list" :key="item.qywxUserId")
      //-   img.img-contain(:src="item.avatar")
      //-   icon(icon="avatar")
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'select-avatar',
  props: {
    avatarList: {
      type: Array,
      default(){
        return []
      }
    },
    enumKey: {
      type: String,
      default: ''
    },
    valKey: {
      type: String,
      default: 'id'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    avatarKey: {
      type: String,
      default: 'avatar'
    }
  },
  data(){
    return {
      val: []
    }
  },
  computed: {
    ...mapState({
      avatarEnumMap: state => state.enum.avatarEnumMap
    }),
    list(){
      return this.avatarEnumMap[this.enumKey] || []
    }
  },
  mounted(){
    if(this.enumKey){
      this.$store.dispatch('enum/fetchAvatarMap', this.enumKey)
    }
  },
  methods: {
    fnClickAdd(item){
      console.log(item)
      this.$emit('update:avatarList', [...this.avatarList, item])
    }
  }
}
</script>


<style>
.w-30{width:30px;}.h-30{height:30px;}.ml-10{margin-left:10px;}.w-60{width:60px;}.h-60{height:60px;}.mr-10{margin-right:10px;}.fs-20{font-size:20px;}.w-20{width:20px;}.h-20{height:20px;}.fs-12{font-size:12px;}.w-55{width:55px;}.h-55{height:55px;}</style>
