<!--
 * @Author: 建辉
 * @Date: 2020-10-17 18:07:09
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-10-08 10:48:10
 * @Description: 统一样式的confirm
-->
<template lang="pug">
el-dialog(
  :visible.sync="show"
  :show-close="showCloseIcon"
  :append-to-body="true"
  :close-on-click-modal="false"
  :close-on-press-escape="false"
  :width='width'
  top="30vh"
)
  .text-center.mb-16(v-if="icon" :style="{color: iconColor}")
    Icon(:icon="icon" :size="58" :bPoint="true")
  .text-center.fs-18.fw-bold.mb-20(style="color: #1F2D3D" v-if="title") {{ title }}
  div(style="min-height: 20px;")
    .fs-16(style="line-height: 1.4em;" v-if="html" v-html="html")
    .fs-16.text-center(style="line-height: 1.4em;") {{ msg }}
    .fs-16.text-center.gh-warning.mt-20(style="line-height: 1.4em;" v-if="warnMsg") {{ warnMsg }}

    //- 拒绝渲染区域
    .p-15.fs-16.mt-10(
      v-if="type === 'reject'"
      style="color: #8391A5;background: #F7F9FC;min-height: 80px;")
      div.fs-16 {{ rejectMsg }}
    .p-15.fs-16.mt-10(
      v-if="type === 'reason'"
      style="color: #8391A5;background: #F7F9FC;min-height: 80px;")
      .flex
        .w-90.text-right.fs-16.fw-bold.flex-shrink-0.mt-4(style="color: #1F2D3D;line-height: 1.3em;" v-show="rejectLabel") {{ rejectLabel }}
        el-input(:autosize="{ minRows: 2, maxRows: 4}" type="textarea" placeholder="请输入" v-model="rejectReason")

    .p-15.fs-16.mt-10(
      v-else-if="showRemark"
      style="color: #8391A5;background: #F7F9FC;min-height: 80px;")
      .flex
        .w-50.text-right.fs-16.fw-bold.flex-shrink-0.mt-4(style="color: #1F2D3D;line-height: 1.3em;") 备注:
        el-input(:autosize="{ minRows: 2, maxRows: 4}" type="textarea" placeholder="请输入" v-model="remark")
  .text-center.mt-40
    xh-btn(
      v-if="showNoBtnText"
      style="margin-right: 10px;"
      @click="noClick") {{ noBtnText }}
    xh-btn(
      style="margin-right: 0;"
      @click="yesClick"
      :loading="loading"
      type="primary") {{ yesBtnText }}
</template>

<script>
import _ from 'lodash'
export default {
  name: 'confirm-dialog',
  props: {
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: '#FF9900'
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    },
    msg: {
      type: String,
      default: ''
    },
    warnMsg: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default' // default reject reason
    },
    showRemark: {
      type: Boolean,
      default: false,
    },
    rejectMsg: {
      type: String,
      default: ''
    },
    rejectLabel: {
      type: String,
      default: '拒绝原因:'
    },
    showNoBtnText: {
      type: Boolean,
      default: true // 设置显示取消按钮
    },
    noBtnText: {
      type: String,
      default: '取消' // 设置取消按钮操作
    },
    yesBtnText: {
      type: String,
      default: '确认' // 设置确认按钮操作
    },
    showCloseBtn:{
      type: Boolean,
      default: true
    },
    html: {
      type: String,
      default: ''
    },
    showCloseIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      promiseStatus: null,
      show: false,
      rejectReason: '',
      remark: '',
      loading: false,
    }
  },
  methods: {
    confirm(){
      this.show = true;
      return new Promise((resolve, reject) => {
        this.promiseStatus = {resolve, reject}
      })
    },
    noClick(){
      this.show = false;
      this.promiseStatus && this.promiseStatus.reject()
    },
    yesClick(){
      console.log('yesclick')
      if(typeof this.beforeClose === 'function'){
        new Promise((resolve, reject) => {
          // 如果是需要填写拒绝理由
          if(this.type === 'reason'){
            if(!this.rejectReason){
              this.$message.warning('请输入原因')
              return;
            }
            this.beforeClose(this, resolve, this.rejectReason)
          }else{
            this.beforeClose(this, resolve, this.remark)
          }
        }).then((data) => {
          this.show = false
          this.promiseStatus && this.promiseStatus.resolve(data);
        })
      }else{
        this.show = false
        this.promiseStatus && this.promiseStatus.resolve(this.remark || '');
      }
    },
    close(){
      this.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-textarea__inner {
  padding: 0;
  border: 0;
  background: #F7F9FC;
}
::v-deep .el-dialog {
  border-radius: 8px;
}
::v-deep .el-dialog__body {
  padding-top: 10px;
}
</style>

<style>
.mb-16{margin-bottom:16px;}.fs-18{font-size:18px;}.mb-20{margin-bottom:20px;}.fs-16{font-size:16px;}.mt-20{margin-top:20px;}.p-15{padding:15px;}.mt-10{margin-top:10px;}.w-90{width:90px;}.mt-4{margin-top:4px;}.w-50{width:50px;}.mt-40{margin-top:40px;}</style>
