<template lang="pug">
div.text-center
  span.lh-50 沪智保管理系统
</template>

<script>
export default {
  name: 'copyright',
  data(){
    return {
      tag: process.env.tag
    }
  }
}
</script>

<style>
.lh-50{line-height:50px;}</style>
