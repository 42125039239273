<!--
 * @Author: 聂涛
 * @Date: 2021-11-10 10:37:32
 * @LastEditors: 聂涛
 * @LastEditTime: 2021-12-06 10:46:49
 * @Description: 选取好友框
-->

<template lang="pug">
el-dialog(
    :visible="show"
    title="增加群成员"
    width="1000px"
    :append-to-body="true"
    :showClose="false"
    center
  )
    .flex.flex-column
      el-form(
        :inline="true"
        ref="form"
      )
        el-form-item(label="客户昵称:")
          el-input(placeholder="请输入客户昵称或者备注名" v-model="oForm.key" @input="fnClickSearch")
        el-form-item
          xh-btn(:loading="oTableData.loading" name='search', @click="fnClickSearch" type="primary") 搜索
          xh-btn(name='clear', @click="fnClickClear") 重置表单
                  //- pagination
      lb-table(
        border
        ref="table"
        v-loading="oTableData.loading"
        :column="oTableData.column"
        :data="oTableData.data"
        :current-page.sync="oTableData.pn"
        :page-size="oTableData.limit"
        :total="oTableData.total"
        @size-change="fnHandleSizeChange",
        @p-current-change="fnHandleCurrentChange"
        @selection-change="handleSelectionChange"
      )
    .flex.mt-30
      H3 已选择{{multipleSelectionAll.length}}人
    .flex.mt-30
      .flex
        .w-80.flex-middle.flex.flex-column.flex-center(v-for="item in multipleSelectionAll" :key="item.id")
          img(:src="item.avatar" style="width: 40px;height: 40px;border-radius: 20px;")
          span.my-5 {{item.nickname}}
    .flex.flex-center.mt-30
      xh-button.mx-30.w-100(@click="fnClickCancel") 取消
      xh-button.mx-30.w-100(type="primary" @click="fnClickSure") 确定
</template>

<script>
import TableSelectionMixin from '@/mixins/table-selection'
import { searchContactList, addGroupChatMember } from '@/api'
import { mapState } from 'vuex'

export default {
  name: 'chat-add-user',
  mixins: [TableSelectionMixin],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    convRemoteId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selects: [],
      oForm: {
        key: '',
      },
      pn: 0,
      limit: 10,
      total: 0,
      oTableData: {
        idKey: 'id',
        loading: false,
        data: [],
        column: [
          {
            label: '选择',
            type: 'selection'
          },
          { prop: "nickname", label: "客户微信"},
          { prop: "avatar", label: "头像", render:(h, scope) => {
            return (
              <div>
                <img src={ scope.row.avatar } style="width: 40px;height: 40px;border-radius: 20px;" />
              </div>
            )
          }},
          { prop: "id", label: "id"},
          { prop: "appID", label: "appID" },
          { prop: "appName", label: "appName" }
        ],
      }
    }
  },
  computed: {
    ...mapState({
      currentConversation: state => state?.chat?.currentConversation || {},
    })
  },
  mounted() {
    this.fnClickClear()
  },
  methods: {
    async fnGetFriends() {
      this.oTableData.loading = true
      if (this.oForm.key === '') {
        this.oTableData.loading = false
        return
      }
      const [err, data] = await searchContactList({
        data: {
          ...this.oForm,
          groupId: 0,
          isRoom: 0
        }
      })
      this.oTableData.loading = false
      if(err) return
      if (data && data.contactList) {
        const userList = []
        data.contactList.forEach(element => {
          element.contactList && element.contactList.forEach( tmp => {
            userList.push(tmp)
          })
        })
        this.oTableData.data = userList
      }
      setTimeout(() => {
        this.setSelectRow()
      }, 20)
    },
    fnClickSearch() {
      this.pn = 1
      this.fnGetFriends();
    },
    fnClickClear() {
      this.oForm = {
        key: '',
      }
      this.pn = 1
      this.oTableData.data = []
      this.fnGetFriends()
    },
    fnClickCancel() {
      this.$emit('update:show', false)
      this.fnBsnClose()
    },
    async fnClickSure() {
      const wxId4Scrm = this.multipleSelectionAll.map((v) => v.wxId4Scrm)
      if (wxId4Scrm && wxId4Scrm.length > 0) {
        const [err, data] = await addGroupChatMember({
          data: {
            convRemoteId: this.currentConversation.wxId4Scrm,
            wxId4Scrm,
            appID: this.currentConversation.appID
          }
        })
        if (err) return
        // this.$message.success('添加成功')
        this.$emit('success')
      }
      this.fnClickCancel()
    },
    /**
     * table条数切换
     */
    fnHandleSizeChange(size){
      this.pn = 1
      this.limit = size
      this.fnGetFriends();
    },
    /**
     * table页码切换
     */
    fnHandleCurrentChange(pn) {
      this.pn = pn
      this.fnGetFriends();
    },
    fnBsnClose() {
      this.fnClickClear()
      this.clearAllSection()
    }
  }
}
</script>

<style>

</style>
<style>
.mt-30{margin-top:30px;}.w-80{width:80px;}.my-5{margin-top:5px;margin-bottom:5px;}.mx-30{margin-left:30px;margin-right:30px;}.w-100{width:100px;}</style>
