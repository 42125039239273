/*
 * @Author: 聂涛
 * @Date: 2021-07-08 15:56:35
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-07 16:36:26
 * @Description: 增加xh-ui
 */

import Vue from "vue"
import XhUI from "xh-ui"

import 'xh-ui/lib/theme-chalk/index.css';

Vue.use(XhUI, {
  icon: {
    url: '//at.alicdn.com/t/font_2552820_dw4e4lcfa37.js',
  },
  imgPreview: {
    replaceQuery: '?x-oss-process=image/resize,h_100,m_lfit'
  }
})

