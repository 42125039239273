<!--
 * @Author: 建辉
 * @Date: 2020-10-17 10:44:07
 * @LastEditors: 建辉
 * @LastEditTime: 2021-06-17 14:37:44
 * @Description: 渲染label
-->
<template lang="pug">
span.fs-14(:class="[$attrs.disabled ? 'is-disabled' : '']")
    span(v-if="money") {{ (desc || value || '-') | FilterMoney }}
    span(v-else) {{ desc || value || '-'}}
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Date],
      default: ''
    },
    desc: {
      type: [String, Number],
      default: ''
    },
    money: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
span.is-disabled {
  color: #C0C4CC;
}
</style>

<style>
.fs-14{font-size:14px;}</style>
