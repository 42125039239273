<!--
 * @Author: 建辉
 * @Date: 2021-05-07 14:15:12
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-10-31 18:38:41
 * @Description: list layout页面
-->
<script>
export default {
  name: 'list-layout',
  props: {
    type: {
      type: String,
      default: 'scroll', // div 固定高度、内容无法滚动，scroll 固定高度、内容可以滚动
    },
    height: {
      type: String,
      default: '196px'
    }
  },
  computed: {
    setHeight() {
      const height = this.height;
      return { height: `calc(100vh - ${height})` }
    }
  },
  methods: {
    renderPage() {
      return <div>
        {this.$slots.default}
      </div>

    }
  },
  render() {
    return this.renderPage();
  }
}
</script>
