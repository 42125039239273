
import {instance} from '@/api/http'


export const state = () => ({
  counter: 0
})

export const actions = {
  nuxtServerInit({ commit, state, dispatch }, { app, store, route, req, res, error, redirect }){
    console.log('store/index.js: server-init')
    instance.defaults.headers.post.Cookie = req.headers.cookie || ''
    instance.defaults.headers.get.Cookie = req.headers.cookie || ''
    instance.defaults.headers['Content-Type'] = 'application/json'
    // 保存浏览器请求header信息到请求实例上
    instance.defaults.reqHeader = req.headers
    store.commit('user/UPDATE_MENU_ACTIVE_TAB_INDEX', +this.$cookies.get('hms_permission_active_tab_index') || 0)
  }
}
