<!--
 * @Author: 建辉
 * @Date: 2020-09-17 10:41:37
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-11-08 16:28:46
 * @Description: 常规图标封装 beta 1.0
-->

<template lang="pug">
throttle-btn(
  @clickEmit="fnClick"
  )
  el-button.wp-100.mr-10(
    :size="size"
    v-bind='$attrs',
    :loading="false"
    :style='getStyle'
  )
    i.el-icon-loading(v-if="$attrs.loading")
    icon(
      v-else-if="icon || getIcon.icon"
      :icon='icon || getIcon.icon',
      size="16"
      )
    .dspl-inbl(:style='{ paddingLeft: icon || getIcon.icon ? "2px" : "0px" }')
      template(v-if='$slots.default')
        slot
      template(v-else)
        span {{ getIcon.label }}
</template>

<script>
import throttleBtn from '@/components/throttle-btn'

const iconMapping = {
  search: {
    label: '查询',
    type: '',
    icon: 'sousuo'
  },
  clear: {
    label: '重置',
    type: '',
    icon: 'reset'
  },
  del: {
    label: '删除',
    type: '',
    icon: 'del'
  },
  add: {
    label: '新增',
    type: 'primary',
    icon: 'add'
  },
  detail: {
    label: '详情',
    type: 'text',
    icon: 'detail'
  },
  addText: {
    label: '新增',
    type: 'text',
    icon: 'tianjiajiahaowubiankuang'
  },
  edit: {
    label: '编辑',
    type: 'primary'
  },
  save: {
    label: '保存',
    type: 'primary'
  },
  cancel: {
    label: '取消',
    type: ''
  },
  export: {
    label: '导出全部',
    type: 'text',
    icon: 'tubiao_jiyao-daochu'
  },
  import: {
    label: '导出全部',
    type: 'text',
    icon: 'import'
  }
}
export default {
  name: 'xh-btn',
  components: {
    throttleBtn
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  data() {
    return {
      themeColor: ''
    }
  },
  computed: {
    getIcon() {
      if (this.name) {
        return iconMapping[this.name]
      } else {
        return {}
      }
    },
    getStyle() {
      let result = {
        display: 'block'
      };
      if (!this.$attrs.type) {
        result = {
          ...result,
        }
      }
      if(this.$attrs.isBlock){
        result.width = '100%';
      }
      return result;
    },

  },
  methods: {
    fnClick(){
      // 如果xh-btn可以点击状态
      if(!this.$attrs.loading && !this.$attrs.disabled){
        this.$emit('click')
      }
    }
  }
}
</script>

<style>
.wp-100{width:100%;}.mr-10{margin-right:10px;}</style>
