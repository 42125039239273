/*
 * @Author: 建辉
 * @Date: 2020-10-17 18:16:18
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-10-08 10:48:54
 * @Description: 实例化confirmdialog
 */

import Vue from 'vue'
import message from './index.vue'
const VueComponent = Vue.extend(message);
let vm = null;
let init = false;
const defaultOptions = {
  icon: '',
  title: '',
  msg: '',
  warnMsg: '', // 红色提示信息
  width: '400px', // 默认宽
  type: 'default',
  html: '', // 动态的html
  rejectMsg: '',
  remark: '',
  yesBtnText: '确认',
  showRemark: false,
  noBtnText: '取消',
  rejectLabel: '拒绝原因:',
  showNoBtnText: true,
  beforeClose: null, // 关闭前的回调，会暂停实例的关闭
  loading: false,
  rejectReason: '', // 拒绝原因
  showCloseBtn:true,
  showCloseIcon: false, // 又上角放回按钮
};


const confirm = function (options) {
  if (!init) {
    vm = new VueComponent().$mount()
    document.body.appendChild(vm.$el);
    init = true;
  }
  vm && Object.assign(vm, defaultOptions, options);
  return vm.confirm()
};

export default confirm;
