<!--
 * @Author: 赵伟
 * @Date: 2021-12-01 09:49:24
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-12-02 20:30:17
 * @Description: file content
-->
<template lang="pug">
  .h-60.bg-white.pst-fix.t-0.l-0.r-0.head-box.header-top-box.flex.flex-between
    div.hp-100.header-top-logo.text-center.flex.flex-middle.flex-center.ml-10(:class="[collapseMenu ? '' : 'show-logo']")
      //img.img-contain(v-show="!collapseMenu" style="width: 80%;" src="https://static.fostars.com/oms/20211201145801.png")
      img.img-contain( src="https://static.fostars.com/ins/HZB001/img/hzbIcon.jpg")
      span.fs-22.color-28781.ml-12.fw-bold(v-show="!collapseMenu" ) 沪智保管理系统
      //img.img-contain(v-show="collapseMenu" style="width: 40%;" src="https://static.fostars.com/oms/20211201145801.png")
    .flex.flex-between.ml-50
      .flex.flex-middle.flex-center.pr-10
        .flex.flex-middle.flex-right
          icon.fs-18(icon="zhanghao")
          span.fs-14.ml-7.fw-400 {{userInfo.name}}
        .fs-14.ml-20.cs-pt.flex.flex-middle(@click="fnClickLogout")
          icon.fs-18(icon="tuichu")
          span.fs-14.ml-10.fw-400 退出
</template>

<script>
import {mapState} from 'vuex';
import { logOut } from "@/api";
export default {
  data(){
    return {
      bLoadingKefu: false
    }
  },
  computed: {
    ...mapState({
      collapseMenu: state => state.client.collapseMenu,
      userInfo: state => state.user.userInfo,
      menuList: state => state.user.permission.menuList,
      topActiveTabIndex: state => state.user.menu.topActiveTabIndex
    }),
    topNavList(){
      return this.menuList.children
    },
    navList(){
      return this.menuList.children[this.topActiveTabIndex] && this.menuList.children[this.topActiveTabIndex].children || []
    },
    firstNav(){
      const navItem = this.navList && this.navList[0] || {data: {}};
      if(navItem.children && navItem.children.length){
        return navItem.children[0] && navItem.children[0].data
      }else{
        return navItem.data
      }
    }
  },
  methods: {
    fnClickLogout() {
      this.$confirm({
        title: '请确认',
        msg: '确认退出登录吗?',
        beforeClose: async (instance, done) => {
          instance.loading = true
          const [err, data] = await logOut()
          instance.loading = false
          if (err) return
          done()
        },
      }).then(() => {
        this.$message.success('退出成功')
        this.$router.push(`/login`)
      })

    },
    fnClickMenus(index){
      if(this.topActiveTabIndex === index) return;
      this.$store.commit('user/UPDATE_MENU_ACTIVE_TAB_INDEX', index);
      const url = this.firstNav.accessUrl
      url && this.$router.push(this.firstNav.accessUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
.head-box{
  box-shadow: 0px 1px 5px 0px rgba(190, 196, 205, 0.48);
  z-index: 1002;
}
.header-top-logo {
  /* transition: all linear .3s; */
  width: 40px;
  &.show-logo{
    width: 240px;
  }
}
.active{
  font-weight: bold;
}

.color-28781{
  color: #2E6BFF;
}
</style>

<style>
.h-60{height:60px;}.t-0{top:0px;}.l-0{left:0px;}.r-0{right:0px;}.hp-100{height:100%;}.ml-10{margin-left:10px;}.fs-22{font-size:22px;}.ml-12{margin-left:12px;}.ml-50{margin-left:50px;}.pr-10{padding-right:10px;}.fs-18{font-size:18px;}.fs-14{font-size:14px;}.ml-7{margin-left:7px;}.fw-400{font-weight:400;}.ml-20{margin-left:20px;}</style>
