<!--
 * @Author: 建辉
 * @Date: 2020-08-04 18:06:19
 * @LastEditors: 建辉
 * @LastEditTime: 2020-10-22 11:31:10
 * @Description: file content
-->
<script>
export default {
  name: 'LbRender',
  functional: true,
  props: {
    scope: Object,
    render: Function
  },
  render: (h, ctx) => {
    return ctx.props.render ? ctx.props.render(h, ctx.props.scope) : ''
  }
}
</script>
