<!--
 * @Author: 建辉
 * @Date: 2021-05-07 10:20:48
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-10-26 12:04:59
 * @Description: 渲染菜单子组件
-->

<template lang="pug">
div
  template(
    v-for="(item, index) in menuList"
  )
    el-submenu(
      :key="item.id"
      v-if="item &&  item.children && item.children.length > 0"
      :index="`${item.id}`"
    )
      template(slot="title")
        .dspl-inbl.fs-18.w-18.h-18.lh-17.mr-6(v-if="item.data.icon" )
          h-icon(:name="item.data.icon" :class="[item.data.icon]")
        span(slot="title") {{ item.name }}
      menu-children(
        v-if="item.children && item.children.length > 0"
        :menuList="item.children"
        )
    el-menu-item(
      v-else
      :key="`${item.id}`"
      :route="handleIndex(item)"
      :index="item.data.accessUrl"
    )
      .dspl-inbl.fs-18.w-18.h-18.lh-17.mr-6(v-if="item.data.icon" )
        h-icon(style="vertical-align: middle;" :name="item.data.icon")
      span(slot="title") {{ item.data.name }}
</template>

<script>
export default {
  name: 'menu-children',
  props: {
    menuList: {
      type: Array,
      default(){
        return []
      }
    }
  },
  methods: {
    // 如果是http链接处理一下
    handleIndex(item) {
      if (item.data?.accessUrl?.startsWith('http')) {
        const encodeUrl = encodeURIComponent(item.data?.accessUrl)
        const encodeName = encodeURIComponent(item.data?.name)
        const id = item.id
        return `/third/${id}?ref=${encodeUrl}&name=${encodeName}`
      } else {
        return item.data?.accessUrl
      }
    }
  }
}
</script>

<style>
.fs-18{font-size:18px;}.w-18{width:18px;}.h-18{height:18px;}.lh-17{line-height:17px;}.mr-6{margin-right:6px;}</style>
