<!--
 * @Author: 建辉
 * @Date: 2020-08-25 11:42:30
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-05-30 16:30:06
 * @Description: 素材分类管理
-->
<template lang="pug">
.tree-container(v-loading='bTreeLoading')
  el-tree.fs-14(
    v-if='oSelectedTree.id',
    ref='oTreeRef',
    v-loading='bTreeLoading',
    element-loading-text='加载中...'
    element-loading-spinner='el-icon-loading'
    :data='aTreeData',
    :props='defaultProps',
    @node-click='fnClickTreeNode',
    @node-expand='fnBsnNodeExpand',
    @node-collapse='fnBsnNodeCollapse',
    node-key='id',
    highlight-current,
    :render-content='renderContent',
    :default-expanded-keys='aExpandedList',
    :expand-on-click-node='false',
    :current-node-key='oSelectedTree.id'
    :draggable="draggable"
    :allow-drop="allowDrop"
    :allow-drag="allowDrag"
    @node-drag-start="handleDragStart"
    @node-drop="handleDrop"
    :current-change="handleCurrentChange"
  )
</template>

<script>
import {
  materialCateTree,
  materialCateTreeChat,
  materialCateTreeDelete,
  materialCateTreeDrop
} from '@/api'

export default {
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    form: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: true
    },
    inChat: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      bTreeLoading: true, // tree loading
      oSelectedTree: {},
      aExpandedList: [], // 展开的id
      defaultProps: {
        children: 'child',
        label: 'name'
      },
      aTreeData: [],
      bLoadingList: false,
      nCurrentPage: 1,
      total: null,
      limit: 20,
      copyTreeList: [], // 拷贝数据
    }
  },
  watch: {
    oSelectedTree: {
      handler() {
        if (this.oSelectedTree.id) this.$emit('select-tree', this.oSelectedTree)
      },
      immediate: true,
      deep: true
    },
    id: {
      handler(val) {
        this.fnNetROrgTree(val)
      },
      immediate: true
    },
    form: {
      handler(val) {
        this.fnNetROrgTree(val)
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {
    allowDrop() {
      return true;
    },
    allowDrag() {
      return true;
    },
    handleDragStart() {
      this.copyTreeList = JSON.parse(
        JSON.stringify(this.aTreeData)
      );
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log('draggingNode:==', draggingNode, draggingNode.label);
      // console.log('dropNode:==', dropNode, dropNode.label);
      // console.log('dropType:==', dropType);
      // console.log('ev:==', ev);
      this.$confirm({
        width: '500px',
        title: "提示",
        msg: `是否将 “${draggingNode.label}” 节点移动到 “${dropNode.label}” ${
          dropType === "before" ? "之前" : dropType === "after" ? "之后" : "下"
        }?`,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (instance, done) => {
          instance.loading = true
          const [err, res] = await materialCateTreeDrop({
            data: {
              id: draggingNode.data.id,
              toId: dropNode.data.id,
              dropType
            }
          })
          instance.loading = false
          if (err) return
          return done()
        }
      })
        .then((res) => {
          // 成功之后调用接口
          // console.log('res:==', res)
          this.$message.success("移动成功!");
          this.fnNetROrgTree()
        })
        .catch(() => {
          this.aTreeData= this.copyTreeList;
          this.$message.info("取消移动");
        });
    },
    handleCurrentChange(dropData, dropNode) {
      console.log('handleCurrentChange', dropData, dropNode)
    },
    fnClickTreeNode(data) {
      this.$refs.oTreeRef.setCurrentKey(data.id)
      this.oSelectedTree = data
    },
    // 展开
    fnBsnNodeExpand(data) {
      // 在节点展开是添加到默认展开数组
      this.aExpandedList.push(data.id)
    },
    // 收起
    fnBsnNodeCollapse(data) {
      this.aExpandedList.splice(this.aExpandedList.indexOf(data.id), 1)
    },

    renderContent(h, { node, data, store }) {
      return (
        <span class="tree-node">
          {this.oSelectedTree.id === data.id ? (
            <span class="theme-color">
              {node.label.slice(0, 20)}
              <span>{node.label.length > 20 ? '...' : ''}</span>
            </span>
          ) : (
            <span>
              <span>{node.label.slice(0, 20)}</span>
              <span>{node.label.length > 20 ? '...' : ''}</span>
            </span>
          )}
          {
            this.edit ? <span class="drop-wrap">
              <dropdown-btn trigger="click" class="custom-item">
                <el-dropdown-item>
                  <xh-button
                    type="text"
                    icon="xiugai"
                    on-click={() => this.fnClickEditFunctionItem(node, data)}
                  >
                    编辑
                  </xh-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <xh-button
                    type="text"
                    icon="tianjia2"
                    on-click={() => this.fnClickAddFunctionItem(node, data)}
                  >
                    添加
                  </xh-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <xh-button
                    icon="delete"
                    type="text"
                    on-click={() => this.fnClickRemoveFunction(node, data)}
                  >
                  删除
                  </xh-button>
                </el-dropdown-item>
              </dropdown-btn>
            </span> : null
          }
        </span>
      )
    },
    // 添加
    fnClickEditFunctionItem(node, data) {
      this.fnClickTreeNode(data)
      this.$emit('editItem', data)
    },
    // 添加
    fnClickAddFunctionItem(node, data) {
      this.fnClickTreeNode(data)
      this.$emit('addItem', data)
    },
    // 删除
    fnClickRemoveFunction(node, data) {
      this.fnClickTreeNode(data)
      this.$confirm({
        title: '温馨提示',
        msg: `确认要删除 ${data.name} 吗?`,
        beforeClose: async (instance, done) => {
          instance.loading = true
          const [err, res] = await materialCateTreeDelete({
            data: {
              id: data.id
            }
          })
          instance.loading = false
          if (err) return
          return done()
        }
      }).then((res) => {
        this.fnNetROrgTree()
        this.$message.success('删除成功')
      }).catch(() => {})
    },

    fnBsnGetTreeNode(tree, id) {
      let isGet = false
      let retNode = null
      function deepSearch(tree, id) {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].children && tree[i].children.length > 0) {
            deepSearch(tree[i].children, id)
          }
          if (id === tree[i].id || isGet) {
            isGet || (retNode = tree[i])
            isGet = true
            break
          }
        }
      }
      deepSearch(tree, id)
      return retNode
    },
    // 获取机构tree
    /**
     * @description R 机构tree
     *
     * @param {String} sesetSelectKey 选择的key 没有的话 取根节点
     *
     *
     */
    async fnNetROrgTree(sesetSelectKey) {
      this.bTreeLoading = true
      const method = this.inChat ? materialCateTreeChat : materialCateTree
      let params = {}
      if (this.inChat) {
        const { current, size, ...args } = this.form
        params = {
          ...args
        }
      } else {
        params = {
          id: this.id
        }
      }
      const [err, data] = await method({
        data: params
      })
      this.bTreeLoading = false
      if (err) return
      this.aTreeData = data || []
      if (!data || !data.length) return
      const aMidKey = this.aTreeData.map((v) => v.id)
      // 第一次进入页面时，query中orgId 要选中org
      if (sesetSelectKey) {
        const oTree = this.fnBsnGetTreeNode(this.aTreeData, sesetSelectKey)
        if (oTree && oTree.id) {
          let aExpandedList = oTree.fullpathId.split('/').map(v => v)
          if (aExpandedList.length > 1 ) {
            aExpandedList.splice(-1)
          } else {
            aExpandedList = aMidKey
          }
          // 默认展开
          this.aExpandedList = aExpandedList
          // 默认选中
          this.oSelectedTree = oTree
          this.$nextTick(() => {
            this.$refs.oTreeRef.setCheckedKeys([sesetSelectKey])
          })
        } else {
          this.aExpandedList = aMidKey
          this.oSelectedTree = this.aTreeData[0]
        }
      } else {
        this.aExpandedList = aMidKey
        this.oSelectedTree = this.aTreeData[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.tree-container {
  min-height: 200px;
  ::v-deep .drop-wrap {
    margin-left: 10px;
  }
  ::v-deep .tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    position: relative;
  }
}
</style>

<style>
.fs-14{font-size:14px;}</style>
