<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:03:10
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-05 11:38:51
 * @Description: Description
-->
<template lang="pug">
div
  client-only
    qEditor(ref="editor" style="border: 1px solid #CCC" :maxLength="maxLength")
    el-popover.mt-10.dspl-inbl(
      v-if="!showEmoji"
      placement="bottom-start"
    )
      EmojiDialog(
        @fnClickAddEmoji="fnClickAddEmoji"
        @fnClickAddOtherEmoji="fnClickAddOtherEmoji"
      )
      .w-30.h-30.mr-12.fs-30.cs-pt(
        slot="reference"
      )
        xh-icon(icon="emoji" size="30")
    span.color-red 最大可输入{{ maxLength }}个字符
</template>
<script>
import qEditor from '@/components/q-editor';
import { emojiChange, emojiTextList, emojiValList} from '@/utils';
import EmojiDialog from '@/components-pages/chat/emoji-dialog/index.vue'

export default {
  name: 'h-txt',
  components: {
    qEditor,
    EmojiDialog
  },
  props: {
    maxLength: {
      type: Number,
      default: 2000,
    },
    showEmoji: {
      type: Boolean,
      defalut: false,
    }
  },
  data() {
    return {
      emojiTextList,
      emojiValList
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      // 默认添加空
      this.$refs.editor.addText('')
    }, 20)
  },
  methods: {
    // 获取输入的内容
    fnGetEditor() {
      return this.$refs.editor.getText() || '';
    },
    fnSetEditor(val) {
      if (val.materialType === 'txt') {
        return this.$refs.editor.addText(val.content)
      }
    },
    // 清除输入的内容
    fnClearEditor() {
      this.$refs.editor.clearText()
    },
    // 点击 emoji=
    fnClickAddEmoji({item, i}){
      const editor = this.$refs.editor
      editor.addEmoji(i)
    },
    fnClickAddOtherEmoji({item, i}){
      const editor = this.$refs.editor
      editor.fnClickAddOtherEmoji(item)
    },
    // 获取emoji
    getEmojiFormText(txt){
      return emojiChange(txt);
    },
  }
}
</script>
<style>
.mt-10{margin-top:10px;}.w-30{width:30px;}.h-30{height:30px;}.mr-12{margin-right:12px;}.fs-30{font-size:30px;}</style>
