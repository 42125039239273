<!--
 * @Author: 赵伟
 * @Date: 2020-10-13 14:14:51
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-12-16 14:42:47
 * @Description: 封装导出dialog
-->
<template lang="pug">
  div
    h-dialog(
      :visible.sync="show"
      :title="title"
      :show-footer="false"
      :width="width"
      @close="handleClose"
    )
      div(style="height: 70vh")
        h-table(
          id="export-list-log"
          ref="export-list-log"
          :columns="oTableExport.columns"
          :data="oTableExport.data"
          :total="oTableExport.total"
          :loading="bLoadingExport"
          @change="fnNetGetList"
          :needHistory="false"
        )
</template>

<script>
import { downloadTaskList } from '@/api';
import { mapState } from 'vuex';
export default {
  props: {
    width: {
      type: String,
      default: '1000px'
    },
    title: {
      type: String,
      default: '历史导出记录'
    },
    bDownLoadFlag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      show: false,
      // 导出职工管理列表
      oTableExport: {
        data: [],
        total: 0,
        columns: [
          { prop: 'fileName', label: '名称' },
          // { prop: 'finishItem', label: '进度', width: '170px',
          //   render: (h, scope) => {
          //     return (
          //       <div>
          //         <el-progress show-text={false} percentage={scope.row.totalItem === 0 ? 0 : parseInt(((scope.row.finishItem || 0) / (scope.row.totalItem || 1) * 100).toFixed(2))}></el-progress>
          //         <div>{parseInt(((scope.row.finishItem || 0) / (scope.row.totalItem || 1) * 100).toFixed(0))}%</div>
          //       </div>
          //     )
          //   }
          // },
          { prop: 'fileUrl', label: '下载地址', render: (h, scope) => {
            return (
              <div>
                <div class="cs-pt theme-color" on-click={ () => this.fnClickDownload(scope.row)}>{scope.row.fileUrl }</div>
              </div>
            )
          }
          },
          { prop: 'finishAt', label: '完成时间', width: '200px'},
          { prop: 'status', label: '状态', width: '100px'},
        ]
      },
      bLoadingExport: false,
      nCurrentPageExport: 1,
      sTimeout: null
    }
  },
  computed: {
    ...mapState({
      roles: state => state.permission.roles,
      oExport: state => state.client.oExport
    })
  },
  watch: {
    show(val){
      if(val){
        this.nCurrentPageExport = 1
        // 初始化
        this.oTableExport.data = []
        this.fnBsnStartExport();
      }
    },
    oExport: {
      handler(val) {
        this.show = val.show
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    fnBsnStartExport(){
      clearInterval(this.sTimeout)
      // 获取 列表
      if (this.$refs['export-list-log']) {
        this.$refs['export-list-log'].refresh();
      }
      // 判断是否需要 循环导出进度
      if(this.bDownLoadFlag) this.fnBsnCircleList();
    },
    // 弹出展示 导出列表
    async fnNetGetList(payload){
      const { pn, size } = payload;
      this.nCurrentPageExport = + pn || 1
      if(!this.bDownLoadFlag) this.bLoadingExport = true
      const params = {
        data: {
          current: this.nCurrentPageExport,
          size
        }
      }
      const [err, data] = await downloadTaskList(params)
      if(!this.bDownLoadFlag) this.bLoadingExport = false
      if(err) {
        clearInterval(this.sTimeout)
        return
      };
      this.oTableExport.total = data.totalCount;
      this.oTableExport.data = data.list;
    },
    // 循环查询列表
    fnBsnCircleList(){
      this.sTimeout = setInterval( () => {
        // 获取数据
        if (this.$refs['export-list-log']) {
          this.$refs['export-list-log'].refresh();
        }
        // 查询是否全部是1
        const circleList = this.oTableExport.data.filter(item => item.status === '执行中')
        if(!circleList.length){
          clearInterval(this.sTimeout)
        }
      }, 1000);
    },
    // --- 下载 ---
    fnClickDownload(row){
      // const urlList = this.oTableExport.data[index].urlList;
      // if(this.oTableExport.data[index].isDownload === 'YES'){
      //   this.$emit('fnHandleDownload', urlList);
      // }
      window.open(row.fileUrl, '_blank')
    },
    // 关闭
    handleClose(){
      this.nCurrentPageExport = 1
      // 初始化
      this.oTableExport.data = []
      this.$store.dispatch('client/toggleDownload', {show: false, download: true})
      clearInterval(this.sTimeout);
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-dialog__body{
  padding: 0 20px 20px 20px!important;
}
</style>

<style>
</style>
