/*
 * 获取文件的相对位置
 * @Author: liujianhui@lexue.com
 * @Date: 2019-10-28 14:52:00
 * @Last Modified by: jianhui
 * @Last Modified time: 2020-06-20 15:42:18
 */

/**
  * 返回是否在可视区内
  * @param {ele} ele 当前dom
  */
export const isInviewPort = (ele) => {
  // 元素顶部 距离 视口左上角 的距离top <= 窗口高度 （反例:元素在屏幕下方的情况）
  // 元素底部 距离 视口左上角 的距离bottom > 0 (反例:元素在屏幕上方的情况)
  // 元素display样式不为none
  const notBelow = ele.getBoundingClientRect().top <= window.innerHeight;
  const notAbove = ele.getBoundingClientRect().bottom >= 0;
  const visable = getComputedStyle(ele).display !== 'none';
  return !!(notBelow && notAbove && visable);
};

// 如果元素到达浏览器顶部，就返回true
export const lessTop = (ele) => {
  const notAbove = ele.getBoundingClientRect().top <= 0;
  return notAbove;
}

// 获取指定dom元素距离页面顶部的距离
export const getTargetElementTop = (ele) => {

};

export const jumpToTargetPosition = (ele) => {

};

/**
 *
 * @param {Element} el 滚动到指定元素的位置
 */
export const scrollIntoView = (el) => {
  if(!el) return;
  el.scrollIntoView({
    // 滚动到指定节点
    // 值有start,center,end，nearest，当前显示在视图区域中间
    block: 'center',
    // 值有auto、instant,smooth，缓动动画（当前是慢速的）
    behavior: 'smooth',
  })
}

// 获取元素react
export const getElementReact = (element) => {
  return element.getBoundingClientRect();
};

// 获取绝对位置的横坐标和纵坐标。

export const getElementLeft = (element) => {
  let actualLeft = element.offsetLeft;
  let current = element.offsetParent;
  while (current !== null) {
    actualLeft += current.offsetLeft;
    current = current.offsetParent;
  }
  return actualLeft;
};

export const getElementTop = (element) => {
  let actualTop = element.offsetTop;
  let current = element.offsetParent;
  while (current !== null) {
    actualTop += current.offsetTop;
    current = current.offsetParent;
  }
  return actualTop;
};


/**
 * 获取滚动方向
 * @returns right left down up
 */
const scrollAction = {x: 'undefined', y: 'undefined'};
let scrollDirection;

export const getScrollDirection = () => {
  if (typeof scrollAction.x === 'undefined') {
    scrollAction.x = window.pageXOffset;
    scrollAction.y = window.pageYOffset;
  }
  const diffX = scrollAction.x - window.pageXOffset;
  const diffY = scrollAction.y - window.pageYOffset;
  if (diffX < 0) {
    // Scroll right
    scrollDirection = 'right';
  } else if (diffX > 0) {
    // Scroll left
    scrollDirection = 'left';
  } else if (diffY < 0) {
    // Scroll down
    scrollDirection = 'down';
  } else if (diffY > 0) {
    // Scroll up
    scrollDirection = 'up';
  } else {
    // First scroll event
  }
  scrollAction.x = window.pageXOffset;
  scrollAction.y = window.pageYOffset;
  return scrollDirection;
};

/**
 * 判断是否触底
 * @returns Boolean
 */
export const checkScrollBottom = (diff = 100) => {
  const windowScrollY = window.scrollY;
  // 判断是否到达底部
  const bodyEl = document.getElementsByTagName('body')[0];
  const bodyHeight = getElementReact(bodyEl).height;
  const screenHeight = window.screen.height;
  console.log(windowScrollY, screenHeight, bodyHeight);

  if(windowScrollY + screenHeight + diff >= bodyHeight){
    console.log('触底了');
    return true;
  }else {
    return false;
  }
};
