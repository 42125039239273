/*
 * @Author: 建辉
 * @Date: 2021-08-04 11:42:32
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-28 11:59:32
 * @Description: 右键菜单指令
 */

import Vue from 'vue';

Vue.directive('cmenu', {
  bind (el, binding, vnode, oldVnode) {
    el.addEventListener('contextmenu', e => {
      e.preventDefault();
      console.log('contextMenu call -------------');
      const data = binding.value.propData;
      console.log('data -------', data);
      if(binding.value.limitHelper){
        // 如果限制小助手才能显示
        if(parseInt(data.isSend) === 0){
          return;
        }
      }
      if(data.data?.disabled) {
        return
      }
      const refVm = binding.value.ref;
      if(!refVm) return;
      if(refVm.bShowMenu){
        return;
      }

      refVm.left = e.pageX;
      refVm.top = e.pageY;
      // 当点击位置较低 需要控制模块上移一部分
      if (e.pageY > document.body.clientHeight - 80 ) {
        refVm.top -= 50
      }
      refVm.bShowMenu = true;
      // 暂时这样处理一下吧
      if (el.dataset.item) {
        try {
          refVm.propData = JSON.parse(el.dataset.item)
        } catch (error) {
          console.log(error);
        }
      } else {
        refVm.propData = data
      }
    })
  }
})
