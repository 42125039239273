<!--
 * @Author: 聂涛
 * @Date: 2022-09-22 09:52:23
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-16 10:16:04
 * @Description: 统一emoji弹窗
-->
<template lang="pug">
el-scrollbar.y-bar.w-310.h-300
  .w-30.h-30.p-2.text-center.mb-4.mr-4.dspl-inbl.cs-pt.emoji-box.select-none(
    v-for="(item, i) in emojiTextList"
    :key="item"
    @click="fnClickAddEmoji(item, i)"
  )
    .lh-30.dspl-inbl
    img.img-contain.vtal-m(
      :src="'https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/'+i+'.gif'"
    )
  .w-30.h-30.p-2.text-center.mb-4.mr-4.dspl-inbl.cs-pt.emoji-box.select-none(
    v-for="(item, i) in emojiValList"
    :key="item"
    @click="fnClickAddOtherEmoji(item, i)"
  )
    .lh-30.dspl-inbl
    img.img-contain.vtal-m(
      :src="'https://static.fostars.com/scrm/img/'+item+'.png'"
    )
</template>

<script>

import { emojiTextList, emojiValList } from '@/utils';
export default {

  data() {
    return {
      emojiTextList,
      emojiValList,
    }
  },
  methods: {
    fnClickAddEmoji(item, i) {
      this.$emit('fnClickAddEmoji', {
        item,
        i
      })
    },
    fnClickAddOtherEmoji(item, i) {
      this.$emit('fnClickAddOtherEmoji', {
        item,
        i
      })
    }
  }
}
</script>

<style>

</style>
<style>
.w-310{width:310px;}.h-300{height:300px;}.w-30{width:30px;}.h-30{height:30px;}.p-2{padding:2px;}.mb-4{margin-bottom:4px;}.mr-4{margin-right:4px;}.lh-30{line-height:30px;}</style>
