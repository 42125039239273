<!--
 * @Author: 建辉
 * @Date: 2020-09-23 18:50:01
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-03-17 13:43:46
 * @Description: 上传单张图片，身份证
-->
<template lang="pug">
div.pst-rlt.dspl-inbl(style="line-height: 1;" :style="style")
  div.pst-rlt(
    v-if="bLoading"
    :style="style"
  )
    .fs-22.lh-22.theme-color.l-0.r-0.t-0.b-0.pst-absl(
        style="background: rgba(0,0,0,0.6)"
        v-if="bLoading"
      )
        .v.l-0.r-0.text-center
          i.el-icon-loading
          div.fs-14.fw-bold 上传中...
  .pst-rlt.wp-100.hp-100.box-id-card.ovfl-hd(
    v-if="!bLoading"
  )
    //- 如果没有图片
    el-upload(
      v-if="!value"
      :show-file-list="false"
      v-bind="$attrs"
      action="#"
      :accept="acceptVal"
      :before-upload="fnHandleBeforeUpload"
      :http-request="fnHandleHttpRequest"
    )
      div(:style="style")
    .pst-absl.l-0.r-0.b-0.t-0(v-else)
      .cover
      .action-area.flex.flex-middle.lh-0
        div.flex.flex-middle.w-25.h-25(@click.stop="fnClickViewImg")
          icon(v-if="value" icon="chakan1" :size="22" color="white")
        div.flex.flex-middle.w-25.h-25(
          v-if="!disable"
          @click="fnClickDelImg"
        )
          icon(v-if="value" icon="delete" :size="22" color="white")
      .wp-100.hp-100.flex.flex-middle.flex-center
        img.img-preview(v-if="value" :src="value" class="avatar")
</template>

<script>
import {mapState} from 'vuex'
// import { uploadCompanyFile, uploadGhFile} from '@/api'
import { uploadFile } from '@/api';

export default {
  name: 'id-card',
  components: {
  },
  props: {
    value: {
      type: String,
      default: 'https://int-gx.fostars.com/api/ent/file/download/9de22540411e11eb6ee6ad7572048762?flowno=9de22540411e11eb6ee6ad7572048762&fileSizeType=THUMBNAIL'
    },
    disable: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '94'
    },
    height: {
      type: String,
      default: '60'
    },
    direction: {
      type: String,
      default: 'front'
    },
    waterMarkFlag: {
      type: Boolean,
      default: true // 默认需要水印
    },
    fileTypeName:{
      type:String,
      default:''
    }
  },
  data(){
    return {
      bLoading: false,
      bShowPreview: false,
      themeColor: process.env.THEME_COLOR,
      isHover: false,
      accept: 'img'
    }
  },
  computed: {
    style(){
      const oImgMap = {
        front: 'https://static.fostars.com/h5/20200924140642.png',
        back: 'https://static.fostars.com/h5/20200924140643.png'
      }
      const options = {
        width: this.width + 'px',
        height: this.height + 'px',
        textAlign: 'center',
        lineHeight: this.height + 'px',
      }
      const key = 'front' in this.$attrs ? 'front' : 'back'
      if(!this.value){
        options.background = `url(${oImgMap[key]}) no-repeat center center/cover`
      }
      return options;
    },
    acceptVal(){
      // 常用MIME类型 https://www.w3cschool.cn/htmltags/att-input-accept.html
      const acceptMap = {
        img: 'image/*',
        xsl: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pdf: 'application/pdf',
        audio: 'audio/*',
        video: 'video/*',
        zip: 'aplication/zip',
      }
      return acceptMap[this.accept]
    },
    ...mapState({
      roles: state => state.permission.roles
    })
  },
  methods: {
    fnHnadleChangeHover(val){
      this.isHover = val
    },
    fnClickViewImg(){
      this.$preview({
        list: [this.value],
        index: 0
      })
    },
    fnHandleBeforeUpload(file){
      this.file = file
    },
    async fnHandleHttpRequest(){
      if(this.bLoading){
        this.$message.warning('请稍后')
        return;
      }
      // 校验文件格式
      const reg = /^.*\.(?:jpg|jpeg|png)$/i;
      if(!reg.test(this.file.name)){
        this.$message.warning('请上传.jpg/.png格式的文件')
        return;
      }
      if(this.file.size > 10 * 1024 * 1024){
        this.$message.warning('文件最大为10M，请检查后重新上传')
        return;
      }
      this.bLoading = true;
      const [err, data] = await uploadFile({
        data: {
          file: this.file,
          waterMarkFlag: this.waterMarkFlag,
          fileTypeName: this.fileTypeName
        }
      })
      this.bLoading = false;
      if(err) return;
      this.$emit('input', data)
      const parentVm = this.$parent.$parent;
      // 清空校验选项
      if(parentVm && parentVm.resetField){
        parentVm.clearValidate();
      }
      return data;
    },
    fnClickDelImg(){
      this.$emit('input', '')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.box-id-card {
  box-sizing: content-box;
  border: 1px solid #E0E6ED;
  border-radius: 5px;
  .action-area{
    transition: all ease-out .2s;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .cover {
    background: rgba(0,0,0,0.7);
    position: absolute;
    transition: all ease-out .2s;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0;
  }
  &:hover{
    border: 1px solid $themeColor;
    .action-area {
      opacity: 1;
    }
    .cover {
      opacity: 1;
      background: rgba(0,0,0,0.5);
    }
  }
  .img-preview{
    max-width: 100%;
    max-height: 100%;
  }
}
</style>

<style>
.fs-22{font-size:22px;}.lh-22{line-height:22px;}.l-0{left:0px;}.r-0{right:0px;}.t-0{top:0px;}.b-0{bottom:0px;}.fs-14{font-size:14px;}.wp-100{width:100%;}.hp-100{height:100%;}.lh-0{line-height:0px;}.w-25{width:25px;}.h-25{height:25px;}</style>
