<!--
 * @Author: 建辉
 * @Date: 2020-08-04 18:06:19
 * @LastEditors: 聂涛
 * @LastEditTime: 2021-07-21 18:16:36
 * @Description: file content
-->
<template>
  <div class="lb-table" ref="oLbTableRef">
    <el-table
      :row-key="rowKey"
      ref="elTable"
      v-bind="oTableProp"
      size="mini"
      :data="data"
      :max-height="maxHeight"
      :default-sort = defaultSort
      :border="border"
      :span-method="merge ? mergeMethod : spanMethod"
      element-loading-text='加载中...'
      element-loading-spinner='el-icon-loading'
      v-on="$listeners">
      <lb-column v-for="(item, index) in aColumnList"
        :key="index"
        :align="align"
        v-bind="oTableProp"
        :column="item">
      </lb-column>
    </el-table>
    <el-pagination
      v-if="pagination"
      class="lb-table-pagination"
      v-bind="oTableProp"
      :style="{ 'margin-top': paginationTop, 'text-align': paginationAlign, 'padding-bottom': '20px', 'padding-right': '30px' }"
      v-on="$listeners"
      @current-change="paginationCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import LbColumn from './lb-column'
import Sortable from 'sortablejs'

export default {
  name: 'lb-table',
  components: {
    LbColumn
  },
  props: {
    maxHeight: {
      type: [String, Number],
      default: 'auto'
    },
    rowKey: {
      type: String,
      default: ''
    },
    column: {
      type: Array,
      default(){
        return []
      }
    },
    data: {
      type: Array,
      default(){
        return []
      }
    },
    border:{ // 是否添加边框
      type: Boolean,
      default(){
        return false
      }
    },
    spanMethod: Function,
    pagination: {
      type: Boolean,
      default: false
    },
    paginationTop: {
      type: String,
      default: '15px'
    },
    paginationAlign: {
      type: String,
      default: 'right'
    },
    align: {
      type: String,
      default: 'center'
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50, 100]
      }
    },
    defaultSort: {
      type: Object,
      default(){
        return {}
      }
    },
    merge: Array
  },
  data () {
    return {
      sortable: null, // 拖拽引用
      newList: [],
      mergeLine: {},
      mergeIndex: {},
      aColumnList: []
    }
  },
  computed: {
    dataLength () {
      return this.data.length
    },
    oTableProp(){
      let oAttrs = {...this.$attrs}
      // https://github.com/ElemeFE/element/issues/3188
      if (!oAttrs.total) oAttrs.total = null

      return {
        layout: 'total,sizes,prev,pager,next,jumper',
        border: false,
        size: 'small',
        'page-sizes': this.pageSizes,
        background: true,
        'min-width': oAttrs.minWidth || '',
        'element-loading-text': '加载中...',
        'element-loading-spinner': 'el-icon-loading',
        ...oAttrs,
      }
    },
  },
  watch: {
    merge () {
      this.getMergeArr(this.data, this.merge)
    },
    dataLength () {
      this.getMergeArr(this.data, this.merge)
    },
    column: {
      handler(val) {
        if (val && val instanceof Array) {
          this.aColumnList = val.map((v) => {
            // TODO fix 属性暂时全部关掉，待优化  https://github.com/ElemeFE/element/issues/4976
            if (v.label === '操作') v.fixed = 'right'
            // if (v.label === '操作') v.fixed = false
            return {
              ...v
            }
          })
        }
      },
      immediate: true
    },
    // data: {
    //   handler(val) {
    //     if (val && val instanceof Array) {
    //       // 防止列表错乱，table重绘操作
    //       this.doLayout()
    //     }
    //   },
    //   immediate: true
    // }
  },
  mounted () {
    this.getMergeArr(this.data, this.merge)
  },
  methods: {
    setSort() {
      this.$nextTick(()=> {
        if(!this.rowKey) {
          throw new ReferenceError('lb-table 调用拖拽方法，请确保指定了 rowKey 属性')
          return;
        }
        this.newList = this.data.slice()
        const el = this.$refs.elTable.$el.querySelectorAll('.el-table__body-wrapper > table > tbody')[0]
        this.sortable = Sortable.create(el, {
          ghostClass: 'sortable-ghost', // Class name for the drop placeholder,
          setData: function(dataTransfer) {
            // to avoid Firefox bug
            // Detail see : https://github.com/RubaXa/Sortable/issues/1012
            dataTransfer.setData('Text', '')
          },
          onEnd: evt => {
            console.log(evt)
            const targetRow = this.newList.splice(evt.oldIndex, 1)[0]
            this.newList.splice(evt.newIndex, 0, targetRow)
            console.log(this.newList.map(item => item.name))
            this.$nextTick(()=>{
              this.$emit('update:data', [...this.newList])
            })
          }
        })
      })
    },
    clearSelection () {
      this.$refs.elTable.clearSelection()
    },
    toggleRowSelection (row, selected) {
      this.$refs.elTable.toggleRowSelection(row, selected)
    },
    toggleAllSelection () {
      this.$refs.elTable.toggleAllSelection()
    },
    toggleRowExpansion (row, expanded) {
      this.$refs.elTable.toggleRowExpansion(row, expanded)
    },
    setCurrentRow (row) {
      this.$refs.elTable.setCurrentRow(row)
    },
    clearSort () {
      this.$refs.elTable.clearSort()
    },
    clearFilter (columnKey) {
      this.$refs.elTable.clearFilter(columnKey)
    },
    doLayout () {
      this.$nextTick(()=>{
        this.$refs.elTable && this.$refs.elTable.doLayout && this.$refs.elTable.doLayout()
      })
    },
    sort (prop, order) {
      this.$refs.elTable.sort(prop, order)
    },
    paginationCurrentChange (val) {
      this.$emit('p-current-change', val)
    },
    getMergeArr (tableData, merge) {
      if (!merge) return
      this.mergeLine = {}
      this.mergeIndex = {}
      merge.forEach((item, k) => {
        tableData.forEach((data, i) => {
          if (i === 0) {
            this.mergeIndex[item] = this.mergeIndex[item] || []
            this.mergeIndex[item].push(1)
            this.mergeLine[item] = 0
          } else if (data[item] === tableData[i - 1][item]) {
              this.mergeIndex[item][this.mergeLine[item]] += 1
              this.mergeIndex[item].push(0)
            } else {
              this.mergeIndex[item].push(1)
              this.mergeLine[item] = i
            }
        })
      })
    },
    mergeMethod ({ row, column, rowIndex, columnIndex }) {
      const index = this.merge.indexOf(column.property)
      if (index > -1) {
        const _row = this.mergeIndex[this.merge[index]][rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    }
  }
}

</script>

<style>
.sortable-ghost{
  opacity: .8;
  color: #fff!important;
  background: #42b983!important;
}
</style>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.icon-star{
  margin-right:2px;
}
.drag-handler{
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.show-d{
  margin-top: 15px;
}

::v-deep .el-pagination__total {
  margin-top: 4px
}
.wrap-border{
  border: 1px solid #E0E6ED;
  border-top: none;
  border-bottom: none;
}
// 修改表格底部线不显示问题
::v-deep .el-table--border{
  border-right: none!important;
  border-bottom: inherit!important;
}
</style>
