<!--
 * @Author: 建辉
 * @Date: 2020-09-24 14:37:08
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-04-28 18:20:27
 * @Description: 上传文件
-->
<template lang="pug">
div
    .my-12.text-center(v-if="fileUrl")
      div
        div.mb-2
          xh-icon.cs-pt(:icon="fileIcon" size="38" @click.native="fnClickOpenFile")
        div
          .fs-12.mb-4.word-break-all {{ getFileName(fileUrl) }}
      div
        xh-icon.cs-pt(icon="Rectangle" size="20" @click.native="fnClickDel")
    .my-12.flex.flex-center(v-else v-loading="loading")
      el-upload(
        drag
        ref="upload"
        action="#"
        :on-change="fnHandleChange"
        :auto-upload="true"
        :show-close="true"
        :show-file-list="true"
        :multiple="true"
        :disabled="loading"
        :accept="acceptStr"
      )
        .w-330.pb-20.m-auto
          i.el-icon-upload
          .el-upload__text
            em 点击上传
        div.el-upload__tip.text-left.w-330.m-auto(slot="tip")
          slot(name="tip")
</template>

<script>
import {mapState} from 'vuex'
import { uploadToOss } from '@/api/oss';
import { downloadUtil, getFileIconName } from '@/utils';

export default {
  name: 'oss-upload-file',
  props: {
    value: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    }
  },
  data() {
    return {
      loading: false,
      fileUrl: this.value,
      acceptMap: {
        audio: 'audio/*',
        video: 'video/*',
        image: 'image/*',
        pdf: 'application/pdf',
        zip: 'application/x-rar-compressed, application/octet-stream,application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
        ppt: 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
        doc: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    }
  },
  computed: {
    acceptStr(){
      if(this.accept){
        const keys = this.accept.split(',');
        return keys.map(item => {

          return this.acceptMap[item]
        }).join(',')
      } else {
        return ''
      }
    },
    ...mapState({
      roles: state => state.permission.roles
    }),
    fileIcon(){
      return getFileIconName(this.fileUrl)
    }
  },
  watch: {
    value(val) {
      this.fileUrl = val;
    },
  },
  methods: {
    getFileName(content){
      const arr = content.split('/');
      const len = arr.length;
      const lastIndex = len -1;
      const name = decodeURIComponent(arr[lastIndex])
      return name;
    },
    fnClickDel() {
      this.fileUrl = ''
      this.$emit('input', '')
    },
    async fnHandleChange(file){
      this.loading = true
      const [err, data] = await uploadToOss(file.raw)
      this.loading = false
      if(err) return
      if(data) {
        console.log(data.url);
        this.$emit('input', data.url)
        this.fileUrl = data.url
      }

    },
    fnClickOpenFile(){
      const msg = this.fileUrl;
      const arr = msg.split('/');
      const fileName = arr[arr.length -1];
      downloadUtil(msg, fileName)
    },
  }
}
</script>

<style>
.my-12{margin-top:12px;margin-bottom:12px;}.mb-2{margin-bottom:2px;}.fs-12{font-size:12px;}.mb-4{margin-bottom:4px;}.w-330{width:330px;}.pb-20{padding-bottom:20px;}</style>
