/**
 * 包裹await
 * @param {Promise} promise 一个异步promise函数
 */
export const awaitWrap = (promise) => {
  try {
    return promise
      .then((data) => [null, data])
      .catch((err) => {
        return [err, null]
      })
  } catch (error) {
    console.log(JSON.stringify(error))
  }
}
