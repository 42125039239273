/*
 * @Author: 建辉
 * @Date: 2020-09-10 17:09:31
 * @LastEditors: 赵伟
 * @LastEditTime: 2022-04-20 14:46:38
 * @Description: 枚举 vuex
 */

import {createAllCoreSysUserInfo, fetchEnumMap, fetchRolesEnum, getQywxSettingListEnum} from "@/api"

export const state = () => ({
  map: {},
  mapKeyword: {
    rgTypeList: [], // 组织类型列表
    accessDataEnumList: [], // 数据权限枚举列表
    netAccessDataEnumListCount: 0,
    roleTypeList: [], // 角色列表
  }
})

export const mutations = {
  UPDATE_MAP(state, {key, data}){
    state.map = {
      ...state.map,
      [key]: data
    }
  }
}

export const actions = {
  // 系统中的枚举集合
  fetchEnum: async ({commit, state}, mapKey) => {
    if(!mapKey) {
      const error = new TypeError('没有传mapKey')
      return;
    }
    if(state.map[mapKey] && state.map[mapKey].length > 0){
      console.log('已经缓存过接口数据,忽略请求')
      return;
    }
    // 其他api获取列表
    const actionMap = {
      'qywxList': getQywxSettingListEnum, // 获取企微列表
      'roleList': fetchRolesEnum,
      'createAllUserList': createAllCoreSysUserInfo // 创建人
    }
    // 如果是获取特殊的列表，用来显示枚举
    if(actionMap[mapKey]){
      const body = actionMap[mapKey].body;
      const [err, data] = await actionMap[mapKey](body);
      if(err) return;
      commit('UPDATE_MAP', {
        key: mapKey,
        data
      })
      return {
        key: mapKey,
        data
      };
    }
    const [err, data] = await fetchEnumMap({
      data: {
        modelName: mapKey
      }
    })
    if(err) return;
    commit('UPDATE_MAP', {
      key: mapKey,
      data
    })
    return {
      key: mapKey,
      data
    }
  },
}
