/*
 * @Author: 建辉
 * @Date: 2021-04-27 15:19:01
 * @LastEditors: 建辉
 * @LastEditTime: 2021-08-11 10:36:16
 * @Description: filters
 */

import Vue from 'vue';
import { numWithCommas } from './num-with-commas';
import dayjs from '@/utils/dayjs';

Vue.filter('FilterMoney', numWithCommas)

// 转发时间戳为YYYY-MM-DD格式
Vue.filter('FilterTimeToDay', function(val) {
  console.log('val', val)
  if (!val) return ''
  return val.split(' ')[0]
});

Vue.filter('FilterBoolean', function(val) {
  return val ? '是' : '否'
});

Vue.filter('timeFromNow', (val) => {
  if(!val) return ''
  const a = dayjs(Number(val))
  const diff = Date.now() - Number(a);
  if(diff > 1000 * 60 * 60 * 24 * 7){
    return a.format('YYYY/MM/DD')
  }
  return a.fromNow()
})
