import { getMessageOpt } from '@/utils/get-message-opt';
import axios from 'axios'
import { Message } from 'element-ui';
import { compile } from 'path-to-regexp'

import { awaitWrap, getDisposition } from '@/utils'
const baseURL = '/api'
const isDev = process.env.FEENV === 'development'
const instance = axios.create({
  baseURL,
  // bMock: false,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})
const isClient = process.client;

instance.interceptors.request.use(config => {
  if(process.server){
    let headerHost = config.reqHeader && config.reqHeader.host;
    if(headerHost){
      // 判断是否是https
      if(headerHost.includes(':443')){
        // https；int-gx.fostars.com:443 => https://int-gx.fostars.com
        headerHost = 'https://'+headerHost.split(':')[0]
      }else if(headerHost.includes(':80')){
        // http；int-gx.fostars.com:443 => https://int-gx.fostars.com
        headerHost = 'https://'+headerHost.split(':')[0]
      }else{
        headerHost = 'https://' + headerHost
      }
    }
    // 组合服务端请求的绝对路径
    let preHttpUrl = headerHost + baseURL
    console.log('preHttpUrl', preHttpUrl)
    // 如果是开发环境
    if(process.env.FEENV === 'development'){
      preHttpUrl = config.bMock ? 'http://yapi.fostars.com/mock/39' : process.env.PROXY + baseURL
    }
    config.baseURL = preHttpUrl
    console.log('server-api-path:', preHttpUrl + config.url )
  }
  try {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8'
    config.headers.channel = 'HMS'
    config.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
  } catch (error) {
    console.log(JSON.stringify(error));
  }
  if (config && config.data && config.data.stream === true) {
    config.responseType = 'blob';
  }
  if (config && config.params && config.params.stream === true) {
    config.responseType = 'blob';
  }
  return config;
}, error => {
  return Promise.reject(error);
});
/**
 * option: {
 *  bNotCommonError: // true：不采用/false：采用，是否采用通用错误处理，默认 false，也就是采用
 * }
 */
instance.interceptors.response.use(
  response => {
    // 注意： 在server端没有客户端api的情况 如果浏览器端要下载
    if(isClient && response.data instanceof Blob){
      return Promise.resolve(response.data)
    }
    if (response.status === 200 ) {
      if (!response.data) response.data = {};

      // 如果是mock，直接返回
      if(response.config.bMock){
        return Promise.resolve(response.data.data)
      }
      const code = response.data.code || response.data.result;
      if(code * 1 === 0 || code * 1 === 200){
        // 如果是分页
        if('count' in response.data){
          const {msg, code, ...rest} = response.data;
          return Promise.resolve(rest)
        }else{
          // 如果是正常请求
          return Promise.resolve(response.data.data);
        }
      } else if(code * 1 === 403){
        messageErr({
          code,
          config: response.config,
          msg: response.data.msg
        })
        if(isClient){
          // 解决el-dialog在cookie失效后的 dom操作
          // 删除el-dialog
          const aDialogList = document.getElementsByClassName('el-dialog');
          for(let i =0; i<aDialogList.length;i ++){
            aDialogList[i].parentNode.remove();
          }
          // 删除黑遮罩
          const childNodes = document.body.childNodes;
          childNodes.forEach((item, i) => {
            if(item.className && (item.className === 'v-modal' || item.className === 'mask'))
              childNodes[i].remove()
          })
          window.$nuxt._router.history.replace({
            path: `/login`
          })
        }
        return Promise.reject(response.data);
        // 50002 表示 需要弹框内容提示的返回码:
      } else {
        messageErr({msg: response.data.msg, config: response.config})
        return Promise.reject(response);
      }
    } else {
      messageErr({
        config: response.config
      })
      return Promise.reject(response);
    }
  },
  error => {
    const status = error.response.status;
    messageErr({code: status, config: error.response.config})
    if(status === 403){
      if(isClient){
        window.$nuxt._router.history.replace({
          path: `/login`
        })
      }
    }
    return Promise.reject(error);
  }
);

/**
 * 客户端的提示
 * @param {Object} opt 参数
 */
function messageErr (opt){
  if(!opt) opt = {}
  if(!opt.config) opt.config = {}
  const errCodeMsgMap = {
    502: '系统正在升级，请稍后重试。',
    403: '登录信息失效',
  }
  const errorMsg = opt.msg || errCodeMsgMap[opt.code] || '服务端错误'
  if(isClient){
    // 如果不需要message，跳过
    if(opt.config.bNotCommonError) return;
    const bHasMessageErr = document.getElementsByClassName('el-message--error');
    if(bHasMessageErr && bHasMessageErr.length){
      console.log('已经有错误提示，忽略后续错误提示')
    }else{
      Message(
        getMessageOpt.getOptions({
          type: 'error',
          message: errorMsg,
          customClass: 'gh-http-tip',
        })
      );
    }
  }
}

function request(method, url, option = {}, fnArgModel = null) {
  const optionData = {
    method,
    url,
    ...option
  }

  const pm = new Promise(function(resolve, reject) {
    // pathToRegExp 用法：https://github.com/pillarjs/path-to-regexp
    if (optionData.regInfo) {
      try {
        const toPath = compile(optionData.url)
        optionData.url = toPath(optionData.regInfo)
      } catch (e) {}
    }
    if (!optionData.params) {
      optionData.params = {}
    }

    process.debug && console.log('api/util[option]: ', optionData)

    const startTime = new Date()
    // promise
    instance
      .request(optionData)
      .then(res => {
        process.server && logTime(false, startTime, JSON.stringify(res))
        resolve(res);
      })
      .catch((oError) => {
        reject(oError)
        process.server &&
          logTime(
            true,
            startTime,
            JSON.stringify(optionData) + ' ' + oError.message
          )
      })
  })
  return awaitWrap(pm)
}

function logTime(err = false, startTime, ext) {
  const nowTime = new Date()
  console[err ? 'error' : 'log'](`接口请求 ${nowTime - startTime}ms ${ext}`)
}

export { request, instance }
