/*
 * @Author: 建辉
 * @Date: 2021-08-09 11:57:04
 * @LastEditors: 建辉
 * @LastEditTime: 2021-08-09 11:57:18
 * @Description: event-bus
 */

import Vue from 'vue'
export const EventBus = new Vue()
