<!--
 * @Author: wangyaqiang
 * @Date: 2022-08-18 19:19:30
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-15 15:02:04
 * @Description: 包装 h-ui 组件库中 enumpick 组件
-->
<script>
import {
  fetchLoginUserList,
  fetchCustServiceUsers,
  getActivityChannelModelList,
  getQywxSettingListEnum,
  fetchRolesEnum,
  getEnumUserList,
  getKeFuList,
  getMsgOptionEnum,
  getDeviceOptionEnum,
  getSysUserByUserTypeEnum,
  getOperationTaskTypeList,
  getScrmList,
  businessOpportGetBusProductConfig,
  businessOpportGetBusStatusEnum,
  businessOpportAllBusUser,
  businessOpportAllBusUserWithOther,
  businessOpportBusUser,
  getWxPlatformList,
  followUserList,
  getOrderStatusList,
  fetchLoginUserListByGroup,
  getWechatContentType,
  dingdingEventList,
  dingdingEventStatusList,
  dingdingContactStatusList,
  dingdingPositionList,
  groupConvertList,
  groupFollowUser,
  dingdingFilestatusenumList,
  dingdingLinkstatusenumList,
  materialRepoList,
  createAllCoreSysUserInfo,
  fetchCommonEnum,
  subChannelList,
  getUserListDeptId,
  getCoreUserAllList,
  getUserType,
  sensitivityLevelEnumList,
  activityFlowList,
  activityRuleList,
  getShangjiStatus,
  getProductList,
  getMsgTemplateList,
  getSmsList,
  getGroupMsgTpl,
  rateValueList,
  businessLifeCycleBusProTypeSelect,
  businessLifeCycleAllMonStatus,
  groupListEm
} from "@/api";
const mapKeyList = {
  'allUserList': getEnumUserList, // 所有小助手
  'addUserList': fetchLoginUserList, // 添加人列表
  'staffActivityModelList': getActivityChannelModelList, // 获取
  'addUserListByGroup':fetchLoginUserListByGroup,
  'qywxList': getQywxSettingListEnum, // 获取企微列表
  'roleList': fetchRolesEnum,
  'keFuList': getKeFuList,
  'chatKeFuList':getMsgOptionEnum,
  'createAllUserList': createAllCoreSysUserInfo, // 创建人
  'emulatorUserList': getDeviceOptionEnum, // 模拟器小助手
  'getSysUserByUserType':getSysUserByUserTypeEnum, // 客服
  'getTaskType':getOperationTaskTypeList, // 任务类型
  'getWxPlatform':getWxPlatformList, // 任务类型
  'scrmList': getScrmList, // 获取群
  'businessProductList': businessOpportGetBusProductConfig, // 获取商机产品信息
  'businessEnumList': businessOpportGetBusStatusEnum,
  'businessUserList': businessOpportBusUser,
  'businessAllUserList': businessOpportAllBusUser,
  'businessAllUserOtherList': businessOpportAllBusUserWithOther,
  'followUser': followUserList,
  'getOrderStatus': getOrderStatusList,
  'wechatContentType': getWechatContentType,
  'custServiceUsersList':fetchCustServiceUsers,
  'convertList': groupConvertList,
  'groupUsers': groupFollowUser,
  'sensitivityLevelEnum': sensitivityLevelEnumList,
  'materialRPList': materialRepoList, // 群标签
  dingdingEventList,
  dingdingEventStatusList,
  dingdingPositionList,
  dingdingLinkstatusenumList,
  dingdingFilestatusenumList,
  dingdingContactStatusList,
  subChannelList,
  getUserListDeptId,
  getCoreUserAllList,
  getUserType,
  activityFlowList,
  activityRuleList,
  getShangjiStatus,
  getProductList,
  getMsgTemplateList,
  getSmsList,
  getGroupMsgTpl,
  rateValueList,
  businessLifeCycleBusProTypeSelect,
  businessLifeCycleAllMonStatus,
  groupListEm
}

const checkbox = {
  boolean: [{ value: true, label: '是' }, { value: false, label: '否' }]
}

export default {
  name: 'h-enum',
  methods: {
    // 请求数据
    async fnRequest (res) {
      // options 是为了请求回来的数据不是 { label: 'xxx', value: 'xxx' } 格式的数据，进行的映射处理
      let { options = {} } = this.$attrs;
      if (!options) options = { label: 'desc', value: 'code' };
      try {
        const params = { typeName: res };
        if (checkbox[res]) {
          return await checkbox[res];
        }
        let action = mapKeyList[res];
        action = action || fetchCommonEnum;
        console.log(action)
        const [err, data] = await action({params})
        if (err) return;

        // if (!options) return data;

        let option = [];

        if (options.label && options.label.includes('~')) option = options.label.split('~');

        return data.filter((item) => {
          let label = item[options.label] || '';
          if (option && option.length) {
            option.forEach((subItem) => {
              label += item[subItem] + '-';
            })
            label = label.substring(0, label.length - 1);
          }
          item.label = label;
          item.value = item[options.value]
          return item;
        });
      } catch (error) {
        console.log(error, 'error:===');
      }
    },
    // change 事件
    fnChange(value) {
      this.$emit('change', value);
    },
    fnInput(value) {
      this.$emit('input', value);
    },
  }
}
</script>


<template lang="pug">
h-enumPick(
  v-bind="$attrs"
  :request="fnRequest"
  @input="fnInput"
  @change="fnChange"
)
</template>

<style>
</style>
