/*
 * @Author: 建辉
 * @Date: 2021-05-10 11:52:57
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-11-26 18:55:55
 * @Description: auth模块
 */


import { request } from './http'

// 登录
export const login = options => {
  return request('post', '/hms/auth/login', options)
}

// 退出登录
export const logOut = options => {
  return request('post', '/hms/auth/logout', options)
}

export const getUserInfo = options => {
  return request('post', '/hms/auth/userInfo', options)
}

// 获取菜单，权限btn api
export const getUserPermission = options => {
  return request('post', '/hms/auth/userPermission', options)
}
