/*
 * @Author: 聂涛
 * @Date: 2022-09-07 19:36:11
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-01-08 11:15:58
 * @Description: 自定义组建懒加载
 */
import Vue from 'vue';
import '@fostars/h-ui/lib/styles/reset-ui.css'
import hTable from '@fostars/h-ui/lib/table'
import hDialog from '@fostars/h-ui/lib/dialog'
import hEditor from '@fostars/h-ui/lib/editor'
import hWeixinEditor from '@fostars/h-ui/lib/weixin-editor'
import hIcon from '@fostars/h-ui/lib/icon'
import hPlayer from '@fostars/h-ui/lib/player'
import hPopAction from '@fostars/h-ui/lib/pop-action'
import hTags from '@fostars/h-ui/lib/tags'
import hText from '@fostars/h-ui/lib/text'
import hViewer from '@fostars/h-ui/lib/viewer'
import hEnumPick from '@fostars/h-ui/lib/enumPick'
import hSection from '@fostars/h-ui/lib/section'
import hLayout from '@fostars/h-ui/lib/layout'
import hDrawer from '@fostars/h-ui/lib/drawer'
import hInput from '@fostars/h-ui/lib/input'
import hCodeView from '@fostars/h-ui/lib/code-view'

import hForm from '@fostars/h-ui/lib/form'
import hFormItem from '@fostars/h-ui/lib/form-item'
import hDatePicker from '@fostars/h-ui/lib/date-picker'
import hImgCard from '@fostars/h-ui/lib/img-card'
import hEmpty from '@fostars/h-ui/lib/empty'
import hCard from '@fostars/h-ui/lib/card'
import hChart from '@fostars/h-ui/lib/chart'
import hSpace from '@fostars/h-ui/lib/space'


// - hFileUploader
const components = [
  hEditor, hIcon,
  hTable, hEnumPick, 
  hPlayer, hPopAction, hTags,
  hText, hViewer, hWeixinEditor,
  hForm, hFormItem, hDatePicker,
  hImgCard, hInput, hSection, hCodeView, hDatePicker,
  hLayout, hDrawer, hInput, hDialog,
  hEmpty, hCard, hSpace, hChart,
]

components.forEach(component => {
  Vue.use(component)
})
