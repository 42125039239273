<!--
 * @Author: 建辉
 * @Date: 2020-09-24 15:52:09
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-11-07 15:40:42
 * @Description: file content
-->
<template lang="pug">
.h-60.py-5.bs-bd.bg-white.pst-fix.b-60.gh-b-t.r-10.box.br-2(:style="{left:left + 'px', zIndex: 99}" style="border-top: 1px solid #eeeeee; width: calc(100vw - 250px)")
  slot
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('app', {
      left: state => (state.sidebar.nSidebarWidth + 10)
    })
  }
};
</script>


<style lang="scss" scoped>
.box {
  /* 添加缓冲动画，跟sidebar的效果一致 */
  transition: all ease .28s;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}
</style>

<style>
.h-60{height:60px;}.py-5{padding-top:5px;padding-bottom:5px;}.b-60{bottom:60px;}.r-10{right:10px;}.br-2{border-radius:2px}</style>
