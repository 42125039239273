<template lang="pug">
span.fs-14 {{ renderText }}
</template>

<script>
// 一般枚举选择使用enum-pick，如果通过key渲染label，需要使用这个组件
import {mapState} from 'vuex';
import _ from 'lodash'
export default {
  name: 'enum-text',
  props: {
    enumKey: {
      type: String,
      required: true
    },
    // 选择对应的值
    value: {
      type: [String, Number, Array],
      default:''
    },
    exclude: {
      type: Array,
      default(){
        return []
      }
    },
    force: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      // map: state => state.enum.map,
      aList(state){
        return state.enum.map[this.enumKey] || []
      },
      map(state){
        return state.enum.map
      }
    }),
    renderText(){
      if(_.isArray(this.value)){
        let result = [];
        result = this.value.map(item => {
          const findItem = this.aList.find(listItem => {
            return item === listItem.code
          })
          if(findItem && findItem.desc){
            return findItem.desc;
          }
          return false;
        })
        if(result.length > 0){
          return result.filter(item => item).join(', ');
        }else {
          return '-'
        }
      } else {
        const item = this.aList.find(item => item.code === this.value)
        return item && item.desc || '-'
      }
    }
  },
  mounted(){
    this.fnNetGetList()
  },
  methods: {
    fnNetGetList(){
      this.$store.dispatch('enum/fetchEnum', this.enumKey)
    }
  }
}
</script>

<style>
.fs-14{font-size:14px;}</style>
