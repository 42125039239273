<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:03:43
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-08-29 15:42:40
 * @Description: Description
-->
<template lang="pug">
div(style="min-height:236px;")
  upload(ref="editorFile" width="152" height="152" accept="pdf,zip,ppt,doc,xls" type="SingleFile" v-model="defaultItemURL")
</template>
<script>
export default {
  name: 'h-file',
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    defaultItem: {
      file: '',
    },
    defaultItemURL: '',
  }),
  created() {},
  mounted() {},
  methods: {
    fnSetEditor(val) {
      if (val.materialType === 'file') {
        this.defaultItemURL = val.content
      }
    },
    // 获取输入的内容
    fnGetEditor() {
      return this.defaultItemURL || '';
    },
    // 清除输入的内容
    fnClearEditor() {
      // if (this.$refs.editorFile) this.$refs.editorFile.fnClickDel();
      this.defaultItemURL = '';
      // console.log('defaultItem:---', this.$refs.editorFile, this.defaultItem, this.$options.data().defaultItem)
    }
  }
}
</script>
<style scoped lang='scss'>
</style>
<style>
</style>
