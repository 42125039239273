<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-12 18:55:07
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-04-25 17:19:14
 * @Description: 展示消息体

 // 两种类型 -
    新建/编辑 数据正常 map 数据
    预览  后端传回的数据是json 格式需要转换
-->
<template lang="pug">
div
  xh-btn(type="text" @click="onView") {{title}}
  el-drawer(
    title="预览"
    :visible.sync="drawer"
    direction="rtl"
    @beforeClose="onClose"
  )
    div.br-7.p-10.br-6.m-auto(
      :class=" isCard ? 'phone-bg' : 'line-bg'"
    )
      div.ovfl-y-auto(v-if="HandleMsgContent && HandleMsgContent.length"
        style="height: 100%"
      )
        .flex.flex-between(
          v-for="(item, index) in HandleMsgContent"
          :key="item.msgType + index"
          :class="index < HandleMsgContent.length - 1 ? 'mb-20' : ''")
          .flex
            span {{ index + 1 }}、
            .flex.cs-pt(v-if="item.msgType === 'H5'")
              .flex.flex-column.p-15.br-6.bg-white
                .w-220.fs-16.fw-bold.lh-20.mb-8.text-ellipsis {{ item.linkTitle || (item.content && item.content.linkTitle) || (item.content && item.content.title) }}
                .flex
                  .flex-1.w-150.mt-4.fs-13.lh-15.text-ellipsis-3(style="color:#d0c9c8;") {{ item.linkDesc || (item.content && item.content.linkDesc) || (item.content && item.content.description) }}
                  img.ml-10.h-50.w-50(:src="item.linkIconUrl || (item.content && item.content.linkIconUrl) || (item.content && item.content.imageUrl)")
            .flex.p-15(style="background:white;border-radius: 10px" v-else-if="item.msgType === 'wxTemplate'")
              .flex.flex-column.w-300.lh-20
                .flex.fw-bold.fs-16.lh-30 {{ item.content.title }}
                .flex(style="color:#888888") {{ item.content.date }}
                .flex.my-10(:style="{ color: (item.content && item.content.first &&  item.content.first.color) || ''} ") {{ item.content.first && item.content.first.value }}
                div(v-for="(it, key) in item.content" :key="it.key")
                  div(v-if="!['remark','first','title','date'].includes(key)" :style=" { color: it.color } ") {{ it.key }} {{ it.value}}
                .my-10(:style=" { color: (item.content && item.content.remark && item.content.remark.color) || ''}") {{ item.content.remark && item.content.remark.value }}
            .cs-pt.bg-white.p-10.br-5(v-else-if="item.msgType === 'img'")
              .flex.flex-wrap(v-if="Array.isArray(item.content)" style="width: 500px;")
                Draggable(
                  :list="item.content"
                  class="list-group"
                  ghost-class="ghost"
                  :move="checkMove"
                  @start="dragging = true"
                  @end="dragging = false"
                )
                  .w-160.h-160.dspl-inbl.pst-rlt.ovfl-hd.mr-5.mb-5.br-5(
                    style="border: 1px solid #ccc;"
                    v-for="(subItem, index) in item.content"
                    :key="index"
                  )
                    img.wp-100.vh(
                      :src="subItem"
                    )
                    xh-icon.pst-absl.r-5.b-5(icon="shanchu2" size="22" style='color: red' @click.native="fnDeleteImg(item, index)")
              img.bg-white.w-160.br-6.vtal-md(v-else style="border: 1px solid #cfcfcf;" :src="item.content")
            .cs-pt.w-300.bg-white.p-15.br-5(
              v-else-if="item.msgType && item.msgType.includes('sph')"
              style='overflow: hidden'
            )
              .flex.flex-row
                img.h-30.w-30.br-15(:src="item.coverUrl")
                .fs-14.ml-10.lh-30 {{ item.nickname }}
              .flex.mt-5.text-left
                .fs-14.mt-2.fw-bold.lh-14 {{item.desc}}
              img.mt-10.h-135.w-240(style="object-fit: cover;" :src="item.ossUrl")
              .lh-20.pt-5.fs-14.border-top 视频号
            .cs-pt(v-else-if="item.msgType === 'file'")
              .flex.flex-middle.p-15.br-6.bg-white
                .w-160.fs-14.fw-bold.lh-20.mb-8.text-ellipsis-3 {{ fnGetFileInfo(item) }}
                icon.ml-10(:icon="fnGetFileInfo(item, 'icon')" :size="58" :bPoint="true")
            .cs-pt(v-else-if="item.msgType === 'mp3'")
              .flex.flex-middle.p-15.br-6.bg-white
                audio(:src="item.content" controls)
            .bg-white.br-6.cs-pt(v-else-if="item.msgType === 'mp4'")
              video.w-300.h-300.vtal-md(muted="muted" class="video" :src="item.content" type="video/mp4" poster="false.png" autoplay="autoplay" controls="controls" loop="-1")
                <p>你的浏览器不支持video标签.</p>
            .bg-white.p-10.br-6.lh-15.cs-pt.ovfl-hd(v-else-if="item.msgType === 'txt'" style="max-width:1510px;" v-html="fnHandleEmojiContent(item.content)")
            .bg-white.p-10.br-6.lh-15.cs-pt.color-red(v-else style="max-width:1510px;") {{ fnHandleEmojiContent(item.content) || '未知类型' }}
          .flex.flex-middle(v-if="sort")
            icon.mx-2(v-show="index !== 0" size="18" icon="shangyi" @click="fnHandleItem(index, 'up')")
            icon.mx-2(v-show="index !== HandleMsgContent.length - 1" size="18" icon="shangyi-copy" @click="fnHandleItem(index, 'down')")
            icon.mx-2(icon="shanchu1" size="18" color="red" @click="fnDelItem(index)") 删除
            icon.mx-2(size="18" icon="bianji" v-if="edit" @click="fnClickEdit(item, index)")
</template>
<script>
import { getFileIconName, getFileName, emojiChange } from '@/utils';
import MaterialDialog from "@/components-pages/content-manager/material-dialog/index.vue"
import Draggable from "vuedraggable";
import _ from 'lodash'

export default {
  name: 'msg-view',
  components: {
    MaterialDialog,
    Draggable
  },
  props: {
    msgContent: {
      type: Array,
      default: () => {
        return [];
      },
    },
    maxHeight: {
      type: String,
      default: '535px'
    },

    // - 显示增加素材
    addMaterial: {
      type: Boolean,
      default: false
    },
    sort: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '模板预览'
    },
    edit: {
      type: Boolean,
      default: false
    },
    // 朋友圈，普通消息模式
    isCard: {
      type: Boolean,
      default: true
    },
    // 编辑时 关闭tab
    editCloseTab: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMaterialDialog: false,
      dragging: true,
      drawer: false,
    }
  },
  computed: {
    HandleMsgContent() {
      const msgContent = this.msgContent || [];
      return msgContent.filter((item) => {
        if ((item.msgType === 'H5' || item.msgType === 'wxTemplate') && typeof item.content === 'string') {
          item.content = JSON.parse(item.content);
        } else if (item.msgType === 'H5' && typeof item.content === 'object') {
          item = { ...item, ...item.content }
        }
        console.log(item, 'item')
        return item;
      })
    },
    // 回显输入的表情内容
    fnHandleEmojiContent() {
      return ((content) => {
        return emojiChange(content)
      })
    }
  },
  created() {
    /[\u4E00-\u9FA5]/.exec('啊实打实打算[桑葚]啊实打实打算', function(str, $1, $2){
      console.log(str, $1, $2)
    } )
  },
  mounted() {},
  methods: {
    onView(){
      this.drawer = true
    },
    onClose() {
      this.drawer = false
    },
    checkMove(e) {
      // console.log(e)
    },
    fnClickEdit(item, index) {
      this.fnDelItem(index)
      this.$emit('edit', _.cloneDeep(item) )
      if (this.editCloseTab) this.drawer = false
    },
    fnDeleteImg(item, idx) {
      console.log(item);
      console.log(idx);
      this.$confirm({
        title: "确认删除",
        msg: "您是否要删除当前图片？",
        beforeClose: (instance, done) => {
          item.content.splice(idx, 1)
          done()
        }
      })
    },
    fnGetFileInfo({ content = '' }, source) {
      // const fileName = content.replace(/(.*\/)*([^.]+).*/ig, "$2") || '未知'
      // // 匹配 . 之前除换行符以外的所有字符替换为""
      if (source === 'icon') {
        return getFileIconName(content)
      } else {
        return getFileName(content);
      }
    },
    // 删除列
    fnDelItem(index) {
      this.msgContent.splice(index, 1);
      this.$emit('update:msgContent', this.msgContent)
    },
    // 数据 顺序变更
    fnHandleItem(index, sort) {
      let newIndex = index;
      if (sort === 'up') newIndex -= 1;
      if (sort === 'down') newIndex += 1;
      // 调换位置
      this.msgContent.splice(newIndex, 1, ...this.msgContent.splice(index, 1, this.msgContent[newIndex]))
    },
  }
}
</script>
<style scoped lang='scss'>

.phone-bg {
  background: url('https://static.fostars.com/h5/20230628103715661.png');
  background-size: 100% 100%;
  width: 438px;
  height: 856px;
  white-space: pre-line;
  padding: 160px 30px 160px 30px;
}

.line-bg {
  background: #ebebeb;white-space: pre-line; min-width: 300px;
}
</style>

<style>
.br-7{border-radius:7px}.p-10{padding:10px;}.br-6{border-radius:6px}.p-15{padding:15px;}.w-220{width:220px;}.fs-16{font-size:16px;}.lh-20{line-height:20px;}.mb-8{margin-bottom:8px;}.w-150{width:150px;}.mt-4{margin-top:4px;}.fs-13{font-size:13px;}.lh-15{line-height:15px;}.ml-10{margin-left:10px;}.h-50{height:50px;}.w-50{width:50px;}.w-300{width:300px;}.lh-30{line-height:30px;}.my-10{margin-top:10px;margin-bottom:10px;}.br-5{border-radius:5px}.w-160{width:160px;}.h-160{height:160px;}.mr-5{margin-right:5px;}.mb-5{margin-bottom:5px;}.wp-100{width:100%;}.r-5{right:5px;}.b-5{bottom:5px;}.h-30{height:30px;}.w-30{width:30px;}.br-15{border-radius:15px}.fs-14{font-size:14px;}.mt-5{margin-top:5px;}.mt-2{margin-top:2px;}.lh-14{line-height:14px;}.mt-10{margin-top:10px;}.h-135{height:135px;}.w-240{width:240px;}.pt-5{padding-top:5px;}.h-300{height:300px;}.mx-2{margin-left:2px;margin-right:2px;}</style>
