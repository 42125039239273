<!--
 * @Author: 聂涛
 * @Date: 2021-05-13 11:45:15
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-07 18:22:35
 * @Description: 录音
-->
<template lang="pug">
el-dialog(
  :visible="show"
  width="600px"
  :append-to-body="true"
  :showClose="true"
  :title="isPhone ? '' : '录音'"
  center
  :before-close="recClose"
  :close-on-click-modal="false"
)
  div(v-if="!isPhone")
    .flex
      xh-btn(@click="start" icon="luyin" :loading="loading" :disabled="loading") 开始录音
      xh-btn.ml-30(@click="recStop" icon="mic-off" :disabled="!loading") 录音结束
      .lh-32.ml-15(v-if="seconds") 已录音时长:{{seconds}}秒
    .mt-10
      .my-10 录音试听
      audio(v-if="content"  :src="content" controls)
    .text-center.mt-20
      xh-btn.mx-10(@click="recClose" icon="Rectangle") 关闭
      xh-btn.mx-10(v-if="!download" @click="recSend" icon="xuanze" :disabled="content === null"  type="primary") 发送
      xh-btn.mx-10(v-else @click="recDownload" icon="daoru" :disabled="content === null"  type="primary") 下载
  div(v-else)
    .text-center.fs-40 录音
    .flex.flex-around.mt-40
      .btn-record( @click="start" v-if="!loading" :class=" content ? 'btn-orange' : '' ") {{ content ? '重新录音' : '开始录音'}}
      .btn-record(style="background: tomato" @click="recStop" v-else)
        div 录音结束
    .text-center(v-if="loading")
      img(style="width: 400px;" src="https://static.fostars.com/h5/record.gif")
      .text-center.fs-35(v-if="seconds") 已录音{{seconds}}秒
    .mt-10(v-if="content")
      .my-30.fs-35 录音试听
      audio( :src="content" controls)
      .fs-35(v-if="recording") 录音生成中...请稍等
    .mt-40.flex.flex-center
      .mx-30.btn.fs-35(v-if="content" @click="recSend" icon="xuanze"  type="primary") 发送

</template>

<script>
import { Recorder } from '@/utils/record'
import { uploadToOss } from '@/api/oss'

export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    download: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      seconds: 0,
      secondsInterval: null,
      newRec: new Recorder(),
      loading: false,
      content: null,
      recBlob: null,
      recording: false,
      isPhone: /Mobi|Android|iPhone/i.test(navigator.userAgent)
    }
  },
  mounted() {
    this.newRec.init()
  },
  methods: {
    start() {
      this.loading = true
      this.content = null
      this.recBlob = null
      this.recording = false
      this.newRec.recStart()
      this.secondsInterval = setInterval(() => {
        this.seconds += 1
        if (this.seconds >= 55) {
          this.recStop()
          this.$message.success('录音已达最长时间，已自动保存')
        }
      }, 1000);
    },
    recClose() {
      this.recStop()
      this.$emit('update:show', false)
      this.recording = false
      this.content = false
    },
    recStop() {
      this.loading = false
      this.recording = true
      this.seconds = 0
      this.recording = true
      clearInterval(this.secondsInterval)
      this.secondsInterval = null
      this.newRec.recStop(([err, data]) => {
        if (err) {
          this.$message.error(err)
          this.recording = false
          return
        }
        if (data) {
          this.content = data.content
          this.recBlob = data.blob
          this.recording = false
        }
      })
    },
    async recSend() {
      if (!this.recBlob) return
      const file = new File([this.recBlob], '录音.mp3', { type: 'audio/mp3' })
      const [err, res] = await uploadToOss(file)
      if(err) return;
      console.log(res.url);
      this.$emit('blob', res.url);
      this.recBlob = null
      this.content = null
    },
    recDownload() {
      const downfile = new File([this.recBlob], '录音.mp3', { type: 'audio/mp3' })
      const tmpLink = document.createElement("a");
      const objectUrl = URL.createObjectURL(downfile);

      tmpLink.href = objectUrl;
      tmpLink.download = downfile.name;
      document.body.appendChild(tmpLink);
      tmpLink.click();

      document.body.removeChild(tmpLink);
      URL.revokeObjectURL(objectUrl);
    }
  }
}
</script>


<style lang="scss" scoped>

.btn-record {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #208dfd;
  font-size: 30px;
  color: white;
}

.btn {
  width: 200px;
  height: 70px;
  background: #208dfd;
  text-align: center;
  color: white;
  font-size: 34px;
  display: flex;
  align-items: center;
  font-weight: bolder;
  justify-content: center;
}

.btn-orange {
  background: orange;
}

::v-deep .el-dialog__close {
  font-size: 40px;
}

</style>

<style>
.ml-30{margin-left:30px;}.lh-32{line-height:32px;}.ml-15{margin-left:15px;}.mt-10{margin-top:10px;}.my-10{margin-top:10px;margin-bottom:10px;}.mt-20{margin-top:20px;}.mx-10{margin-left:10px;margin-right:10px;}.fs-40{font-size:40px;}.mt-40{margin-top:40px;}.fs-35{font-size:35px;}.my-30{margin-top:30px;margin-bottom:30px;}.mx-30{margin-left:30px;margin-right:30px;}</style>
