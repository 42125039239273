<!--
 * @Author: 建辉
 * @Date: 2020-09-29 10:13:07
 * @LastEditors: 赵伟
 * @LastEditTime: 2022-04-20 14:48:57
 * @Description: enum-pick 枚举选择
-->
<template lang="pug">
div.pst-rlt.wp-100
  el-select.wp-100(
    :clearable="clearable"
    v-bind="$attrs"
    :popper-append-to-body="appendToBody"
    :value="val"
    :multiple="multiple"
    filterable
    @change="fnHandleInput" v-if="type === 'select'")
    el-option(value="" label="全部" v-if="showAll") 全部
    el-option(
      v-for="(item, i) in aList"
      :key="i"
      :label="item.desc"
      :value="item.code"
      v-if="!exclude.includes(item.code)"
    )
      .flex.flex-middle
        img.h-30(v-if="item.avatar" :src="item.avatar")
        emoji-text(:style="{marginLeft: item.avatar ? '5px' : ''}" :text="item.desc")
  el-radio-group(
    v-bind="$attrs"
    :value="val" @input="fnHandleInput" v-if="type === 'radio'")
    el-radio(label="" v-if="showAll") 全部
    el-radio(v-for="(item, i) in aList" :key="i" :label="item.code") {{ item.desc }}
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex';
import EmojiText from "@/components/emoji-text/index.vue";
export default {
  name: 'enum-pick',
  components: {EmojiText},
  inheritAttrs: false,
  // multiple 多选
  props: {
  // 接口 enumtype
  // addUserList 添加人列表
    enumKey: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Array],
      default:''
    },
    // 渲染类型
    type: {
      type: String,
      default: 'select' // select radio
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    // 排除的选项
    exclude: {
      type: Array,
      default(){
        return []
      }
    },
    // 是否显示全部
    showAll: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    force: { // 是否强制刷新
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      val: ''
    }
  },
  computed: {
    ...mapState({
      aList(state){
        return state.enum.map[this.enumKey] || []
      },
      map(state){
        return state.enum.map
      }
    }),
  },
  watch: {
    value: {
      handler(val){
        if(_.isArray(val) && val.length === 0){
          this.val = []
          this.fnBsnDealModel([])
        }else{
          this.val = val || ''
          this.fnBsnDealModel(this.val)
        }
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetList()
  },
  methods: {
  // tips： change事件会优先于input事件触发
    fnHandleInput(val){
      console.log(val)
      if(_.isArray(val) && val.length === 0){
        this.$emit('change', []);
        this.$emit('input', []);
        this.fnBsnDealModel([])
        return;
      }
      // 数组，最后一次点击是全部，显示全部
      if(_.isArray(val) && val[val.length-1] === '' && val.length > 1){
        this.$emit('change', [])
        this.$emit('input', [])
        this.fnBsnDealModel([])
      // 数组，最后一次点击不是全部，删除全部
      } else if(_.isArray(val) && val[val.length-1] !== '' && val.length > 1){
        const inputList = val.filter(item => item !== '')
        this.$emit('change', inputList)
        this.$emit('input', inputList)
        this.fnBsnDealModel(inputList)
      } else{
        this.$emit('change', val)
        this.$emit('input', val)
        const descItem = this.aList.find(item => item.code === val)
        this.$emit('update:desc', descItem && descItem.desc)
        this.fnBsnDealModel(val)
      }
    },
    fnNetGetList(){
      this.$store.dispatch('enum/fetchEnum', this.enumKey)
    },
    // 返回第一级别的model
    fnBsnDealModel(val){
    // 数组
      if(_.isArray(val)){
        const aTargetModels = [];
        this.aList.forEach(item => {
          val.forEach(code => {
            if(item.code === code) {
              aTargetModels.push(item)
            }
          })
        });
        this.$emit('InputModel', aTargetModels);
        this.$emit('update:model', aTargetModels);
      }else{
        const oTargetItem = this.aList.find(item => item.code === val);
        this.$emit('InputModel', oTargetItem);
        this.$emit('update:model', oTargetItem);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 神坑，因为是fixed，但是没有计算好高度，所以直接使用absolute来处理 */
/* 参考位置： https://blog.csdn.net/Hello_yihao/article/details/104557895 */
::v-deep .el-select-dropdown {
    position: absolute!important;
    top: 30px!important;
    left: 0px!important;
}
</style>

<style>
.wp-100{width:100%;}.h-30{height:30px;}</style>
