// import { whiteRoutes } from "@/utils";

// 获取用户菜单权限，按钮权限
export default function(context) {
  const {
    isHMR,
    app,
    store,
    req,
    route,
    params,
    error,
    redirect
  } = context;
  // if(whiteRoutes.includes(route.path)) return;
  // if(!context.bsnData) context.bsnData = {}
  // if(!context.bsnData.userInfo){
  //   console.log('没有登录，忽略')
  //   return;
  // }
  // const [err, data] = await store.dispatch('user/fetchPermission')
  // console.log(err, data)
}
