<!--
 * @Author: 建辉
 * @Date: 2020-09-24 14:37:08
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-01-09 18:26:11
 * @Description: 上传文件
-->
<template lang="pug">
el-dialog(
    :visible="show"
    :width="width"
    :before-close="fnHandleClose"
    :append-to-body="true"
  )
    .fs-20.fw-bold.text-center(style="color: #202E3E;" slot="title") {{ title }}
    .fs-16(style="line-height: 1.5em;")
      slot
      //- div 只更新新增的职工信息，忽略数据库中已有的职工信息。请点此下载
        el-button(type="text") 模板文件
        span ，按要求填写完毕后，上传文件，即可完成批量加入
    .my-12.flex.flex-center(v-loading="loading")
      el-upload(
        drag
        ref="upload"
        action="#"
        :on-change="fnHandleChange"
        :before-upload="fnHandleBeforeUpload"
        :auto-upload="false"
        :show-close="false"
        :show-file-list="false"
        :accept="acceptVal"
        :multiple="false"
      )
        .w-330.pb-20.m-auto
          i.el-icon-upload
          .el-upload__text
            //- span 将文件拖到此处，或
            em 点击上传
        div.el-upload__tip.text-left.w-330.m-auto(slot="tip")
          slot(name="tip")
          //.flex.flex-between.w-330.m-auto
          //el-button.w-150.h-40(@click="fnHandleClose") 取消
          //el-button.w-150.h-40(type="primary" @click="fnClickUpload") 上传
</template>

<script>
import {mapState} from 'vuex'
// import { uploadCompanyFile, uploadGhFile} from '@/api'
import { uploadKnowledgeFile, uploadFile2url, uploadKnowledgePrivateImport } from '@/api';

export default {
  name: 'upload-file',
  props: {
    accept: {
      type: String,
      default: 'img'
    },
    uploadUrl: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    onlyFile: {
      type: Boolean,
      default: false,
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '400px'
    },
    repoCode: {
      type: String,
      default: ''
    },
    private: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    acceptVal(){
      // 常用MIME类型 https://www.w3cschool.cn/htmltags/att-input-accept.html
      const acceptMap = {
        img: 'image/*',
        xsl: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pdf: 'application/pdf',
        audio: 'audio/*',
        video: 'video/*',
        zip: 'aplication/zip',
      }
      return acceptMap[this.accept]
    },
    ...mapState({
      roles: state => state.permission.roles
    })
  },
  methods: {
    fnHandleClose(){
      this.$emit('showChange', false)
    },
    fnHandleChange(file){
      console.log(file)
      const oRegMap = {
        xsl: /^.*\.(?:xls|xlsx)$/i
      }
      if(!oRegMap[this.accept].test(file.name)){
        this.$message.warning('文件不符合格式，请检查后重新上传')
        return;
      }
      if(this.onlyFile){
        this.$emit('input', file.raw)
      }
      if (this.autoClose) {
        this.fnClickUpload()
      }
    },
    fnHandleBeforeUpload(file){
      console.log(file)
    },
    fnClickUpload(){
      console.log(this.$refs.upload)
      const fileList = this.$refs.upload.$refs['upload-inner'].fileList;
      console.log(fileList)
      const rawFiles = fileList.map(item => item.raw);
      if (!rawFiles.length) {
        this.$message.error('请上传文件');
        return
      }
      // TODO: 上传多个文件
      this.fnHandleHttpRequest(rawFiles[0])
    },
    async fnHandleHttpRequest(file){
      // 如果只需要一个文件流
      if(this.onlyFile){
        this.$emit('input', file)
        if (this.autoClose) {
          this.$emit('showChange', false)
        }
        return;
      }

      if(this.uploadUrl!==''){
        const [err, data] = await uploadFile2url({
          data: file,
          uploadUrl:this.uploadUrl
        })
        if (this.autoClose) {
          this.$emit('showChange', false)
        }
        this.$refs.upload.clearFiles()
        if(err) return;
        this.$emit('input', data);
        this.$message.success('上传成功');
      }else{
        const method = this.private ? uploadKnowledgePrivateImport : uploadKnowledgeFile
        const [err, data] = await method({
          regInfo: {
            repoCode : this.repoCode
          },
          data: file
        })
        if (this.autoClose) {
          this.$emit('showChange', false)
        }
        this.$refs.upload.clearFiles()
        if(err) return;
        this.$emit('input', data);
        this.$message.success('上传成功');
      }


    },
  }
}
</script>

<style>
.fs-20{font-size:20px;}.fs-16{font-size:16px;}.my-12{margin-top:12px;margin-bottom:12px;}.w-330{width:330px;}.pb-20{padding-bottom:20px;}.w-150{width:150px;}.h-40{height:40px;}</style>
