/*
 * @Author: 赵顺陈
 * @Date: 2020-08-31 13:46:54
 * @LastEditors: 建辉
 * @LastEditTime: 2021-08-05 11:39:43
 * @Description:
 */
import dayjs from 'dayjs'
/**
 * 获取指定的key的值
 * @param {Object} data 对象
 * @param {String} keys a.b.c
 */
export const getValueWithKey = (data, keys) => {
  const keyArr = keys.split('.')
  let resultInfo = data
  keyArr.forEach((key, i) => {
    if (key in resultInfo) {
      resultInfo = resultInfo[key]
    }
  })
  return resultInfo
}

export const getFormData = (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((key) => {
    formData.append(key, params[key]);
  });
  return formData
}

// 递归删除一个数组的一个属性
export const deleteEmptyKey = (arr, deleteKey = 'children') => {
  function loop (arr) {
    return arr.map(item => {
      if(item[deleteKey].length === 0){
        delete item[deleteKey]
      }else{
        item[deleteKey] = loop(item[deleteKey]);
      }
      return item;
    })
  }
  return loop(arr);
}

export const deleteLevelChild = (arr, level = 2, deleteKey = 'children') => {
  function loop (arr) {
    return arr && arr.map(item => {
      if(item && item.level === level){
        delete item[deleteKey]
      }else{
        item[deleteKey] = loop(item[deleteKey]);
      }
      return item;
    })
  }
  return loop(arr);
}

// 根据 code 码 递归获取地区列表的text
export const getTextFromArea = (arr, codeList, nameKey = 'name') => {
  const result = []
  function loop(arr){
    arr && arr.forEach(item => {
      const itemCode = item.code;
      const findItem = codeList.find(item => {
        return item === itemCode;
      })
      if(findItem){
        result.push(item[nameKey])
      }
      if(item.children){
        loop(item.children, codeList)
      }
    })
  }
  loop(arr)
  return result;
}


// 生成随机字符串 e 生成字符串的长度
export const randomString = (e = 32) => {
  const time = Date.now();
  const t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const a = t.length;
  let n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n + time;
}


export const toBlob = (base64) => {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  let blob;
  try{
    blob = new Blob([buffer.buffer], {
      type: 'image/png'
    });
  }catch (e){
    return false;
  }
  return blob;
}
