/*
 * @Author: 建辉
 * @Date: 2020-10-12 10:52:27
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-15 19:27:48
 * @Description: 表单提交验证
 */
import {
  validEmail,
  validMobile,
  validateChineseName,
  validateBankNum,
  validMoney,
  validTelPhone,
  validPwd,
  validInteger,
  validURL,
  regIntegerOrZero,
  validIntegerOrZero
} from './validate';
import { parseidStrNumber } from './id-card';
import { validBsnDigit } from './validate-bsn'

export const validFormPwd = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入密码'));
    return;
  }
  if (!validPwd(value)) {
    return callback(new Error('密码格式错误，6~18位、需包含字母和数字'))
  } else {
    callback();
  }
}

// 需6-18位字母与数字组合，不分大小写

export const validAccount = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,18}$/;

export const validFormAccount = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入帐号'));
    return;
  }
  if (!validAccount.test(value)) {
    return callback(new Error('请输入6-18位字母与数字组合'))
  } else {
    callback();
  }
}


// 校验座机
export const validLandLine = /\d{3}-?\d{8}|\d{4}-?\d{7}/
export const validFormLandLine = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入座机号'));
    return;
  }
  if (!validLandLine.test(value)) {
    return callback(new Error('请输入合法的座机号码,如0358-12345678'))
  } else {
    callback();
  }
}


export const validFormEmail = (rule, value, callback) => {
  if (!value) {
    callback(new Error('邮箱不能为空'));
    return;
  }
  if (!validEmail(value)) {
    return callback(new Error('邮箱不合法'))
  } else {
    callback();
  }
}

// 校验验证码
export const validFormSmsCode = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入验证码'));
    return;
  }
  if (value.length !== 6) {
    return callback(new Error('请输入6位验证码'))
  } else {
    callback();
  }
}

// 验证手机号码
export const validFormMobile = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入手机号'));
    return;
  }
  if (!validMobile(value)) {
    return callback(new Error(`请输入正确的11位手机号码`))
  } else {
    callback();
  }
}

export const validFormTelPhone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('座机号不能为空'));
    return;
  }
  if (!validTelPhone(value, value.includes('-') ? 1 : 2)) {
    return callback(new Error(`请输入正确的座机号码`))
  } else {
    callback();
  }
}

// 校验内容管理 - 标题
export const validFormContentTit = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入'));
    return;
  }
  const oValid = validBsnDigit(1, 30, '');
  if (!oValid.reg.test(value)) {
    return callback(new Error(oValid.msg))
  } else {
    callback();
  }
}

// 校验中文名字
export const validFormChineseName = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入'));
    return;
  }
  if (!validateChineseName(value)) {
    return callback(new Error('请输入正确的中文姓名'))
  } else {
    callback();
  }
}


// 校验社保卡号
export const validFormSocialNo = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入'));
    return;
  }
  callback();
  // if (!validateChineseName(value)) {
  //   return callback(new Error('请输入正确的中文姓名。'))
  // } else {
  //   callback();
  // }
}

export const validFormBankNum = (rule, value, callback) => {
  // 有些为存折号，因此此处不做银行卡号16-19位限制
  if (!value) {
    callback(new Error('请输入银行卡号'));
    return;
  }
  callback();
}


// 校验身份证号
export const validFormCardNo = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入身份证号码'));
    return;
  }
  if (!parseidStrNumber(value)) {
    return callback(new Error('请输入正确的证件号码'))
  } else {
    callback();
  }
}

// 校验金额
export const validFormMoney = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入金额'));
    return;
  }
  if (!validMoney(value)) {
    return callback(new Error('请输入正确金额'))
  } else {
    callback();
  }
}

// 校验员工备注
export const validFormStaffRemark = (rule, value, callback) => {
  if (!value) {
    callback();
  } else if (value.length > 20) {
    console.log('asasas')
    callback(new Error('不超过20个字'))
  } else {
    callback();

  }
}

// 正整数
export const validFormInteger = (rule, value, callback) => {

  if (!value) {
    callback(new Error('请输入'));
    return;
  }
  if (!validInteger(value)) {
    return callback(new Error('请输入正整数'))
  } else {
    callback();
  }
}

// 正整数
export const validFormIntegerOrZero = (rule, value, callback) => {
  if (!validIntegerOrZero(value)) {
    return callback(new Error('请输入正整数'))
  } else {
    callback();
  }
}

// 链接
export const validFormURL = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入'));
    return;
  }
  if (!validURL(value)) {
    return callback(new Error('请输入正确的地址'))
  } else {
    callback();
  }
}
