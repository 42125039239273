<!--
 * @Author: 建辉
 * @Date: 2021-08-03 11:32:45
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-28 19:08:20
 * @Description: 上传文件到oss
-->

<template lang="pug">
div.pst-rlt.dspl-inbl.ovfl-hd
    input.pst-absl.l-0.r-0.b-0.fs-0.wp-100.hp-100.cs-pt(
      type="file"
      @change="fnHandleChange"
      :accept="acceptStr"
    )
    slot
</template>

<script>
import {fromBlob} from 'file-type/browser';
import { uploadToOss } from '@/api/oss';
export default {
  inheritAttrs: false,
  props: {
    accept: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    batchOperationGroup: {
      type: [Object, Boolean],
      default: null
    }
  },
  data(){
    return {
      file: null,
      acceptMap: {
        audio: 'audio/*',
        video: 'video/*',
        image: 'image/*',
        pdf: 'application/pdf',
        zip: 'application/x-rar-compressed, application/octet-stream,application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
        ppt: 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
        doc: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    }
  },
  computed:{
    acceptStr(){
      if(this.accept){
        const keys = this.accept.split(',');
        return keys.map(item => {
          return this.acceptMap[item]
        }).join(',')
      } else {
        return ''
      }
    }
  },
  methods: {
    async fnHandleChange(e){
      const file = e.target.files[0];
      if(file){
        const type = await fromBlob(file);
        const id = this.id
        const batchOperationGroupId = this.batchOperationGroup?.id
        console.log(type)
        const mimeType = type && type.mime.split('/')[0];
        const [err, res] = await uploadToOss(file)
        if(err) return;
        this.$emit('input', res.url);
        this.$emit('change', {
          mime: mimeType,
          url: res.url,
          type: type.mime,
          id,
          batchOperationGroupId
        });
        e.target.value = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input{
  opacity: 0;
  z-index: 20;
}
</style>

<style>
.l-0{left:0px;}.r-0{right:0px;}.b-0{bottom:0px;}.fs-0{font-size:0px;}.wp-100{width:100%;}.hp-100{height:100%;}</style>
