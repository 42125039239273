<!--
 * @Author: 聂涛
 * @Date: 2021-06-08 11:56:31
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-06-24 10:56:26
 * @Description: 标签组增加
-->

<template lang="pug">
el-dialog(
  width="500px"
  :visible="innerVisible"
  append-to-body
  :before-close="fnClickCancel"
)
  .flex.flex-middle.flex-center.flex-column
    TagGroup(
      ref="tagRefs"
      type="dialog"
      :form.sync="oForm"
      v-bind="$attrs"
      v-on="$listeners"
    )
</template>

<script>
import TagGroup from "@/components-pages/qwt/tag/detail/tagsGroup.vue"
export default {
  components: {
    TagGroup
  },
  props: {
    // 是否显示
    innerVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oForm: {
        groupName: "",
        order: "",
        tag	: []
      }
    }
  },
  methods: {
    // - 点击 dialog 取消
    fnClickCancel() {
      this.oForm = {
        groupName: "",
        order: "",
        tag	: []
      };
      this.$emit('update:innerVisible', false);
      // 重置form表单
      this.$nextTick(() => {
        const tagRefs = this.$refs.tagRefs;
        tagRefs.$refs.form.resetFields();
      })
    },
  },
}
</script>

<style>
</style>
