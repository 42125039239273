/*
 * @Author: 赵伟
 * @Date: 2021-05-21 10:23:56
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-11-30 16:15:55
 * @Description: file content
 */
import { getUserInfo, getUserPermission } from "@/api";
// 包含用户信息，权限按钮，权限菜单等state
export const state = () => ({
  // - 用户信息
  userInfo: {},
  // - 记住密码
  rememberPwdInfo:{
    userName: '', // - 账号
    password: '' // - 密码
  },
  // - 权限
  permission: {
    btnCodeList: [], // 按钮权限列表
    menuList: {
      children: []
    }, // 菜单列表
    fetched: false
  },
  // - 当前菜单信息
  menu: {
    topActiveTabIndex: 0,
  },
})

export const mutations = {
  UPDATE_USER_INFO(state, data){
    state.userInfo = data;
    console.log("state.userInfo", state.userInfo)
  },
  UPDATE_REMEMBER_PWD(state, data){
    if(data){
      this.$cookies.set('$nuxt_remember_pwd', data, {
        path: '/',
        maxAge: 60 * 60 * 24 * 30
      })
    } else {
      this.$cookies.remove('$nuxt_remember_pwd');
    }
    state.rememberPwdInfo = data || '';
  },
  UPDATE_PERMISSION_BTNCODELIST(state, data){
    state.permission.btnCodeList = data;
  },
  UPDATE_PERMISSION_MENULIST(state, data){
    state.permission.menuList = data;
  },
  UPDATE_PERMISSION_FETCHED(state, data){
    state.permission.fetched = data
  },
  UPDATE_MENU_ACTIVE_TAB_INDEX(state, data){
    this.$cookies.set('hms_permission_active_tab_index', data)
    state.menu.topActiveTabIndex = data;
  },
  UPDATE_MENU_ACTIVE_TAB_INDEX_WITH_PATH(state, path){
    const menuList = state.permission.menuList.children;
    const index = 0;
    const findMenu = (children, path) => {
      return children.find(item => {
        let result = (item.data.accessUrl === path)
        if(!result && item.children){
          result = findMenu(item.children, path)
        }
        return result;
      })
    }
    menuList.forEach((item, i) => {
      const findMenuRes = findMenu(item.children, path)
      if(findMenuRes){
        state.menu.topActiveTabIndex = i;
      }
    })
  }
}

export const actions = {
  // - 获取userInfo
  fetchUserInfo: async({commit, state}, options = {force: false}) => {
    console.log("userInfo", state.userInfo)
    console.log("force::", options.force)
    // debugger;
    if(!options.force && state.userInfo.userId ){
      return [null, state.userInfo]
    }
    const [err, res] = await getUserInfo();
    console.log("res::", res)
    commit('UPDATE_USER_INFO', res || {})
    return [err, res]
  },
  // - 获取权限菜单
  fetchPermission: async ({commit, state}, options = {force: false}) => {
    if(!options.force && state.permission.fetched) return [null, {}];
    const [err, data] = await getUserPermission();
    if(err) return [err, data];
    commit('UPDATE_PERMISSION_BTNCODELIST', data.btnFunctions || [])
    commit('UPDATE_PERMISSION_MENULIST', data.menuItem || {
      children: []
    })
    commit('UPDATE_PERMISSION_FETCHED', true)
    return [err, data];
  }
}
