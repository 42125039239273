<!--
 * @Author: 建辉
 * @Date: 2020-09-16 14:54:30
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-26 16:25:00
 * @Description: 用户标签-级联选择器
-->
<template lang="pug">
//- 任意一级
el-cascader.org-cascader(
  v-if="checkStrictly"
  ref="oCascaderRef"
  v-model="val"
  :options="aList"
  @change="handleChange"
  :props="propsKey"
  :show-all-levels="false"
  :collapse-tags="true"
  v-bind="$attrs"
  :placeholder="placeholder"
  clearable
)
  div(slot-scope='{node,data}' @click='fnClickNode(node, data)') {{ data.name }}

//- 最后一级
el-cascader.org-cascader(
  v-else
  v-model="val"
  :options="aList"
  @change="handleChange"
  :props="propsKey"
  v-bind="$attrs"
  :show-all-levels="false"
  :collapse-tags="true"
  :placeholder="placeholder"
)

</template>

<script>
import { scrmTagTree, sensitivityLevelEnumList } from '@/api';
export default {
  name: 'select-org',
  props: {
    value: {
      type: [Number, String, Array],
      default() {
        return 0;
      }
    },
    type: {
      type: String,
      default: ''
    },
    // 是否可选任意一级
    checkStrictly: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    tagCate: {
      type: String,
      default: ''
    },
    // scrmTagTree 标签
    // scrmRiskTree 风险等级
    methodEnum: {
      type: String,
      default: 'scrmTagTree'
    }
  },
  data(){
    return {
      aList: [],
      // val 为 number类型，但后端接口搜索列表时 空时为'' 而不是0
      val: '',
      defaultPropsKey: {
        expandTrigger: 'hover',
        checkStrictly: true, // 可选任意一级
        label: 'name',
        value: 'id',
        emitPath: false // 只返回组织 叶子节点的id
      }
    }
  },
  computed: {
    propsKey(){
      return {
        ...this.defaultPropsKey,
        checkStrictly: this.checkStrictly,
        multiple: this.multiple,
      }
    }
  },
  watch: {
    value: {
      handler(val){
        // val 为 number类型，但后端接口搜索列表时 空时为'' 而不是0
        this.val = val || ''
        // 任意一级时 选择后可隐藏 cascader
        if (this.$refs.oCascaderRef) this.$refs.oCascaderRef.dropDownVisible = false;
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetTree();
  },
  methods: {
    getTargetItem(key){
      const targetKey = this.value;
      let targetIterm = null
      const findTargetItem = (aList, key) => {
        aList.forEach(item => {
          if(item.id === key){
            targetIterm = item;
          }else{
            item.children && findTargetItem(item.children, key)
          }
        })
      }
      findTargetItem(this.aList || [], key)
      console.log(targetIterm)
      if(targetIterm){
        this.$emit('update:item', targetIterm)
        this.$emit('update:desc', targetIterm.name)
      }
    },
    fnClickNode(node, data) {
      this.val = data.id
      this.handleChange(this.val)
    },
    handleChange(aVal){
      // val 为 number类型，但后端接口搜索列表时 空时为'' 而不是0
      const sVal = aVal || '';
      this.$emit('input', sVal)
      this.$emit('change', sVal)
      this.getTargetItem(sVal)
      const parent = this.$parent;
      if(aVal && parent && parent.clearValidate){
        parent.clearValidate();
      }
    },
    async fnNetGetTree(){
      let method = null
      let params = null
      switch (this.methodEnum) {
      case 'scrmTagTree':
        method = scrmTagTree
        params = {
          id: this.id,
          businessType: this.tagCate,
          tagId: "",
          tagName: "",
        }
        break;
      case 'scrmRiskTree':
        method = sensitivityLevelEnumList
        params = {}
        break;
      default:
        break;
      }
      const [err, data] = await method({
        data: params
      })
      if(err) {
        return;
      }
      // 递归数据，如果children 长度为0，就删除
      function loopData(arr) {
        arr.forEach(item => {
          if(item.child && item.child.length){
            item.children = loopData(item.child)
          }else{
            delete item.child;
          }
        })
        return arr;
      }
      const result = loopData(data)
      this.aList = result
      // 针对开户申请页面获取对应item时不触发方法、此处手动触发一次，未直接触发handleChange方法，因会在搜索时触发两次
      if(this.val) this.getTargetItem(this.val)
    },
  }
}
</script>

<style>
</style>
