/*
 * @Author: 建辉
 * @Date: 2020-08-08 13:03:23
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-06 14:53:47
 * @Description: 校验正则
 */

/**
 * 是不是 外部链接
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const validMap = ['admin', 'editor']
  return validMap.includes(str.trim())
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
// 提取出来用于ele form表单使用
export const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function validEmail(email) {
  // const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailReg.test(email)
}

export function validMobile(phone){
  return /^1\d{10}$/.test(phone)
}

export const telPhoneReg = /^0\d{2,3}-[1-9]\d{6,7}$/
export const telPhoneReg2 = /^0\d{2,3}[1-9]\d{6,7}$/
export function validTelPhone(phone, reg = 1){
  return reg === 1 ? telPhoneReg.test(phone) : telPhoneReg2.test(phone)
}
// 中国人姓名校验
export function checkChineseName(name){
  return /^[\u4E00-\u9FA5]{2,16}$/.test(name)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}


export function validPwd(pwd){
  const regNum = /\d/;
  const regStr = /[a-zA-Z]/;
  // 只要包含数字，字母, 6-18位字符之间 就返回true
  if(regNum.test(pwd) && regStr.test(pwd) && pwd.length >= 6 && pwd.length <= 18){
    return true;
  }else{
    return false;
  }
}

/**
 * @description 校验 中国名字
 *
 * @param {String} sName 中国名字
 * @returns {Boolean}
 */
export const regChineseName = /^[\u4E00-\u9FA5]{2,16}$/;
export const validateChineseName = (sName) => {
  return regChineseName.test(sName);
};

/**
 * @description 校验 银行卡号 16~19位数校验
 * @param {Number} id 银行卡号
 * @returns {Boolean}
 */
const regBankNum = /^([1-9]{1})(\d{15,18})$/;
export const validateBankNum = (id) => {
  return regBankNum.test(id);
};

// 必须为数字，但是允许小数点
export const regMoney = /^(([1-9]\d*)|\d)(\.\d{1,2})?$/;
export const validMoney = (val) => {
  return regMoney.test(val)
}
// 正整数
export const regInteger = /^\+?[1-9][0-9]*$/
export const validInteger = (val) => {
  return regInteger.test(val)
}
// 验证正整数+0
export const regIntegerOrZero = /^(0|[1-9][0-9]*)$/
export const validIntegerOrZero = (val) => {
  return regIntegerOrZero.test(val)
}

// 座机号
export const regLandLine = /\d{3}-?\d{8}|\d{4}-?\d{7}/
export const validLandLine = (tel) => {
  return regLandLine.test(tel);
};


// emoji 验证
export const emojiReg = /(?:[\u2700-\u27BF]|(?:\uD83C[\uDDE6-\uDDFF]){2}|[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u0023-\u0039]\uFE0F?\u20E3|\u3299|\u3297|\u303D|\u3030|\u24C2|\uD83C[\uDD70-\uDD71]|\uD83C[\uDD7E-\uDD7F]|\uD83C\uDD8E|\uD83C[\uDD91-\uDD9A]|\uD83C[\uDDE6-\uDDFF]|[\uD83C[\uDE01-\uDE02]|\uD83C\uDE1A|\uD83C\uDE2F|[\uD83C[\uDE32-\uDE3A]|[\uD83C[\uDE50-\uDE51]|\u203C|\u2049|[\u25AA-\u25AB]|\u25B6|\u25C0|[\u25FB-\u25FE]|\u00A9|\u00AE|\u2122|\u2139|\uD83C\uDC04|[\u2600-\u26FF]|\u2B05|\u2B06|\u2B07|\u2B1B|\u2B1C|\u2B50|\u2B55|\u231A|\u231B|\u2328|\u23CF|[\u23E9-\u23F3]|[\u23F8-\u23FA]|\uD83C\uDCCF|\u2934|\u2935|[\u2190-\u21FF])/g;
export const validEmoji = (val) => {
  return emojiReg.test(val);
}
