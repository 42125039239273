/*
 * @Author: 赵伟
 * @Date: 2021-05-14 10:51:46
 * @LastEditors: 赵伟
 * @LastEditTime: 2022-04-21 16:21:39
 * @Description: file content
 */
// 公共api
import { request } from './http'
import Compressor from 'compressorjs';

// 压缩文件
const compressFile = (file) => {
  return new Promise((resolve, reject) => {
    // 小于5M 不压缩
    if (file && file.size && (file.size / 1024 / 1024 < 5) ) {
      resolve(file)
      return
    }
    // eslint-disable-next-line
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        // 返回blob
        resolve(result)
      },
      error() {
        // 如果压缩报错，上传源文件
        resolve(file)
      },
    });
  })
}

// - 枚举
export const fetchEnumMap = async (options) => {
  return await request('post', '/hms/ui/comm/getEnumModels', options)
}

// 上传
export const uploadFile = async (options) => {
  const miniFile = await compressFile(options.data.file);
  const formData = new FormData();
  formData.append('file', miniFile)
  const body = {
    data: formData,
    params: {
      waterMarkFlag: options.data.waterMarkFlag, // 是否需要水印
      fileStorageType: options.data.fileStorageType || '', // 是否要存储到oss
      fileName: encodeURIComponent(options.data.file.name),
      fileTypeName: options.data.fileTypeName || ''
    }
  }
  return await request('post', `/hms/ui/upload/file`, body)
}


// -  plusbound 上传
export const getPlusStaffBoundFile = async (options) => {
  const miniFile = await compressFile(options.data.file);
  const formData = new FormData();
  formData.append('file', miniFile)
  const body = {
    data: formData,
  }
  return await request('post', `/hms/ui/plusBound/getPlusStaffBoundFile`, body)
}
