/*
 * @Author: 建辉
 * @Date: 2021-04-27 15:18:37
 * @LastEditors: 建辉
 * @LastEditTime: 2021-04-27 15:18:51
 * @Description: 转化金额为科学计数法
 */

/***
 * @param {string | number} x
 *
 * @description 123456.23 -> 123,456.23
 *
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 */
export const numWithCommas = (x) => {
  if(x === '-') return '-'
  if (!x) return 0
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

