<!--
 * @Author: 聂涛
 * @Date: 2023-06-15 16:26:54
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-29 09:59:00
 * @Description: 
-->
<template>
  <el-select
    v-model="myVal"
    multiple
    filterable
    remote
    reserve-keyword
    placeholder="请输入关键词"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="input"
    >
    <el-option
      v-for="item in options"
      :key="item.chatId"
      :label="item.chatName"
      :value="item.chatId">
    </el-option>
  </el-select>
</template>

<script>
import { groupListEm } from '@/api';
export default {
  props: {
    value: {
      type: [Array, String],
      default: () => []
    }
  },
  data() {
    return {
      options: [],
      myVal: '',
      loading: false
    }
  },
  watch: {
    value: {
      handler(val) {
        this.myVal = val
        this.remoteMethod('')
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    input() {
      this.$emit('input', this.myVal)

    },
    async remoteMethod(query) {
      this.loading = true;
      this.loading = false;
      let byCondition = {
        chatIdList: this.myVal || []
      }
      if (query) {
        byCondition = {
          chatName: query,
        }
      }
      const [err, data] = await groupListEm({
        data: {
          byCondition,
          size: 50
        }
      })
      if (err) return
      if (data.records) {
        if (query) {
          this.options = data.records
        } else {
          data.records.forEach(element => {
            const result = this.options.find( o => element.chatId === o.chatId)
            if (!result) {
              this.options.push(element)
            }
          });
        }

      }
    }
  },
}
</script>

<style>

</style>