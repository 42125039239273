<!--
 * @Author: 聂涛
 * @Date: 2023-07-20 14:10:31
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-07-20 16:28:35
 * @Description: ma问题修复
-->
<template lang="pug">
div
  div(v-if="computedMode === 'edit' || onlyUseCase")
    el-cascader(
      v-if="loaded"
      :options="list"
      v-model="myVal"
      :props="props"
      collapse-tags
      @change="input"
      filterable
      :disabled="computedMode !== 'edit'"
    )
  h-space(direction="horizontal" v-else)
    el-tag(v-for="(item,i) in computedLabels" :key="i") {{  item.name  }}
</template>

<script>
import { mapState } from 'vuex'
import {loopHandler} from '@/utils/loop-handler'
export default {
  inject: {
    'h-form-mode': {
      default: ''
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    emitPath: {
      type: Boolean,
      default: false
    },
    isArr: {
      type: Boolean,
      default: false,
    },
    onlyUseCase: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Array, String],
      default: () => []
    },
    mode: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default(){
        return null
      }
    }
  },
  data() {
    return {
      keys: 0,
      myVal: [],
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      maTagList: state => state.user.maTagList
    }),
    list(){
      if(this.data){
        return this.data
      }
      return this.maTagList
    },
    computedMode () {
      return this.mode || this['h-form-mode']
    },
    props(){
      return {
        // lazy: this.myVal.length === 0,
        expandTrigger: 'hover', value : 'code', label : 'name', multiple: this.multiple, emitPath: this.emitPath,
        // lazyLoad: this.lazyLoad
      }
    },
    computedLabels(){
      const arr = []
      loopHandler(this.maTagList, (item, parentItem) => {
        if(this.myVal.includes(item.code)){
          arr.push(item)
        }
      })
      return arr;
    }
  },
  watch: {
    value: {
      handler(val) {
        if(!val) return
        if ( typeof val === 'string') {
          this.myVal = val.split(',')
        } else {
          this.myVal = val
        }
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    await this.$store.dispatch('user/fetchMATagList')
    this.loaded = true
    await this.$nextTick()
    this.loaded = true;
  },
  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: 'region' }]);
      }
      if (node.level > 1) return resolve([]);

      setTimeout(() => {
        const data = [{
          name: 'leaf',
          leaf: true
        }, {
          name: 'zone'
        }];

        resolve(data);
      }, 500);
    },
    onCheck(node, checkInfo){
      console.log(node, checkInfo)
      const {checkedKeys} = checkInfo;
      this.$emit('input', checkedKeys.join(','))
    },
    lazyLoad(node, resolve){
      setTimeout(() => {
        if(node.level === 0){

          const firstArr = this.maTagList.map(item => {
            const {children, ...rest} = item
            return rest
          })
          resolve(firstArr)
        } else {
          let target = null
          loopHandler(this.maTagList, (item, parentItem) => {
            if(item.code === node.data.code){
              target = item.children
            }
          })
          resolve(target)
        }
      }, this.loaded ? 100 : 1000);
    },
    input() {
      if(this.isArr){
        this.$emit('input', this.myVal.slice(0))
        return
      }
      this.$emit('input', this.myVal.join(',') )
    },
  },
}
</script>

<style>

</style>

<style>
</style>
