<!--
 * @Author: 建辉
 * @Date: 2021-06-22 13:57:51
 * @LastEditors: 建辉
 * @LastEditTime: 2021-06-22 14:31:26
 * @Description: 由于组织树是树状结构，故渲染的话不能使用render-label
-->

<template lang="pug">
span {{label}}
</template>

<script>
import { fetchAgentOrgTree } from '@/api';
export default {
  name: 'render-agent-org-label',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      aList: [],
      spreadArr: [],
      orgList: []
    }
  },
  computed: {
    label(){
      if(this.value === ''){
        return ''
      }
      const result = [];
      const getItem = (targetId) => {
        this.spreadArr.forEach(item => {
          if(item.id === targetId){
            result.unshift(item.name)
            if(item.pid !== 0){
              getItem(item.pid)
            }
          }
        })
      }
      getItem(this.value)
      // console.log(result)
      return result.join('/')
    }
  },
  mounted(){
    this.fnNetGetAgentOrgTree()
  },
  methods: {
    flatten1(tree) {
      const arr = [];
      function spread(tree, pid) {
        for (let i=0; i < tree.length; i++ ) {
          const item = tree[i];
          const {id, name}=item;
          arr.push({id, name, pid})
          if (item.children) {
            spread(item.children, item.id)
            // delete item.children
          }
        }
      }
      spread(tree, 0)
      this.spreadArr = arr;
    },
    async fnNetGetAgentOrgTree(){
      const [err, data] = await fetchAgentOrgTree()
      if(err) {
        return;
      }
      // 递归数据，如果children 长度为0，就删除
      function loopData(arr) {
        arr.forEach(item => {
          if(item.children && item.children.length){
            item.children = loopData(item.children)
          }else{
            delete item.children;
          }
        })
        return arr;
      }
      const result = loopData([data])
      this.aList = result
      this.flatten1(result)
      // // 针对开户申请页面获取对应item时不触发方法、此处手动触发一次，未直接触发handleChange方法，因会在搜索时触发两次
      // if(this.val) this.getTargetItem(this.val)
    },
  }
}
</script>

<style>
</style>
