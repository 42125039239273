<!--
 * @Author: 建辉
 * @Date: 2021-05-08 15:07:18
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-10-26 18:28:44
 * @Description: 初始化面包屑
-->

<template lang="pug">
div
  slot
</template>

<script>
export default {
  name: 'bread-init',
  props: {
    title: {
      type: String,
      default: ''
    },
    init: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    $route: {
      handler(val){
        const {fullPath, path} = this.$route;
        setTimeout(() => {
          if(this.init){
            this.$store.commit('client/INIT_BREAD_LIST', {
              title: this.title,
              fullPath,
              path
            })
          }else {
            this.$store.commit('client/ADD_BREAD', {
              title: this.title,
              fullPath,
              path
            })
          }
        }, 50);

      },
      immediate: true
    }
  },
  mounted(){

  }
}
</script>

<style>
</style>
