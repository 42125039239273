/*
 * @Author: 聂涛
 * @Date: 2022-06-10 16:06:21
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-16 10:14:35
 * @Description: emoji 重构
 */

import store from 'vuex';

export const emojiTextList = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '愉快', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '悠闲', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
export const emojiValList = ['[笑脸]', '[生病]', '[脸红]', '[破涕为笑]', '[恐惧]', '[失望]', '[无语]', '[嘿哈]', '[捂脸]', '[奸笑]', '[机智]', '[皱眉]', '[耶]', '[吃瓜]', '[加油]', '[汗]', '[天啊]', '[Emm]', '[社会社会]', '[旺柴]', '[好的]', '[打脸]', '[哇]', '[翻白眼]', '[666]', '[让我看看]', '[叹气]', '[苦涩]', '[裂开]', '[嘴唇]', '[合十]', '[庆祝]', '[红包]', '[發]', '[福]', '[烟花]', '[爆竹]', '👇', '👆']
export const emojiChange = (content) => {
  const reg = /\[(.*?)\]/g;
  const array = [];
  let temp = '';
  while ((temp = reg.exec(content))) {
    array.push(temp[1])
  }
  emojiTextList.forEach((item, i) => {
    if (array.includes(item)) {
      content = content.split(`[${item}]`).join(`<img style="width: 24px;vertical-align: middle;" src='https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${i}.gif' />`)
    }
  })
  emojiValList.forEach((item, i) => {
    const str = item.replace('[', '').replace(']', '')
    if (array.includes(str)){
      content = content.split(item).join(`<img style="width: 24px;vertical-align: middle;"  src="https://static.fostars.com/scrm/img/${item}.png" />`)
    }
  })
  return content;
}

export const emojiImg2Txt = (soresData) => {
  soresData.map(item => {
    const img = item.insert.image
    if (img) {
      if (img.includes('https://res.wx.qq.com/mpres/htmledition/images/icon/emotion')) {
        const index = img.split('emotion/')[1].split('.')[0]
        item.insert = `[${emojiTextList[index]}]`
      }
    }
  })
  soresData.map(item => {
    const img = item.insert.image
    if (img) {
      if (img.includes('https://static.fostars.com/scrm/img/')) {
        const name = img.split('img/')[1].split('.')[0]
        item.insert = decodeURI(name)
      }
    }
  })

}