<!--
 * @Author: 建辉
 * @Date: 2021-08-04 18:46:00
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-16 10:16:19
 * @Description: 富文本编辑器
-->

<template lang="pug">
div(ref="box" style="min-height: 180px;")
    slot
</template>

<script>
import { createTxtMsg, emojiTextList, EventBus, randomString, toBlob, emojiValList, emojiImg2Txt} from '@/utils'
import { uploadToOss } from '@/api/oss'
import {mapState} from 'vuex';
export default {
  name: 'q-editor',
  components: {
  },
  props: {
    conversationInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    maxLength: {
      type: Number,
      default: 2000
    }
  },
  data(){
    return {
      content: '',
      editorOption: {
        theme: 'snow', // snow是有工具栏的   bubble文本域
      },
    }
  },
  computed: {
    ...mapState({
      aiteMemberInfo: state => state.chat.aiteMemberInfo,
      batchOperationGroup: state => state.chat.batchOperationGroup,
    })
  },
  beforeDestroy(){
    EventBus.$off('quill-insert-text', this.addText)
    EventBus.$off('quill-insert-file', this.addFile)
  },
  mounted(){
    EventBus.$on('quill-insert-text', this.addText)
    EventBus.$on('quill-insert-file', this.addFile)

    this.$nextTick(() => {
      const quill = new window.Quill(this.$refs.box, {
        theme: 'snow',
        modules: {
          toolbar: false,
          keyboard: {
            bindings: {
              tab1: {
                key: 13,
                metaKey: true,
                handler: this.saveHtml
              },
              tab: {
                key: 13,
                ctrlKey: true,
                handler: this.addShift
              },
              tab2: {
                key: 13,
                handler: this.saveHtml
              },
            }
          }
        },
        placeholder: '请输入聊天内容...',
      });
      this.quill = quill;
    })
  },
  methods: {
    addShift() {
      this.addText('\n')
    },
    async saveHtml(){
      const soresData = this.quill.getContents().ops
      // 直接回车拦截 不发了
      if (soresData && soresData.length === 1 && soresData[0].insert === "\n") return
      // 将emoji图片修改为emoji代码
      emojiImg2Txt(soresData)
      // 处理数据
      const arr = [];
      const spaceReg = /\S/;
      // 处理源数据为一个数组
      soresData.forEach(item => {
        const isTxt = !!item.insert?.image;
        const index = arr.length - 1 > 0 ? arr.length - 1 : 0;
        let lastChildItem = arr[index];
        const childItemType = lastChildItem && lastChildItem[0] && lastChildItem[0].type || '';
        if(item.insert?.image){
          if(item.insert.image.startsWith('http')){
            arr.push([{type: 'img', content: item.insert.image}])
          } else {
            const imgBlob = toBlob(item.insert.image)
            arr.push([{type: 'img', content: imgBlob}])
          }
        } else if(item.insert?.video) {
          arr.push([{type: 'mp4', content: item.insert.video}])
        } else if(spaceReg.test(item.insert)){
          // arr.push({type: 'txt', content: item.insert.replace('\n', '')})
          if(childItemType === 'img'){
            arr.push([{type: 'txt', content: item.insert.replace('\n', '')}])
          } else {
            if(!lastChildItem) arr.push([]);
            lastChildItem = arr[index];
            lastChildItem.push({type: 'txt', content: item.insert})
          }
        } else if (item.insert === '\n') {
          if(!lastChildItem) arr.push([]);
          lastChildItem = arr[index];
          lastChildItem.push({type: 'txt', content: item.insert})
        }
      })
      for (let i = 0; i < arr.length; i++) {
        const group = arr[i];
        const groupType = group[0] && group[0].type;
        if(groupType === 'txt'){
          let groupRes = ''
          group.forEach((item) => {
            groupRes += item.content;
          })
          if (groupRes.length > 2000) {
            this.$message.error('字数不能超过2000！')
            return
          }
          if ( groupRes.endsWith('\n')) {
            groupRes = groupRes.slice(0, groupRes.length - 1)
          }
          this.socketSend('txt', groupRes)
        } else {
          console.log('group-img', group)
          for (let j = 0; j < group.length; j++) {
            const groupItem = group[j];
            await this.sendImgMsg(groupType, groupItem.content)
          }
        }
      }
      this.quill.setContents([])
    },
    // 发送图片消息
    sendImgMsg(type, file){
      return new Promise((resolve, reject) => {
        console.log(type, file)
        const isBlob = file.toString() === '[object Blob]'
        if(!isBlob){
          this.socketSend(type, file)
          resolve(true)
        }
        uploadToOss(file)
          .then(([err, data]) => {
            if(err) return;
            this.socketSend(type, data.url);
            resolve(true)
          })
      })
    },
    async socketSend(type, content){
      const contactGroupId = this.batchOperationGroup?.id
      // 微信id
      const wxId4Scrm = this.aiteMemberInfo?.wxId4Scrm
      // 图片自动带一个\n 之前的过滤放开了 现在要拦截 另外还有空字符串也要拦截
      if (content === '\n' || content === '') return
      const params = {
        type,
        content,
        sender: '',
        img: 'https://i-static.ygibao.com/qkt/img/logo.png', // TODO 头像
        receiverId: this.conversationInfo?.wxID,
        time: Date.now(),
        isSend: 1,
        uuId: randomString()
      }
      if (contactGroupId) {
        const [err, data] = await this.$store.dispatch('chat/sendMsgByGroup', {
          ...params,
          contactGroupId
        })
        if (err) return
        this.$message.success('发送成功')
      } else if (wxId4Scrm){
        const data = {
          ...params,
          reminder: wxId4Scrm
        }
        const msgInfo = createTxtMsg(data)
        this.$store.commit('chat/socketSend', msgInfo)
        this.$store.commit('chat/update_aiteMemberInfo', null)
      }else {
        const msgInfo = createTxtMsg(params)
        this.$store.commit('chat/socketSend', msgInfo)
      }
    },
    addEmoji(index){
      const imgStr = `https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif`
      const length = this.quill.selection.savedRange.index
      this.quill.insertEmbed(length, 'image', imgStr)
      this.quill.setSelection(length + 1)
    },
    fnClickAddOtherEmoji(name){
      const imgStr = `https://static.fostars.com/scrm/img/${name}.png`
      const length = this.quill.selection.savedRange.index
      this.quill.insertEmbed(length, 'image', imgStr)
      this.quill.setSelection(length + 1)
    },
    addEmebed({mime, url}){
      const length = this.quill.selection.savedRange.index
      this.quill.insertEmbed(length, mime, url)
      this.quill.setSelection(length + url.length)
    },
    addText(content) {
      const length = this.quill.selection.savedRange.index
      this.quill.insertText(length, content)
      this.quill.setSelection(length + content.length)
    },
    addFile( {content, type}) {
      const length = this.quill.selection.savedRange.index
      this.quill.insertEmbed(10, type, content);
    },
    setText(content) {
      this.quill.setText(content)
    },

    getText() {
      const soresData = this.quill.getContents().ops
      const maxLength = this.maxLength
      console.log(soresData)
      // 将emoji图片修改为emoji代码
      emojiImg2Txt(soresData)
      // 处理数据
      const arr = [];
      const spaceReg = /\S/;
      // 处理源数据为一个数组
      soresData.forEach(item => {
        const isTxt = !!item.insert?.image;
        const index = arr.length - 1 > 0 ? arr.length - 1 : 0;
        let lastChildItem = arr[index];
        const childItemType = lastChildItem && lastChildItem[0] && lastChildItem[0].type || '';
        if(spaceReg.test(item.insert)){
          // arr.push({type: 'txt', content: item.insert.replace('\n', '')})
          if(childItemType === 'img'){
            arr.push([{type: 'txt', content: item.insert.replace('\n', '')}])
          } else {
            if(!lastChildItem) arr.push([]);
            lastChildItem = arr[index];
            lastChildItem.push({type: 'txt', content: item.insert.replace('', '')})
          }
        }
      })
      let groupRes = ''
      for (let i = 0; i < arr.length; i++) {
        const group = arr[i];
        const groupType = group[0] && group[0].type;
        if(groupType === 'txt'){
          group.forEach((item) => {
            groupRes += item.content;
          })
          if (groupRes.length > maxLength) {
            this.$message.error(`字数不能超过${maxLength}！`)
            return
          }
          if (groupRes.endsWith('\n')) {
            groupRes = groupRes.slice(0, groupRes.length - 1)
          }
        }
      }
      return groupRes
    },
    clearText(){
      this.quill.setContents([])
    },
    onEditorBlur(e){
    },
    onEditorFocus(e){},
    onEditorChange(e){

    }
  }
}
</script>

<style lang="scss">

.ql-container.ql-snow {
  border: 0;
}
.ql-editor {
  padding: 10px;
  min-height: 180px;
  max-height: 400px;
  overflow-y: auto;
}

</style>

<style lang="scss" scoped>
::v-deep img {
  max-width: 300px;
}
</style>


<style>
</style>
