<!--
 * @Author: 赵伟
 * @Date: 2021-05-24 13:58:10
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-05-24 15:54:38
 * @Description: file content
-->
<template lang="pug">
el-tooltip(
    effect="dark"
    :content="content"
    :placement="placement"
  )
    slot
</template>

<script>
export default {
  props: {
    // 方向
    placement: {
      type: String,
      default: "bottom" // 上top start top end/   左left start left end  右 right start right end
    },
    // 内容
    content:{
      type: String,
      require: true,
      default: ""
    }
  }
}
</script>

<style>
.el-tooltip__popper{
  padding: 10px;
}
</style>

<style>
</style>
