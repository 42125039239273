<!--
 * @Author: wangyaqiang
 * @Date: 2022-07-12 15:30:08
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-07-14 10:10:51
 * @Description: echarts
-->
<template lang="pug">
div.wp-100.hp-100(ref="box")
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  data(){
    return {
      chart: null,
    }
  },
  watch: {
    options: {
      handler(val){
        this.init()
      },
      deep: true,
    }
  },
  mounted(){
    this.$nextTick(() => {
      this.init()
    })
  },
  destroyed(){
    this.chart && window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize(){
      this.chart.resize();
    },
    init(){
      console.log('options:---', window.echarts)
      if(!this.options && !this.options.length) return;
      const myChart = window.echarts.init(this.$refs.box);
      this.chart = myChart;
      this.chart.setOption(this.options)
      window.addEventListener('resize', this.handleResize)
    }
  }
}
</script>


<style>
.wp-100{width:100%;}.hp-100{height:100%;}</style>
