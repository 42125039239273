/*
 * @Author: 赵伟
 * @Date: 2021-11-30 17:58:31
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-12-18 17:41:08
 * @Description: 订单列表（团单）
 */

import { request } from "./http"

// 订单列表页
export const pageOrderInfo = async (options) => {
  return await request('post', '/hms/ui/order/pageOrderInfo', options)
}

// 订单列表: 导出
export const exportOrder = options => {
  return request('post', '/hms/ui/order/exportOrder', options)
}

// 订单详情
export const getOrderDetailsInfo = options => {
  return request('post', '/hms/ui/order/getOrderDetailsInfo', options)
}


// 导入承保名单
export const importPolicy = options => {
  return request('post', '/hms/ui/order/importPolicy', options)
}

// - 团体投保导入
export const importGroupOrder = options => {
  return request('post', '/hms/ui/order/importGroupOrder', options)
}


export const updateOrderAgent = options => {
  return request('post', '/hms/ui/order/updateOrderAgent', options)
}

