<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:10:54
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-04-25 17:12:05
 * @Description: Description
-->
<template lang="pug">
div
  el-tabs.w-600(
    style="min-height: 300px;"
    type="border-card"
    v-model="msgType")
    el-tab-pane(
      v-for="(item) in editorList"
      :label="item.label"
      :key="item.type"
      :name="item.type")
      component(
        :is="item.type"
        :ref="item.type"
        v-bind="$attrs"
        :max="max"
      )
  xh-button.mt-10(type="primary" v-if="footerShow" @click="saveMsgContent(false)") 添加预览
  xh-button.mt-10(type="primary" v-if="footerShowMaterial" @click="saveMsgContent(true)") 保存至素材库/添加预览

</template>
<script>
import Txt from './Txt.vue'
import Img from './Img.vue'
import H5 from './H5.vue'
import Video from './Video.vue'
import File from './File.vue'
import QA from './QA.vue'
import Mp3 from './Mp3.vue'

import { validURL } from '@/utils/validate';
import { materialAutoSave } from '@/api'
import { EventBus } from '@/utils'

export default {
  name: 'editor',
  components: {
    'v-Txt': Txt,
    'v-Img': Img,
    'v-Mp4': Video,
    'v-H5': H5,
    'v-File': File,
    'v-Knowledge': QA,
    'v-Mp3': Mp3
  },
  inheritAttrs: true,
  props: {
    // a: [ 'txt', 'img', 'mp4', 'h5', 'file', 'qa'],
    include: {
      type: Array,
      default: () => {
        return ['txt', 'img', 'mp4', 'h5', 'file', 'mp3']
      }
    },
    footerShow: {
      type: Boolean,
      default: true,
    },
    footerShowMaterial: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 1,
    }
  },
  data: () => ({
    defaultEditorList: [
      { type: 'v-Txt', label: '文本' },
      { type: 'v-Img', label: '图片' },
      { type: 'v-H5', label: '链接' },
      { type: 'v-Mp4', label: '视频' },
      { type: 'v-File', label: '文件' },
      { type: 'v-Knowledge', label: '问答' },
      { type: 'v-Mp3', label: '音频' }

    ],
    msgType: 'v-Txt',
  }),
  computed: {
    editorList() {
      // 根据传来的数组过滤需要展示的 tab 列
      const arr = this.defaultEditorList.filter((item) => {
        const type = item.type.split('-')[1].toLowerCase()
        if (this.include.includes(type)) return item;
      })
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.msgType = arr[0]?.type
      return arr
    }
  },
  created() {},
  mounted() {
    EventBus.$on('EditorChildSetVal', this.fnChildSetVal)
    EventBus.$on('fnChildSetValByWeChat', this.fnChildSetValByWeChat)
  },
  destroyed() {
    EventBus.$off('EditorChildSetVal', this.fnChildSetVal)
    EventBus.$off('fnChildSetValByWeChat', this.fnChildSetValByWeChat)

  },
  methods: {

    fnGetContent(msgType) {
      const max = this.max;
      let content = null;
      let contents = null;
      switch (msgType) {
      case 'txt':
        content = this.fnHandleMsgBody('Txt');
        break;
      case 'img':
        // 处理 朋友圈 需要多张图片的问题做兼容
        contents = this.fnHandleMsgBody('Img');
        if (contents && contents.length) {
          if (max < 2) {
            content = contents[0]
          } else {
            content = contents;
          }
        }
        break;
      case 'file':
        content = this.fnHandleMsgBody('File');
        break;
      case 'mp3':
        content = this.fnHandleMsgBody('Mp3');
        break;
      case 'mp4':
        content = this.fnHandleMsgBody('Mp4');
        break;
      case 'h5':
        content = this.fnHandleMsgBody('H5');
        break;
      case 'knowledge':
        content = this.fnHandleMsgBody('Knowledge');
        break;
      default:
        content = ''
        break;
      }
      return content
    },
    // 保存 数组
    saveMsgContent(save) {
      let msgType = this.msgType.toLowerCase();
      let item = null;
      if (msgType) {
        msgType = msgType.split('-')[1]
      }
      const content = this.fnGetContent(msgType)
      // 判断是否填入内容
      if (content) {
        if (save) {
          this.saveMsgCategroy(msgType, content)
        }
        item = {
          msgType,
          content
        }
        // 下期优化
        // if (msgType === 'txt') {
        //   if (item.content.length > maxLength) {
        //     this.$message.error(`字数不能超过${maxLength}！`)
        //     return
        //   }
        // }
        if (msgType === 'h5') {
          if (!content.linkIconUrl || !content.linkTitle || !content.linkDesc || !content.linkUrl) {
            if (!validURL(content.linkUrl)) {
              this.$message.error('请检查链接输入的是否正确!')
              return
            }
            this.$message.error('卡片内容未添加完整')
            return
          }

          this.$refs['v-H5'][0].fnClearEditor();
          // 需要 单独处理 用于展示
          item.msgType = msgType.toUpperCase();
          Object.assign(item, item.content)
          item.content = null;
        }
        // console.log(item, 'itemitem', this.msgType)
        this.$emit('fnMsgItem', item);
      } else if (msgType !== 'txt') {
        this.$message.warning('请输入内容之后在添加!')
      }
    },
    fnHandleMsgBody(type) {
      let content = null;
      const msgType = 'v-' + type;
      if (!this.$refs[msgType][0]) return;
      content = this.$refs[msgType][0].fnGetEditor()
      if (type !== 'H5' && content) {
        this.$refs[msgType][0].fnClearEditor();
      }
      return content;
    },
    async saveMsgCategroy(msgType, content) {
      let h5Content = {}
      if (msgType === 'h5') {
        h5Content = {
          ...content
        }
        content = ''
      }
      const [err, data] = await materialAutoSave({
        data: {
          materialType: msgType,
          content,
          ...h5Content
        }
      })
      if (err) return
      this.$message.success('保存成功')
    },
    fnChildSetVal(val) {
      debugger
      if (!val.materialType) return
      const type = val.materialType.replace(/^\S/, s => s.toUpperCase())
      const msgType = 'v-' + type;
      this.msgType = msgType
      this.$nextTick( () => {
        if (!this.$refs[msgType][0]) return;
        this.$refs[msgType][0].fnSetEditor(val)
      })
    },
    fnChildSetValByWeChat(val) {
      const type = val.msgType.replace(/^\S/, s => s.toUpperCase())
      const msgType = 'v-' + type;
      this.msgType = msgType
      this.$nextTick( () => {
        if (!this.$refs[msgType]) return;
        const params = {
          ...val,
          materialType: val.msgType
        }
        this.$refs[msgType][0].fnSetEditor(params)
      })
    }
  }
}
</script>
<style scoped lang='scss'>
</style>




<style>
.w-600{width:600px;}.mt-10{margin-top:10px;}</style>
