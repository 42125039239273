<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:03:40
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-08-29 15:43:07
 * @Description: Description
-->
<template lang="pug">
div(style="min-height:236px;")
  upload(width="152" height="152" type="SingleFile" v-model="defaultItemURL" accept="video")
</template>
<script>
export default {
  name: 'h-video',
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    defaultItem: {
      file: '',
    },
    defaultItemURL: '',
  }),
  created() {},
  mounted() {},
  methods: {
    fnSetEditor(val) {
      if (val.materialType === 'mp4') {
        this.defaultItemURL = val.content
      }
    },
    // 获取输入的内容
    fnGetEditor() {
      console.log('this.defaultItem.file:--', this.defaultItem.file)
      return this.defaultItemURL || '';
    },
    // 清除输入的内容
    fnClearEditor() {
      this.defaultItemURL = ''
    }
  }
}
</script>
<style scoped lang='scss'>
</style>
<style>
</style>
