/*
 * @Author: 建辉
 * @Date: 2021-04-26 16:02:57
 * @LastEditors: 建辉
 * @LastEditTime: 2021-05-08 15:32:54
 * @Description: 客户端的store信息
 */


export const state = () => {
  return {
    collapseMenu: false, // 是否展开menu
    breadList: [], // 面包屑list
    imgViewer: {
      show: false, // 是否显示图片
      list: [] // 图片列表
    }
  }
}


export const mutations = {
  // 触发mutation，显示图片，用于预览图片弹窗
  UPDATE_IMG_VIEWER(state, {list, index, show = true}){
    state.imgViewer = {
      list,
      index,
      show
    }
  },
  // toggleMenu collapseMenu
  ToggleCollapseMenu(state){
    state.collapseMenu = !state.collapseMenu
  },
  // add bread item
  ADD_BREAD(state, data){
    const bHasBread = state.breadList.find(item => {
      return item.title === data.title;
    })
    if(bHasBread) return;
    state.breadList.push(data);
  },
  // init breadlist
  INIT_BREAD_LIST(state, data){
    state.breadList = [data]
  },
  // splice the last bread item
  SPLICE_LAST_BREAD_ITEM(state, data){
    state.breadList.splice(state.breadList.length -1, 1, data)
  },
  // delete bread item from index
  DELETE_BREAD_FROM_INDEX(state, index){
    state.breadList.splice(index)
  }
}
