<template lang="pug">
el-dialog(
  :visible="vmVisible"
  :showClose="false"
  :width="vmWidth"
  :append-to-body="true"
  max-width="400px"
  top="15vh"
  v-bind="$attrs"
)
  div(slot="title")
    div.fw-bold.text-center.fs-16(style="color: #1F2D3D;" v-html="vmTitle")
  div.fs-14(style="line-height: 1.5em;" :class="[align === 'center' ? 'text-center' : '']" v-html="vmContent")
  .text-center.mt-30
    xh-btn.w-150(style="margin-right: 0;" type="primary" @click="fnClickSure") 确定
</template>


<script>
// alert
export default {
  name: 'alert',
  props: {
    align: {
      type: String,
      default: '' // center
    },
    showDialog: {
      type: Boolean, // 是否显示弹框
      default: null
    },
    alertWidth: {
      type: String,
      default: null
    },
    alertTitle: {
      type: String,
      default: null
    },
    alertContent: {
      type: String,
      default: null
    }
  },
  data(){
    return {
      visible: false,
      width: '30%',
      title: '提示',
      content: '这里是默认内容'
    }
  },
  computed: {
    vmVisible(){
      return this.showDialog !== null ? this.showDialog : this.visible;
    },
    vmWidth(){
      return this.alertWidth !== null ? this.alertWidth : this.width;
    },
    vmTitle(){
      return this.alertTitle !== null ? this.alertTitle : this.title;
    },
    vmContent(){
      return this.alertContent !== null ? this.alertContent : this.content;
    }
  },
  methods: {
    show(){
      this.visible = true;
      return this;
    },
    fnClickSure(){
      this.visible = false
      this.$emit('sure')
    }
  }
}
</script>

<style>
.fs-16{font-size:16px;}.fs-14{font-size:14px;}.mt-30{margin-top:30px;}.w-150{width:150px;}</style>
