<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:03:45
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-08-29 15:42:47
 * @Description: Description
-->
<template lang="pug">
div(style="min-height:248px;")
  .flex
    .mr-10
      el-input.mb-5(size="medium" placeholder="标题" v-model="defaultItem.linkTitle")
      el-input.my-15(size="medium" placeholder="内容" v-model="defaultItem.linkDesc")
    div
      upload(width="100" height="100" type="MultiImg" :max="1" v-model="defaultItem.linkIconUrl"  :oss="true")
  el-input(size="medium" placeholder="请输入https链接" v-model="defaultItem.linkUrl")
</template>
<script>
import _ from 'lodash'
export default {
  name: 'h-h5',
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    defaultItem: {
      linkTitle: '',
      linkDesc: '',
      linkIconUrl: '',
      linkUrl: ''
    }
  }),
  created() {},
  mounted() {},
  methods: {
    fnSetEditor(val) {
      if (val.materialType === 'h5'|| val.materialType === 'H5') {
        this.defaultItem = {
          linkTitle: val.linkTitle,
          linkDesc: val.linkDesc,
          linkIconUrl: [val.linkIconUrl],
          linkUrl: val.linkUrl
        }
      }
    },
    // 获取输入的内容
    fnGetEditor() {
      const tmp = _.cloneDeep(this.defaultItem)
      tmp.linkIconUrl = tmp.linkIconUrl[0]
      // console.log(tmp, '49')
      return tmp || '';
    },
    // 清除输入的内容
    fnClearEditor() {
      this.defaultItem = this.$options.data().defaultItem;
    }
  }
}
</script>
<style scoped lang='scss'>
</style>
<style>
.mr-10{margin-right:10px;}.mb-5{margin-bottom:5px;}.my-15{margin-top:15px;margin-bottom:15px;}</style>
