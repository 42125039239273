<!--
 * @Author: 建辉
 * @Date: 2021-04-27 14:03:13
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-03-16 14:01:48
 * @Description: 图片预览
-->

<template lang="pug">
div.dspl-none(ref="box")
  ul
    li(v-for="(item, i) in srcList" :key="item")
      img.w-600.h-600(
        :data-original="item.replace('fileSizeType=THUMBNAIL', '')"
        :src="item"
        :alt="item"
      )

</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'viewerjs';
import _ from 'lodash'
import { mapState } from 'vuex'
export default {
  name: 'viewer',
  props: {
    inline: {
      type: Boolean,
      default: false // 默认不是弹窗模式
    },
    index: {
      type: Number,
      default: 0 // 默认显示第0张图片
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    defaultOptions: {
      type: Object,
      default: () => {
        return {
          inline: false,
          backdrop: true, // 开启一个遮罩，在model模式下使用，默认开启
          button: true, // 是否显示右上角button，用来预览，默认开启
          title: false, // 显示title，不需要修改
          navbar: true, // 显示导航，不需要修改
          toolbar: true, // 默认显示，考虑在只有一张图片的时候，关闭toolbar显示
          fullscreen: false, // 不建议打开
          initialViewIndex: 0, // 默认显示的图片index
        }
      }
    }
  },
  data () {
    return {
      // srcList: [],
      // srcList: [
      //   'https://int-gx.fostars.com/api/ent/file/download/9de22540411e11eb6ee6ad7572048762?flowno=9de22540411e11eb6ee6ad7572048762&fileSizeType=THUMBNAIL',
      //   'https://int-gx.fostars.com/api/ent/file/download/a18367e0411e11eb6ee6ad7572048762?flowno=a18367e0411e11eb6ee6ad7572048762&fileSizeType=THUMBNAIL',
      //   'https://int-gx.fostars.com/api/ent/file/download/9c6dd4c0411e11eb6ee6ad7572048762?flowno=9c6dd4c0411e11eb6ee6ad7572048762&fileSizeType=THUMBNAIL'
      // ]
    }
  },
  computed: {
    ...mapState({
      imgViewer: state => state.client.imgViewer
    }),
    show(){
      return this.imgViewer.show
    },
    srcList(){
      if(this.inline){
        return this.list
      }else{
        return this.imgViewer.list
      }
    }
  },
  watch: {
    show(val){
      if(val){
        this.showImg()
      }
    },
    index(i){
      if(this.inline && i >= 0){
        setTimeout(() => {
          this.view(i)
        }, 20)
      }
    },
    srcList: {
      handler(val) {
        if(val.length > 0){
          this.inlineInit();
        }
      },
      immediate: true
    }
  },
  methods: {
    inlineInit(){
      if(this.inline && process.client){
        const _this = this
        setTimeout(() => {
          this.viewer = new Viewer(this.$refs.box, {
            inline: true,
            title: false,
            button: true,
            initialViewIndex: this.index >= 0 ? this.index : 0,
            // 切换图片
            view (e) {
              _this.$emit('update:index', e.detail.index)
              _this.$emit('update:currentImgUrl', e.detail.originalImage.currentSrc)
              _this.$emit('change', {
                index: e.detail.index,
                imgUrl: e.detail.originalImage.currentSrc
              })
            },
            url(image){
              return image.src.replace('fileSizeType=THUMBNAIL', '');
            }
          });
          this.viewer.show();
        }, 100)
      }
    },
    // 显示第几张图片
    view(index){
      this.viewer && this.viewer.view(index || 0)
    },
    // 图片预览，默认显示第0个
    listenHide(){
      if(!this.inline){
        this.$store.commit('client/UPDATE_IMG_VIEWER', {
          list: [],
          show: false,
          index: 0
        })
      }
      this.$refs.box.removeEventListener('hide', this.listenHide)
      this.viewer.destroy();
    },
    /**
     * list 要显示的数组图片
     * index 想要显示第几张图片，默认是第0个
     */
    showImg(){
      if(this.viewer && this.viewer.viewed) return;
      this.viewer = new Viewer(document.body, {
        url: 'data-original',
        zIndex: 6000,
        title: false,
        backdrop: 'static',
        initialViewIndex: this.imgViewer.index || 0,
        show: () => { // 动态加载图片后，更新实例
          // this.viewer.update();
        },
        hidden: () => {
          this.viewer.destroy();
          this.$store.commit('client/UPDATE_IMG_VIEWER', {
            list: [],
            show: false,
            index: 0
          })
          this.viewer = null
        },
      });
      setTimeout(() => {
        this.viewer.show();
      }, 100)
    }
  }
}
</script>

<style>
.w-600{width:600px;}.h-600{height:600px;}</style>
