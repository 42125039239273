<!--
 * @Author: 建辉
 * @Date: 2021-11-08 15:41:51
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-03-27 14:44:40
 * @Description: 列表查询页面
-->

<template lang="pug">
  div
    slot(name="top")
    div.pt-20.px-20
      el-form(inline @submit.native.prevent="")
        el-form-item(size="small" :label="formItem.label" v-for="(formItem, i) in formItems" :key="i")
          render-item(
            :formItem="formItem"
            @input="fnHandleInput"
            :index="i"
          )
        el-form-item(size="small" v-if="formItems && formItems.length")
          xh-btn.w-100.ml-20(size="small" type="primary" icon="search" @click="fnClickSearch") 查询
          xh-btn.w-100(size="small" icon="gengxin" @click="fnClickReset") 重置
    .h-10(style="background: #f5f7fa;" v-if="bShowTopDistance")
    slot(name="middle")
    .pt-20.px-20
      slot(name="action")
      xh-table(
        v-bind="$attrs"
        border
        :checked-list.sync="checkedList"
        :id="id"
        :rowId="rowId"
        :columns="tableData.columns"
        :data="tableData.data"
        :pagination="pagination"
        :pager-count="5"
        :total="tableData.total"
        :page-size="limit",
        :current-page.sync="pn"
        v-loading="tableData.bLoading"
        @size-change="fnHandleSizeChange",
        @p-current-change="fnHandleCurrentChange"
        ref="xTable"
        :style="{ overflowY: tableScrollY ? 'scroll' : 'hidden' }"
      )
</template>

<script>
import RenderItem from './render-item.vue'
import _ from 'lodash'
import dayjs from '@/utils/dayjs'
export default {
  components: {
    RenderItem
  },
  props: {
    rowId: {
      type: String,
      default: ''
    },
    formItems: {
      type: Array,
      default(){
        return []
      }
    },
    id: {
      type: String,
      default: ''
    },
    history: {
      type: Boolean,
      default: true
    },
    tableData: {
      type: Object,
      default(){
        return {
          columns: [],
          data: []
        }
      }
    },
    bShowTopDistance: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: true
    },
    nPageLimit: {
      type: Number,
      default: 20
    },
    tableScrollY: {
      type: Boolean,
      default: true
    },
  },
  data(){
    return {
      limit: 20,
      pn: 1,
      checkedList: []
    }
  },
  watch: {
    checkedList: {
      handler(val){
        this.$emit('checkboxUpdate', val);
      }
    }
  },
  mounted(){
    this.limit = _.cloneDeep(this.nPageLimit);
    console.log(this.limit)

    // 恢复历史记录，根据url修改
    const query = this.$route.query;
    this.limit = Number(query.limit || this.nPageLimit)
    this.pn = Number(query.pn || 1)
    const newFormItems = this.formItems
    if (Object.keys(query).length) {
      newFormItems.map(item => {
        const prop = item.prop;
        return {
          ...item,
          val: query[prop]
        }
      })
    }
    if(this.history){
      this.$emit('update:formItems', newFormItems)
    }
    const params = this.fnBsnGetApiParams(newFormItems, {pn: this.pn, limit: this.limit})
    this.$emit('formChange', params)
  },
  methods: {
    fnHandleSizeChange(size){
      this.limit = size;
      this.pn = 1;
      const params = this.fnBsnGetApiParams(this.formItems, {pn: this.pn, limit: this.limit})
      this.$emit('formChange', params)
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          pn: this.pn,
          limit: this.limit,
          t: Date.now(),
        }
      })
    },
    fnHandleCurrentChange(pn){
      this.pn = pn;
      const params = this.fnBsnGetApiParams(this.formItems, {pn: this.pn, limit: this.limit})
      this.$emit('formChange', params);
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          pn: this.pn,
          t: Date.now(),
        }
      })
    },
    // 获取api请求的参数
    fnBsnGetApiParams(formItems, rest = {}){
      const info = {};
      formItems.forEach((item, i) => {
        info[item.prop] = item.val || '';
        if(item.component === 'el-date-picker'){
          if(item.bindProps?.type?.includes('range')){
            info[item.prop] = item.val && item.val.map(itemVal => {
              return dayjs(itemVal).format(item.format)
            }) || []
          }
        }
      })
      return {
        ...info,
        ...rest
      }
    },
    fnClickReset(){
      const newFormItems = _.cloneDeep(this.formItems).map((item, i) => {
        let val = '';
        if(item.component === 'el-date-picker'){
          if(item.bindProps?.type?.includes('range')){
            val = []
          }
        }
        return {
          ...item,
          val
        }
      })
      this.pn = 1;
      // 重置表单数据
      this.$emit('update:formItems', newFormItems)
      // 获取api请求参数
      const params = this.fnBsnGetApiParams(newFormItems, {pn: this.pn || 1, limit: this.limit || this.nPageLimit})
      this.$emit('formChange', params)
      // 路由记录
      if(this.history){
        this.$router.push({
          path: this.$route.path,
          query: {
            ...params
          }
        })
      }
    },
    fnBsnGetQueryInfo(){
      const queryInfo = {
        t: Date.now()
      }
      this.formItems.forEach(item => {
        queryInfo[item.prop] = item.val
      })
      return queryInfo;
    },
    // 点击按钮搜索
    fnClickSearch(){
      this.pn = 1;
      const params = this.fnBsnGetApiParams(this.formItems, {pn: this.pn, limit: this.limit})
      this.$emit('formChange', params)
      if(this.history){
        this.$router.push({
          path: this.$route.path,
          query: {...params, t: Date.now()}
        })
      }
    },
    fnClickDelSelected(){
      this.$emit('checkboxUpdate', []);
      this.$refs.xTable.fnClearAllCheck()
      this.fnClickSearch()
    },
    fnHandleInput({index, val}){
      const newFormItems = _.cloneDeep(this.formItems).map((item, i) => {
        return {
          ...item,
          val: i === index ? val : item.val
        }
      })
      this.$emit('update:formItems', newFormItems)
      // - 日期/enum-pick 可以立刻查询
      if(newFormItems[index].component !== 'el-input'){
        const params = this.fnBsnGetApiParams(newFormItems, {pn: 1, limit: this.limit})
        this.$emit('formChange', params)
        if(this.history){
          this.$router.push({
            path: this.$route.path,
            query: {...params, t: Date.now()}
          })
        }
      }
    }
  }
}
</script>


<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-right: 20px!important;
}
::v-deep .el-form-item__label{
  line-height: 34px!important;
}
</style>

<style>
.pt-20{padding-top:20px;}.px-20{padding-left:20px;padding-right:20px;}.w-100{width:100px;}.ml-20{margin-left:20px;}.h-10{height:10px;}</style>
