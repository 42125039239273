/*
 * @Author: xiaoguang_10@qq.com
 *
 * @description 表格选择态
 *
 * 1. 绑定table ref="table"
 * 2. table 添加 @selection-change="handleSelectionChange"
 * 3. 设置唯一key oTableData.idKey
 * 4. table 数据为 oTableData.data
 * 5. 每次翻页、切换size 先要调用 this.changePageCoreRecordData()
 * 6. 每次点击搜索时
 *    a. 清除选中态（产品要求），需调用 this.clearAllSection()
 *    b. 若保留 需先调用 this.changePageCoreRecordData()
 * 7. 每次搜索完结果后,
 *     setTimeout(() => {
        this.setSelectRow()
      }, 20)
 *
 * 内部已处理 可忽略 (保存的时候 先调用 this.getAllSelectionData()，然后 获取 multipleSelectionAll | multipleSelection)
 * @Date: 2020-10-16 10:36:00
 * @Last Modified by: xiaoguang_10@qq.com
 * @Last Modified time: 2020-10-20 10:37:31
 *
 * https://github.com/wanglu05/element-ui-memory-page/blob/master/src/components/table.vue
 */


export default {
  data() {
    return {
      // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
      // oTableData.idKey
      multipleSelectionAll: [], // 所有选中的数据包含跨页数据
      multipleSelection: [] // 当前页选中的数据
    }
  },
  mounted() {
  },

  methods: {
    getAllSelectionData() {
      // 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
      this.changePageCoreRecordData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      setTimeout(() => {
        this.changePageCoreRecordData()
      }, 20);
    },

    clearAllSection() {
      this.multipleSelection = []
      this.multipleSelectionAll = []
      this.$refs.table.clearSelection()
    },

    // 设置选中的方法
    setSelectRow() {
      if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
        return
      }
      // 标识当前行的唯一键的名称
      const idKey = this.oTableData.idKey
      const selectAllIds = []
      this.multipleSelectionAll.forEach((row) => {
        selectAllIds.push(row[idKey])
      })
      this.$refs.table.clearSelection()
      for (let i = 0; i < this.oTableData.data.length; i++) {
        if (selectAllIds.includes(this.oTableData.data[i][idKey])) {
          // 设置选中，记住table组件需要使用ref="table"
          this.$refs.table.toggleRowSelection(this.oTableData.data[i], true)
        }
      }
    },

    // 记忆选择核心方法
    changePageCoreRecordData() {
      // 标识当前行的唯一键的名称
      const idKey = this.oTableData.idKey
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.multipleSelection
        return
      }
      // 总选择里面的key集合
      const selectAllIds = []
      this.multipleSelectionAll.forEach((row) => {
        selectAllIds.push(row[idKey])
      })
      const selectIds = []
      // 获取当前页选中的id
      this.multipleSelection.forEach((row) => {
        selectIds.push(row[idKey])
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (!selectAllIds.includes(row[idKey])) {
          this.multipleSelectionAll.push(row)
        }
      })
      const noSelectIds = []
      // 得到当前页没有选中的id
      this.oTableData.data.forEach((row) => {
        if (!selectIds.includes(row[idKey])) {
          noSelectIds.push(row[idKey])
        }
      })
      noSelectIds.forEach((id) => {
        if (selectAllIds.includes(id)) {
          for (let i = 0; i < this.multipleSelectionAll.length; i++) {
            if (this.multipleSelectionAll[i][idKey] === id) {
              // 如果总选择中有未被选中的，那么就删除这条
              this.multipleSelectionAll.splice(i, 1)
              break
            }
          }
        }
      })
    },
  }
}
