
import { request } from "./http"


// 订单统计
export const orderSettleCount = async (options) => {
  return await request('post', '/hms/ui/settle/orderSettleCount', options)
}

// 结算列表
export const pageSettleInfo = options => {
  return request('post', '/hms/ui/settle/pageSettleInfo', options)
}

// 结算导出
export const exportSettle = options => {
  return request('post', '/hms/ui/settle/exportSettle', options)
}
