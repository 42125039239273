<!--
 * @Author: 聂涛
 * @Date: 2022-02-08 17:02:13
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-02-10 14:39:48
 * @Description: 拖动布局
-->


<template lang="pug">
div
    #dropDiv.flex.flex-center.flex-middle(:class="active ? 'active' : 'active-end' ")
      i.el-icon-upload
      span.ml-10 拖拽上传
</template>

<script>
import {fromBlob} from 'file-type/browser';
import { uploadToOss } from '@/api/oss';

export default {
  props: {
    accept: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    dropDivKey: {
      type: String,
      default: 'ql-container'
    }
  },
  data() {
    return {
      active: false,
      file: null,
      acceptMap: {
        audio: 'audio/*',
        video: 'video/*',
        image: 'image/*',
        pdf: 'application/pdf',
        zip: 'application/x-rar-compressed, application/octet-stream,application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
        ppt: 'application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
        doc: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fnInitDom()
    })

  },
  methods: {
    async fnHandleChange(file){
      if(file){
        const type = await fromBlob(file);
        const id = this.id
        console.log(type)
        const mimeType = type && type.mime.split('/')[0];
        const [err, res] = await uploadToOss(file)
        if(err) return;
        this.$emit('input', res.url);
        this.$emit('change', {
          mime: mimeType,
          url: res.url,
          type: type.mime,
          id
        });
      }
      this.active = false
    },
    fnInitDom() {
      // 给目标div增加可以拖拽事件
      const dropDiv = document.getElementsByClassName(this.dropDivKey)[0];
      if (!dropDiv) return
      dropDiv.addEventListener('drop', this.dropEvent, false)
      dropDiv.addEventListener('dragleave', (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.active = false
      })
      dropDiv.addEventListener('dragenter', (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.active = true
      })
      dropDiv.addEventListener('dragover', (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.active = true
      })
    },
    // 拖动api开始执行
    dropEvent(e) {
      e.stopPropagation();
      e.preventDefault()
      if (e.dataTransfer.files.length) {
        this.fnHandleChange(e.dataTransfer.files[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#dropDiv {
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0;
}

.active {
  background: #2581FA;
  opacity: 1 !important;
}
.active-end {
  background: transparent;
  opacity: 0 !important;
}
</style>
<style>
.ml-10{margin-left:10px;}</style>
