<!--
 * @Author: 建辉
 * @Date: 2021-08-05 17:01:14
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-28 21:15:58
 * @Description: 监听scrollbar
-->

<template lang="pug">
el-scrollbar.y-bar(
    ref="scrollbar"
    v-bind="$attrs"
  )
    slot
</template>

<script>
import { getElementReact } from '@/utils';
import _ from 'lodash';
export default {
  props: {
    spaceVal: {
      type: Number,
      default: 30
    }
  },
  data(){
    return {
      scrollViewHeight: 0
    }
  },
  mounted(){
    this.$nextTick(() => {
      this.fnDebounce();
      this.fnListenScroll();
    })
  },
  methods: {
    scrollToBottom(){
      const ref = this.$refs.scrollbar
      const scrollbarEl = ref.wrap
      const scrollViewBox = ref.$el.getElementsByClassName('el-scrollbar__view')[0]
      const scrollViewHeight = getElementReact(scrollViewBox).height
      const boxHeight = getElementReact(ref.$el).height;
      const leaveTop = scrollViewHeight - boxHeight;
      scrollbarEl.scrollTop = leaveTop
    },
    // 向上滚动，但是预留一些空间，用来触发下次滚动；
    scrollToSpaceTop(){
      const ref = this.$refs.scrollbar
      const scrollbarEl = ref.wrap
      const scrollViewBox = ref.$el.getElementsByClassName('el-scrollbar__view')[0]
      const scrollViewHeight = getElementReact(scrollViewBox).height
      setTimeout(() => {
        this.scrollViewHeight = scrollViewHeight
      })
      scrollbarEl.scrollTop = scrollViewHeight - this.scrollViewHeight;
    },
    fnDebounce(){
      this.listenDebounce = _.debounce(() => {
        const ref = this.$refs.scrollbar
        const scrollbarEl = ref.wrap
        const scrollViewBox = ref.$el.getElementsByClassName('el-scrollbar__view')[0]
        const boxHeight = getElementReact(ref.$el).height;
        const scrollViewHeight = getElementReact(scrollViewBox).height
        this.scrollViewHeight = scrollViewHeight;
        const scrollTop = scrollbarEl.scrollTop;
        const val = boxHeight + scrollTop + this.spaceVal
        if(val >= scrollViewHeight){
          this.$emit('scrollBottom', '滚动到底部')
          console.log('滚动到底部')
        }
        if(scrollTop <= 0){
          this.$emit('scrollTop', '滚动到顶部')
          console.log('滚动到顶部')
          console.log(scrollTop);
        }
      }, 200)
    },
    fnListenScroll(){
      const ref = this.$refs.scrollbar
      if (!ref) return
      const scrollbarEl = ref.wrap
      scrollbarEl.onscroll = this.listenDebounce;
    }
  }
}
</script>

<style>
</style>
