<!--
 * @Author: 赵伟
 * @Date: 2021-06-21 14:15:33
 * @LastEditors: 聂涛
 * @LastEditTime: 2021-07-09 18:48:52
 * @Description: 解决emoji和文字重合在一起的问题
 * 文档 https://www.h5w3.com/154573.html  https://segmentfault.com/q/1010000011020467
-->
<template lang="pug">
span(v-html="transFormText")
</template>

<script>
import { emojiReg, validEmoji } from '@/utils'
export default {
  name: 'emoji-text',
  props: {
    text: {
      type: String,
      default:''
    }
  },
  data () {
    return {
      transFormText: ''
    }
  },
  watch: {
    text: {
      handler(val){
        if(validEmoji(val)){
          this.transFormText = val.replace(emojiReg, (emoji) => {
            return `<span class="emojiText">${emoji}</span>`
          })
        }else
          this.transFormText = val || '-'
      },
      immediate: true
    }
  }
}
</script>

<style>
@media not screen and (-webkit-min-device-pixel-ratio: 2),
  not screen and (min--moz-device-pixel-ratio: 2),
  not screen and (-o-min-device-pixel-ratio: 2/1),
  not screen and (min-device-pixel-ratio: 2),
  not screen and (min-resolution: 192dpi),
  not screen and (min-resolution: 2dppx){
    .emojiText {
      margin-right: 8px;
      margin-left: 3px;
    }
  }
</style>

<style>
</style>
