<!--
 * @Author: 建辉
 * @Date: 2020-10-10 14:16:57
 * @LastEditors: 赵伟
 * @LastEditTime: 2021-06-24 10:44:09
 * @Description: 按钮权限控制
-->
<template lang="pug">
.dspl-inbl
    span(v-if="bShowSlot")
      slot
    span(v-else) -
</template>

<script>
import {mapState} from 'vuex'
import _ from 'lodash'
export default {
  name: 'fn-render-check',
  props: {
    // 按钮名称
    code: {
      type: [String, Array],
      default: "",
      require: true
    },
  },
  computed: {
    ...mapState({
      btnCodeList: state => state.user.permission && state.user.permission.btnCodeList || []
    }),
    bShowSlot(){
      // 判断是不是数组
      if(_.isArray(this.code)){
        // 为空时
        if(!this.code.length) return false;
        return this.code.some(item => this.btnCodeList.includes(item));
      }else if(_.isString(this.code)){
        if(this.btnCodeList.includes(this.code)){
          return true
        }
        return false;
      }
      return false;
    }
  }
}
</script>

<style>
</style>
