<template>
  <svg
    class="icon"
    aria-hidden="true"
    :class="[bPoint ? 'cs-pt' : '']"
    :style="{
      color: color || '',
      fontSize: size + 'px',
      width: size + 'px',
      height: size + 'px',
    }"
    @click="fnClick"
  >
    <use :href="`#icon-${icon}`"></use>
  </svg>
</template>

<script>
export default {
  name: "icon",
  props: {
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: [String, Number],
      default: "",
    },
    size: {
      type: [String, Number],
      default: 24,
    },
    bPoint: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    fnClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;
  line-height: 1;
  vertical-align: -0.15em; // for SVG icon, see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
  fill: currentColor;
  overflow: hidden;
}
</style>
