<!--
 * @Author: xiaoguang
 * @Date: 2020-09-23 11:33:23
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-10-18 15:13:43
 * @Description: 下拉按钮
-->
<template lang="pug">
el-dropdown.dropdown-wrap(placement="bottom")
  //- dropdown Icon 需要加一个元素 https://github.com/ElemeFE/element/issues/13453
  span
    icon(icon="gengduo1" :color="color" :size="size")
  el-dropdown-menu(slot='dropdown')
    slot
</template>

<script>

/****
 *
 *
 * e.g.
 *
  <dropdown-btn>
    <el-dropdown-item>
      <xh-btn type="text" name="detail">详情</xh-btn>
    </el-dropdown-item>
    <el-dropdown-item>
      <xh-btn type="text" icon="jindu">审核进度</xh-btn>
    </el-dropdown-item>
    <el-dropdown-item>
      <xh-btn type="text" icon="chehui1">撤回</xh-btn>
    </el-dropdown-item>
  </dropdown-btn>
 *
 */

import variables from '@/styles/variables.scss';

export default {
  name: 'dropdown-btn',
  props: {
    /**
     * icon 颜色
     */
    color: {
      type: String,
      default: variables.normal
    },
    /**
     * size 大小
     */
    size: {
      type: Number,
      default: 16
    },
  },

  computed: {
    variables() {
      return variables
    }
  },

  methods: {
    fnClick() {}
  }

}
</script>

<style lang="scss" scoped>
@import "~/styles/variables.scss";

::v-deep .el-button--text {
  padding: 0 8px!important;
  width: 100%;
  height: 28px;
  color: #3B455D;
  text-align: left;
  .svg-icon {
    fill: #3B455D!important;
  }
  // &:hover {
  //   color: $theme;
  // }
  &:hover .svg-icon {
    fill: $theme!important;
  }
  // 处理 xh-btn 下拉选中状态样式调整
  .icon-box {
    color: #3B455D!important;
  }
  // &:hover .icon-box {
  //   color: $theme!important;
  // }
}
::v-deep .el-dropdown-menu__item {
  padding: 0;
}
</style>



<style>
</style>
