<!--
 * @Author: 建辉
 * @Date: 2021-11-24 13:44:38
 * @LastEditors: 赵伟
 * @LastEditTime: 2022-06-08 18:05:30
 * @Description: table 组件封装
-->

<template lang="pug">
  div.pst-rlt.xh-table.ovfl-x-hd
    client-only
      vxe-table(
        type="checkbox"
        align="center"
        highlight-current-column
        highlight-current-row
        :key="`${checkedColumnList.join('')}_${columnsResizeVal}`"
        :sort-config="sortConfig"
        :id="id"
        ref="xTable"
        :data="data"
        :row-id="rowId"
        resizable
        :custom-config="customConfig"
        :checkbox-config="{ checkMethod: checkboxConfig, strict: true, reserve: true }"
        :highlight-hover-row="true"
        @resizable-change="fnHandleResizeChange"
        @sort-change="fnHanldeSortChange"
      )
        template(
          v-for="(item, i) in visibleColumns"
        )
          vxe-column(
            v-if="item.type === 'checkbox'"
            :width="`${item.resizeWidth ? item.resizeWidth + 'px' : item.width ? item.width + 'px' : '50px'}`"
            type="checkbox"
            align="left"
            :key="`${item.prop}_${item.resizeWidth}`"
          )
            //- checkbox
            template(#header="{checked, indeterminate}")
              .flex.flex-middle
                el-checkbox(
                  style="margin-right: 4px;"
                  size="medium" :indeterminate="indeterminate" :value="checked"
                  @change="fnHandleToggleAllCheckBoxRow"
                )
                transition(name="fade")
                  icon(
                    v-if="checkedList.length"
                    icon="clear1" @click.native="fnClearAllCheck" size="22")
            template(#checkbox="{row, checked, indeterminate}")
              el-checkbox(
                :value="checked"
                @change="fnHandleToggleCheckboxRow(row)"
              )
          vxe-column(
            :sortable="item.sortable || false"
            :key="`${item.prop}_${item.resizeWidth}`"
            v-else-if="item.render"
            :fixed="item.fixed ? item.fixed : ''"
            :cell-render="{name: 'render', render: item.render}"
            :field="item.prop"
            min-width="100"
            :width="`${item.resizeWidth ? item.resizeWidth + 'px' : item.width ? item.width + 'px' : ''}`"
            :title="item.label"
            )
            template(#header)
              span.mr-4 {{ item.label }}
              span(@click="showSettings = !showSettings" v-if="i === visibleColumns.length -1")
                icon(icon="settings" style="transform: translateY(2px)")
              el-tooltip(effect="dark" :content="item.headerTip" placement="top")
                span(v-if="item.headerTip")
                  icon(size="16" icon="gantan")
          vxe-column(
            :sortable="item.sortable || false"
            min-width="100"
            :key="`${item.prop}_${item.resizeWidth}`"
            :width="`${item.resizeWidth ? item.resizeWidth + 'px' : item.width ? item.width + 'px' : ''}`"
            v-else
            :field="item.prop" :title="item.label")
              template(#header)
                span.mr-4 {{ item.label }}
                el-tooltip(effect="dark" :content="item.headerTip" placement="top")
                  span(v-if="item.headerTip")
                    icon(size="16" icon="gantan")
                span(@click="showSettings = !showSettings" v-if="i === visibleColumns.length -1")
                  icon(icon="settings" style="transform: translateY(2px)")
      .py-20.text-right(v-if="pagination")
        el-pagination(
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="pageSize"
          :page-sizes="pageSizes"
          :current-page="currentPage"
          @current-change="handlePnChange"
          @size-change="fnChangeSize"
          :total="total"
        )
    .h-400.pst-absl.r-0.t-49.settings(ref="tool" style="z-index: 30;" :class="[showSettings ? 'active' : '']")
      div.pl-10.pt-10.pb-10(style="border-bottom: 1px solid #eeeeee;")
        el-checkbox(
          :value="bHasResizeColumns"
          @input="fnHandleResizeSettings"
          :disabled="!bHasResizeColumns"
        ) 恢复默认列宽
        el-checkbox(
          :value="bVisibledAllColumns"
          :disabled="bVisibledAllColumns"
          @input="fnHandleInputVisibleAllColumns"
        ) 显示所有字段
      .h-316.mt-10
        el-scrollbar.y-bar.hp-100
          .pl-10
            //- 显示所有的columns
            el-checkbox-group(v-model="checkedColumnList" @input="fnHandleInputColumn")
              div.mb-4(v-for="(item, i) in columns" :key="item.prop")
                el-checkbox(
                  :disabled="item.requiredVisible"
                  :label="item.prop") {{ item.label }}
</template>

<script>
import _ from 'lodash'
import { checkClickDomInArea } from '@/utils/client'
export default {
  components: {
  },
  props: {
    rowId: {
      type: [String, Number],
      required: true
    },
    defaultSort: {
      type: Array,
      default(){
        return []
      }
    },
    id: {
      type: [String, Number],
      required: true
    },
    pageSize: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default(){
        return [10, 20, 50, 100]
      }
    },
    currentPage: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    checkedList: {
      type: Array,
      default() {
        return []
      }
    },
    sortList: {
      type: Array,
      default(){
        return [
          // {
          //   order: 'desc', // desc asc
          //   prop: '' // 字段
          // }
        ]
      }
    },
    pagination: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default(){
        return []
      }
    },
    data: {
      type: Array,
      default(){
        return []
      }
    },
    checkboxConfig: {
      type: Function,
      default: () => {}
    }
  },
  data(){
    return {
      selfColumns: [],
      bUseDefaultLayout: false, // 使用默认布局
      showSettings: false,
      checkedColumnList: [], // 选择的列表
      customConfig: {
        storage: {
          visible: true,
          resizable: true
        }
      },
      localColumns: [],
      sortConfig:  {
        multiple: true, remote: true, orders: ['desc', 'asc']
      }
    }
  },
  computed: {
    visibleColumns(){
      return this.selfColumns.filter(item => {
        return item.visible;
      })
    },
    // 统计是否改变过列宽，根据resizeWidth字段来判断
    bHasResizeColumns(){
      return this.visibleColumns.filter(item => {
        return item.resizeWidth
      }).length > 0
    },
    columnsResizeVal(){
      return this.visibleColumns.map(item => {
        return item.resizeWidth
      }).join('_')
    },
    // 是否显示了所有的字段
    bVisibledAllColumns(){
      const originalColumnsLength = this.columns.length;
      const currentColumnsVisibleLength = this.visibleColumns.length;
      return originalColumnsLength === currentColumnsVisibleLength;
    }
  },
  beforeDestroy(){
    document.removeEventListener('mouseup', this.fnListenDomClick)
  },
  mounted(){
    this.fnInitToolSettings();
    document.addEventListener('mouseup', this.fnListenDomClick)
    this.$nextTick(() => {
      this.sortList.forEach(item => {
        this.$refs.xTable.sort(item.prop, item.order)
      })
    })
  },
  methods: {
    showTooltipMethod({ type, column, row, items, _columnIndex }){
      console.log(column, items)
      if (type === 'header') {
        return column.title ? '' + column.title : ''
      }
      // 其余的单元格使用默认行为
      return null
    },
    fnClearAllCheck(){
      const table = this.$refs.xTable
      table.clearCheckboxRow();
      table.clearCheckboxReserve();
      this.fnHandleCheckChange();
    },
    getCheckedList(){
      const list = this.$refs.xTable.getCheckboxReserveRecords()
      const list2 = this.$refs.xTable.getCheckboxRecords()
      const result = list.concat(list2)
      console.log(result)
      return result
    },
    // 初始化工具配置
    fnInitToolSettings(){
      const localKey = `local-table-tool-${this.id}`
      let parseVal = []
      try {
        const checkedColumnsList = []
        const localVal = localStorage.getItem(localKey);
        if(localVal){
          const parseLocalVal = JSON.parse(localVal)
          parseVal = parseLocalVal;
          const newColumns = this.columns.map(item => {
            const findItem = parseLocalVal.find(findItem => {
              return findItem.prop === item.prop
            })
            if(findItem){
              item.visible = findItem.visible
              item.resizeWidth = findItem.resizeWidth || ''
            } else {
              item.visible = true
              item.resizeWidth = ''
              checkedColumnsList.push(item.prop)
            }
            if(findItem && findItem.visible){
              checkedColumnsList.push(findItem.prop)
            }
            return item
          })
          this.selfColumns = newColumns
          localStorage.setItem(localKey, JSON.stringify(newColumns))
          this.checkedColumnList = checkedColumnsList

        } else {
          // 没有工具配置local
          const addPropColumns = this.columns.map(item => {
            return {
              ...item,
              visible: true,
              resizeWidth: '',
            }
          })
          this.selfColumns = addPropColumns
          const strColumns = JSON.stringify(addPropColumns);
          localStorage.setItem(localKey, strColumns)
          this.checkedColumnList = addPropColumns.map(item => {
            return item.prop
          })
        }
        if(this.checkedColumnList.length === 0){
          this.showSettings = true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    fnHandleInputVisibleAllColumns(val){
      if(val){
        // 显示所有的字段
        const localKey = `local-table-tool-${this.id}`
        const localVal = localStorage.getItem(localKey)
        const parseVal = JSON.parse(localVal)
        const newColumns = this.columns.map(item => {
          let res = {}
          const findItem = parseVal.find(parseItem => {
            return parseItem.prop === item.prop
          })
          if(findItem){
            res = {
              ...findItem,
              visible: true
            }
            res.render = item.render
          } else {
            res = {
              ...item,
              visible: true
            }
          }
          return res
        })
        this.selfColumns = newColumns;
        this.checkedColumnList = newColumns.map(item => {
          return item.prop;
        })
        localStorage.setItem(localKey, JSON.stringify(newColumns))
      }
    },
    fnHandleInputColumn(list){
      console.log(list)
      const localKey = `local-table-tool-${this.id}`
      const localVal = localStorage.getItem(localKey);
      const parseVal = JSON.parse(localVal);
      const selfColumns = this.columns.map(item => {
        const bHasKey = list.find(checkItem => {
          return checkItem === item.prop;
        })
        const localItem = parseVal.find(parseItem => {
          return parseItem.prop === item.prop;
        })
        if(bHasKey){
          return {
            ...item,
            visible: true,
            resizeWidth: localItem.resizeWidth || ''
          }
        } else {
          return {
            ...item,
            visible: false,
            resizeWidth: localItem.resizeWidth || ''
          }
        }
      })
      this.selfColumns = selfColumns
      localStorage.setItem(localKey, JSON.stringify(selfColumns))
    },
    // 同步字段显示隐藏列表
    fnSyncColumnVisibleList(){
    },
    // 获取字段显示隐藏逻辑
    fnGetColumnVisible(column){
    },
    // 获取本地缓存中的column显示隐藏字段
    fnGetLocalVisibleColumns(){
    },
    fnSetLocalVisibleColumns(opt){
    },
    fnGetLocalColumns(){

    },
    fnGetWidth(column){
      if(column.resizeWidth){
        return column.resizeWidth + 'px'
      }
      if(column.width){
        return column.width + 'px'
      }
      return ''
    },
    // 更新column的宽度，高度
    fnSetLocalVal(opt){
    },
    handlePnChange(pn){
      this.$emit('p-current-change', pn);
      this.$emit('update:currentPage', pn)
    },
    fnHandleResizeSettings(val){
      console.log(val)
      if(val === false){
        // 如果是恢复
        const newColumns = this.selfColumns.map(item => {
          return {
            ...item,
            resizeWidth: ''
          }
        })
        this.selfColumns = newColumns;
        this.$nextTick(() => {
          const table = this.$refs.xTable
          table.refreshColumn();
        })
        localStorage.setItem(`local-table-tool-${this.id}`, JSON.stringify(newColumns))
        // 同步table组件自己的逻辑
        const localTableKey = 'VXE_TABLE_CUSTOM_COLUMN_WIDTH';
        const localVal = localStorage.getItem(localTableKey)
        if(localVal){
          const parseVal = JSON.parse(localVal);
          parseVal[this.id] = {}
          localStorage.setItem(localTableKey, JSON.stringify(parseVal))
        }
      }
    },
    fnHandleResizeChange({$rowIndex, column, columnIndex, $columnIndex, $event }){
      console.log($rowIndex, column, columnIndex, $columnIndex, $event )
      const resizeWidth = column.resizeWidth;
      const width = column.width || '';
      const prop = column.property
      console.log(prop, resizeWidth)
      const newColumns = this.selfColumns.map(item => {
        if(item.prop === prop){
          item.resizeWidth = resizeWidth;
        }
        return item;
      })
      this.selfColumns = newColumns
      localStorage.setItem(`local-table-tool-${this.id}`, JSON.stringify(newColumns))
    },
    fnHandleCheckChange(e){
      setTimeout(() => {
        const result = this.getCheckedList();
        this.$emit('update:checked-list', result)
      }, 50)
    },
    fnHanldeSortChange(e){
      const tempSortList = _.cloneDeep(this.sortList)
      const property = e.property;
      const bHasProp = tempSortList.find(item => {
        return item.prop === property
      })
      // 如果已经有对应的字段排序历史，先删除，然后吧最新的放在第一个
      if(bHasProp){
        const index = tempSortList.findIndex(item => {
          return item.prop === property
        })
        tempSortList.splice(index, 1)
      }
      tempSortList.unshift({
        prop: e.property,
        order: e.order,
      })
      console.log(tempSortList)
      this.$emit('update:sortList', tempSortList)
      this.$emit('sort-change')
    },
    // 监听鼠标点击事件，点击的如果是工具栏外面，需要隐藏工具栏
    fnListenDomClick(e){
      if(e.target.tagName === 'svg') {
        return;
      }
      const toolArea = this.$refs.tool
      const bInToolArea = checkClickDomInArea(e, toolArea)
      if(!bInToolArea){
        if(this.checkedColumnList.length === 0){
          this.showSettings = true;
          return;
        }
        this.showSettings = false
      }
    },
    fnChangeSize(size){
      console.log(size)
      this.$emit('size-change', size)
    },
    // checkbox
    fnHandleToggleAllCheckBoxRow(){
      this.$refs.xTable.toggleAllCheckboxRow()
      this.fnHandleCheckChange();
    },
    fnHandleToggleCheckboxRow(row){
      this.$refs.xTable.toggleCheckboxRow(row)
      this.fnHandleCheckChange();
    }
  }
}
</script>

<style lang="scss">
@import './variable.scss';
@import 'vxe-table/styles/index.scss';
.settings {
  transition: opacity ease .3s, transform ease .3s;
  transform: translateX(250px);
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
  background: #fff;
  border-radius: 0 0 5px 5px;
  width: 200px;
  &.active {
    transform: translateX(0px);
  }
}
</style>

<style lang="scss">
.xh-table {
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    /* background-color: $theme; */
  }
  .el-pagination.is-background .el-pager li{
    min-width: 22px;
  }

  .el-pager li{
    /* border-radius: 2px; */
    /* height: 22px; */
    /* line-height: 22px; */
  }

  .el-pagination button, .el-pagination span:not([class*=suffix]) {
    /* display: inline-block;
    min-width: 22px;
    height: 22px;
    line-height: 22px; */
  }
}
</style>

<style>
.mr-4{margin-right:4px;}.py-20{padding-top:20px;padding-bottom:20px;}.h-400{height:400px;}.r-0{right:0px;}.t-49{top:49px;}.pl-10{padding-left:10px;}.pt-10{padding-top:10px;}.pb-10{padding-bottom:10px;}.h-316{height:316px;}.mt-10{margin-top:10px;}.hp-100{height:100%;}.mb-4{margin-bottom:4px;}</style>
