<!--
 * @Author: 建辉
 * @Date: 2020-09-29 10:13:07
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-07-11 15:40:41
 * @Description: enum-pick 枚举选择
-->
<template lang="pug">
div.pst-rlt.wp-100
  el-select.wp-100(
    :clearable="clearable"
    v-bind="$attrs"
    :popper-append-to-body="appendToBody"
    :value="val"
    :multiple="multiple"
    filterable
    @change="fnHandleInput" v-if="type === 'select'")
    el-option(
      v-for="(item, i) in aList"
      :key="i"
      :label="item.desc"
      :value="item.code"
      :disabled="disabledStatus.includes(item.status)"
      v-if="!exclude.includes(item.code)"
    )
      .flex.flex-middle
        img.h-30(v-if="item.avatar" :src="item.avatar")
        emoji-text(:style="{marginLeft: item.avatar ? '5px' : ''}" :text="item.desc")
</template>

<script>
import _ from 'lodash'
import {mapState} from 'vuex';
import { commonPageConfig } from '@/api';
export default {
  name: 'enum-pick',
  inheritAttrs: false,
  // multiple 多选
  props: {
    // 接口 enumtype
    // addUserList 添加人列表
    enumKey: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Array],
      default:''
    },
    // 渲染类型
    type: {
      type: String,
      default: 'select' // select radio
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    // 排除的选项
    exclude: {
      type: Array,
      default(){
        return []
      }
    },
    // 是否显示全部
    showAll: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    force: { // 是否强制刷新
      type: Boolean,
      default: false
    },
    customConfig: {
      type: Object,
      default: null
    },
    // status 判断 禁用项
    disabledStatus: {
      type: Array,
      default(){
        return []
      }
    },
  },
  data(){
    return {
      val: '',
      aList: []
    }
  },
  watch: {
    value: {
      handler(val){
        if(_.isArray(val) && val.length === 0){
          this.val = []
          this.fnBsnDealModel([])
        }else{
          this.val = val || ''
          this.fnBsnDealModel(this.val)
        }
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetList()
  },
  methods: {
    // tips: change事件会优先于input事件触发
    fnHandleInput(val){
      console.log(val)
      if(_.isArray(val) && val.length === 0){
        this.$emit('change', []);
        this.$emit('input', []);
        this.fnBsnDealModel([])
        return;
      }
      // 数组，最后一次点击是全部，显示全部
      if(_.isArray(val) && val[val.length-1] === '' && val.length > 1){
        this.$emit('change', [])
        this.$emit('input', [])
        this.fnBsnDealModel([])
      // 数组，最后一次点击不是全部，删除全部
      } else if(_.isArray(val) && val[val.length-1] !== '' && val.length > 1){
        const inputList = val.filter(item => item !== '')
        this.$emit('change', inputList)
        this.$emit('input', inputList)
        this.fnBsnDealModel(inputList)
      } else{
        this.$emit('change', val)
        this.$emit('input', val)
        const descItem = this.aList.find(item => item.code === val)
        this.$emit('update:desc', descItem && descItem.desc)
        this.fnBsnDealModel(val)
      }
    },
    async fnNetGetList() {
      const [err, data] = await commonPageConfig({
        params: {
          type: 'RFM_TAG',
          configKey: this.enumKey
        }
      })
      if (err) return
      this.aList = data
    },
    // 返回第一级别的model
    fnBsnDealModel(val){
      // 数组
      if(_.isArray(val)){
        const aTargetModels = [];
        this.aList.forEach(item => {
          val.forEach(code => {
            if(item.code === code) {
              aTargetModels.push(item)
            }
          })
        });
        this.$emit('InputModel', aTargetModels);
        this.$emit('update:model', aTargetModels);
      }else{
        const oTargetItem = this.aList.find(item => item.code === val);
        this.$emit('InputModel', oTargetItem);
        this.$emit('update:model', oTargetItem);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 神坑，因为是fixed，但是没有计算好高度，所以直接使用absolute来处理 */
/* 参考位置: https://blog.csdn.net/Hello_yihao/article/details/104557895 */
::v-deep .el-select-dropdown {
    position: absolute!important;
    top: 30px!important;
    left: 0px!important;
}
</style>

<style>
.wp-100{width:100%;}.h-30{height:30px;}</style>
