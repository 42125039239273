/*
 * @Author: 赵伟
 * @Date: 2020-08-27 10:09:17
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-03-30 15:47:36
 * @Description: file content
 */
import Vue from 'vue';
import dayjs from 'dayjs'


export const dateToDay = function(val, format = 'YYYY-MM-DD') {
  const date = dayjs(val && Number(val))
  if(date.isValid()){
    return date.format(format)
  }else {
    console.log('日期格式非法:', val)
    return ''
  }
}
export const dateFormat = function(val, format = 'YYYY-MM-DD') {
  const date = dayjs(val)
  if(date.isValid()){
    return date.format(format)
  }else {
    console.log('日期格式非法:', val)
    return ''
  }
}

// 转发时间戳为YYYY-MM-DD格式
Vue.filter('FilterTimeStampToDay', dateToDay);

// 转发时间戳为YYYY-MM-DD HH:MM:SS格式
Vue.filter('FilterTimeStampToDate', function(val) {
  const date = dayjs(val);
  if(date.isValid()){
    return date.format('YYYY-MM-DD HH:mm:ss')
  }else {
    console.log('日期格式非法:', val)
    return ''
  }
});

export const numberWithCommas = (x) => {
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x))
    x = x.replace(pattern, "$1,$2");
  return x;
}

/***
 * @param {string | number} x
 *
 * @description 123456.23 -> 123,456.23
 *
 * https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
 */
Vue.filter('FilterNumWithCommas', numberWithCommas);

// time 2020-10-20 19:20:10
Vue.filter('FilterTimeFormat', function(time){
  if(time){
    return time.split(' ')[0]
  }
  return '';
})
