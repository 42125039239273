import { render, staticRenderFns } from "./tagsGroup.vue?vue&type=template&id=ebc6805e&scoped=true&lang=pug"
import script from "./tagsGroup.vue?vue&type=script&lang=js"
export * from "./tagsGroup.vue?vue&type=script&lang=js"
import style0 from "./tagsGroup.vue?vue&type=style&index=0&id=ebc6805e&prod&lang=scss&scoped=true"
import style1 from "./tagsGroup.vue?vue&type=style&index=1&id=ebc6805e&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebc6805e",
  null
  
)

export default component.exports