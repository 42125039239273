// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuText": "#1b253a",
	"menuActiveText": "#208dfd",
	"subMenuActiveText": "#208dfd",
	"menuBg": "#fff",
	"menuHover": "#e6f3ff",
	"subMenuBg": "#fff",
	"subMenuHover": "#e6f3ff",
	"sideBarWidth": "210px",
	"theme": "#208dfd",
	"menuActiveBg": "rgba(0,208,144,.1)",
	"status1": "#0096ff",
	"themeColor": "#208dfd",
	"link": "#ff9a00",
	"warn": "#e70000",
	"normal": "rgba(0,0,0,.88)",
	"grey": "#b5b5b5",
	"boxBgColor": "#f8fafd"
};
module.exports = ___CSS_LOADER_EXPORT___;
