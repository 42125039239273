<!--
 * @Author: 建辉
 * @Date: 2020-09-23 18:50:01
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-03-17 13:58:16
 * @Description: 上传多张图片
-->
<template lang="pug">
div.upload-preview
  //- 图片显示取悦
  .pst-rlt.dspl-inbl.fs-0.vtal-md.box-muti-img.mr-12.pst-rlt(
    v-if="value.length"
    v-for="(item, i) in value"
    :key="i"
    :style="{...style, background: `url(${item}) no-repeat center center/contain`}"
  )
    .cover.hp-100.flex-center.wp-100.lh-0.flex.flex-middle.flex-center
      span.mr-5(@click="fnClickViewImg(i)")
        icon(v-if="value" icon="chakan1" :size="22" color="white")
      span(@click="fnClickDelImg(i)" v-if="!disable")
        icon(v-if="value" icon="delete" :size="22" color="white")
    //- .cover.flex.flex-center(:style="{lineHeight: height + 'px'}")
  .pst-rlt.box-muti-img.dspl-inbl.fs-0.vtal-md(
    v-if="bShowAddBtn && !disable"
    :style="style")
    //- 上传遮罩
    div.vh(
      v-if="bLoading"
      :style="style"
      )
      div.fs-22.lh-22.theme-color.mt-10
        i.el-icon-loading
        div.fs-14.fw-bold 上传中...
    //- 上传组件
    el-upload(
      v-if="!bLoading"
      :show-file-list="false"
      v-bind="$attrs"
      action="#"
      :accept="acceptVal"
      :before-upload="fnHandleBeforeUpload"
      :http-request="fnHandleHttpRequest"
    )
      div.flex.flex-middle.flex-center.pst-rlt(
        :style="style"
      )
        .action-area-add.flex.flex-middle.fs-14.color-white.lh-0
          icon(icon="jia" :size="22" :color="themeColor")
</template>

<script>
import {mapState} from 'vuex'
// import { uploadCompanyFile, uploadGhFile} from '@/api'
import { uploadFile } from '@/api';
import { uploadToOss } from '@/api/oss';


export default {
  props: {
    value: {
      type: [Array, String],
      default(){
        return []
      }
    },
    width: {
      type: String,
      default: '94'
    },
    disable: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '60'
    },
    max: {
      type: [Number, String],
      default: ''
    },
    waterMarkFlag: {
      type: Boolean,
      default: true // 默认需要水印
    },
    fileStorageType: {
      type: String,
      default: '' // OSS 上传到oss
    },
    fileTypeName:{
      type:String,
      default:''
    },
    oss: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      bLoading: false,
      themeColor: process.env.THEME_COLOR || '#2581FA',
      isHover: false,
      accept: 'img',
      file: null
    }
  },
  computed: {
    ...mapState({
      roles: state => state.permission.roles
    }),
    style(){
      return {
        width: this.width + 'px',
        height: this.height + 'px',
        lineHeight: this.height + 'px',
        textAlign: 'center',
        marginBottom: '10px'
      }
    },
    acceptVal(){
      // 常用MIME类型 https://www.w3cschool.cn/htmltags/att-input-accept.html
      const acceptMap = {
        img: 'image/*',
        xsl: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        pdf: 'application/pdf',
        audio: 'audio/*',
        video: 'video/*',
        zip: 'aplication/zip',
      }
      return acceptMap[this.accept]
    },
    bShowAddBtn(){
      if(this.max){
        if(this.value && this.value.length >= this.max){
          return false;
        }else{
          return true;
        }
      }
      return true;
    }
  },
  methods: {
    fnHnadleChangeHover(val){
      this.isHover = val
    },
    fnClickViewImg(index){
      this.$preview({
        list: [this.value],
        index
      })
    },
    fnHandleBeforeUpload(file){
      this.file = file
    },
    async fnHandleHttpRequest(){
      if(this.bLoading) {
        this.$message.warning('请稍后...')
        return;
      }
      console.log(this.file)
      // 校验文件格式
      const reg = /^.*\.(?:jpg|jpeg|png)$/i;
      if(!reg.test(this.file.name)){
        this.$message.warning('请上传.jpg/.png格式的文件')
        return;
      }
      if(this.file.size > 10 * 1024 * 1024){
        this.$message.warning('文件最大为10M，请检查后重新上传')
        return;
      }
      this.bLoading = true;
      let result = null
      if (this.oss) {
        result = await uploadToOss(this.file)
      } else {
        result = await uploadFile({
          data: {
            file: this.file,
            waterMarkFlag: this.waterMarkFlag,
            fileStorageType: this.fileStorageType,
            fileTypeName: this.fileTypeName
          }
        })
      }
      const [err, data] = result
      this.bLoading = false;
      if(err) return;
      if (this.oss) {
        this.$emit('input', [...this.value, data.url])
      } else {
        this.$emit('input', [...this.value, data])
      }
      const parentVm = this.$parent.$parent;
      // 清空校验选项
      if(parentVm && parentVm.resetField){
        parentVm.clearValidate();
      }
      return data;
    },
    fnClickDelImg(i){
      // this.$emit('input', '')
      // eslint-disable-next-line
      this.value.splice(i, 1)
      this.$emit('input', [...this.value])
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.cover {
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  opacity: 0;
  transition: all ease .3s;
}
.box-muti-img {
  border-radius: 5px;
  border: 1px solid #E0E6ED;
  overflow: hidden;
  box-sizing: content-box;
  &:hover {
    border: 1px solid $theme;
  }
  &:hover .cover {
    opacity: 1;
    background: rgba(0,0,0,0.5);
  }
}
</style>

<style>
.fs-0{font-size:0px;}.mr-12{margin-right:12px;}.hp-100{height:100%;}.wp-100{width:100%;}.lh-0{line-height:0px;}.mr-5{margin-right:5px;}.fs-22{font-size:22px;}.lh-22{line-height:22px;}.mt-10{margin-top:10px;}.fs-14{font-size:14px;}</style>
