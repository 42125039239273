<!--
 * @Author: 聂涛
 * @Date: 2021-06-17 21:31:10
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-07-04 14:39:30
 * @Description: 可以换行的标签
-->

<template lang="pug">
div(class="tag") {{content}}
  slot
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/styles/variables.scss";

.tag{
  margin-bottom: 8px;
  border-radius: 2px;
  padding: 5px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E6FFFF;
  background-color: $subMenuHover;
}
</style>
<style>
</style>
