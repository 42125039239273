/*
 * @Author: 建辉
 * @Date: 2021-05-10 15:49:32
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-21 14:08:16
 * @Description: file content
 */

import { request } from './http'

// 获取角色列表
export const fetchRoles = (options) =>
  request('post', '/hms/core/role/list', options)

// 供枚举使用的角色列表
export const fetchRolesEnum = async () => {
  const [err, data] = await request('post', '/hms/core/role/list', {
    data: {
      current: 1,
      condition: {name: ''},
      size: 1000
    }
  });
  if(err) return [err, data];
  if(!data.records) data.records = []
  data.records = data.records.map(item => {
    return {
      ...item,
      code: item.roleId,
      desc: item.name,
      originCode: item.code
    }
  })
  return [err, data.records]
}


// 获取角色详情
export const fetchRoleDetail = (options) =>
  request('post', '/hms/core/role/roleInfo/:roleId', options)

// 删除角色
export const deleteRole = (options) =>
  request('post', '/hms/core/role/remove/:roleId', options)

// 更新角色
export const updateRole = (options) =>
  request('post', '/hms/core/role/edit', options)

// 添加角色
export const addRole = (options) =>
  request('post', '/hms/core/role/add', options)

// 查询角色下面的用户列表
export const fetchRoleUserList = (options) =>
  request('post', '/hms/core/role/userList', options)

// 获取所有的功能tree
export const fetchAllFunctionTree = (options) =>
  request('post', '/hms/core/function/tree', options)

// 获取所有的菜单列表
export const fetchAllMenuTree = (options) =>
  request('post', '/hms/gh/core/user/menu/tree', options)

// 添加菜单
export const addMenuItem = (options) =>
  request('post', '/hms/gh/admin/menu/save', options)
// 更新菜单
export const updateMenuItem = (options) =>
  request('post', '/hms/gh/admin/menu/update', options)
// 删除菜单
export const delMenuItem = (options) =>
  request('post', '/hms/gh/admin/menu/batchDel', options)

// 删除某个功能点，菜单，按钮权限等
export const delFuctionItemWithId = (options) =>
  request('post', '/hms/gh/admin/function/batchDel', options)

// 获取登录用户的按钮权限
export const fetchUserFunctionTree = (options) =>
  request('post', '/hms/gh/core/user/function/tree', options)

// 更新功能点，菜单，按钮权限等
export const updateFuctionItemWithId = (options) =>
  request('post', '/hms/gh/admin/function/update', options)

// add功能点，菜单，按钮权限等
export const addFunctionItem = (options) =>
  request('post', '/hms/gh/admin/function/add', options)

// role all
export const reqRoleAll = options => {
  return request('post', '/hms/gh/admin/role/all', options)
}

// role list
export const reqRoleList = options => {
  return request('post', '/hms/gh/admin/role/list', options)
}

// 角色下面的菜单
export const reqRoleMenuTree = options => {
  return request('post', '/hms/core/function/:roleId', options)
}

// 获取角色所有的数据权限 http://yapi.fostars.com/project/27/interface/api/3607
export const reqRoleDataAccessList = options => {
  return request('post', '/hms/gh/admin/role/function/queryFunction', options)
}
// 获取数据权限枚举列表 从功能中拿出来
export const reqRoleDataAccessListPickFun = options => {
  return request('post', '/hms/core/function/dataAccess/:roleId', options)
}
// update 角色权限
export const reqURoleMenu = options => {
  return request('post', '/hms/core/function/update', options)

}
// 获取未添加角色的用户列表
export const fetchUserWithOutRole = options => {
  return request('post', '/hms/core/role/without/user/list', options)
}

// 给角色批量添加用户
export const addRoleBindUser = options => {
  return request('post', '/hms/core/role/batch/user/save', options)
}
// fetch 组织tree
export const fetchOrgTree = async options => {
  return await request('post', `/hms/core/org/tree`, options)
}
// 用户所在公司的组织机构树，给权限管理模块用
export const fetchUnionOrgTreeWithUser = options => {
  return request('post', '/hms/gh/core/user/org', options)
}

// 获取企业org tree
export const fetchEntOrgTree = options => {
  return request('post', '/hms/ent/admin/org/all/tree', options)
}

// fetch 组织列表
export const reqOrgList = options => {
  return request('post', '/hms/gh/admin/org/list', options)
}

// 组织相关api http://yapi.fostars.com/project/27/interface/api/cat_688
// R  组织详情
export const reqROrg = options => {
  return request('post', '/hms/core/org/orgInfo/:orgId', options)
}
// C 组织
export const reqCOrg = options => {
  return request('post', '/hms/core/org/add', options)
}
// U 组织
export const reqUOrg = options => {
  return request('post', '/hms/core/org/edit', options)
}
// D 组织
export const reqDOrg = options => {
  return request('post', '/hms/core/org/removeRole/:orgId', options)
}
// R  组织用户list
export const reqROrgUserList = options => {
  return request('post', '/hms/core/user/list', options)
}
// 修改用户密码
export const changeUserPassword = options => {
  return request('post', '/hms/core/user/resetPassword', options)
}

// 用户相关api http://yapi.fostars.com/project/27/interface/api/cat_693
// C 用户 by role
export const reqCUserByRole = options => {
  return request('post', '/hms/gh/admin/user/withRole/add', options)
}
// U 用户 by roles
export const reqUUserByRole = options => {
  return request('post', '/hms/gh/admin/user/withRole/update', options)
}
// U 用户状态，启用/禁用
export const reqUUserState = options => {
  return request('post', '/hms/gh/admin/user/state/update', options)
}
// Del 用户
export const deleteUser = options => {
  return request('post', '/hms/core/user/remove/:userId', options)
}
// R 用户
export const reqRUserList = options => {
  return request('post', '/hms/gh/admin/user/list', options)
}
// 根据用户id获取详情
export const reqRUserDetailForId = options => {
  return request('post', '/hms/gh/admin/user/detail', options)
}
// 获取字典列表
export const fetchDictTypeList = options => {
  return request('post', '/hms/gh/admin/dict/type/list', options)
}

// 获取功能权限
export const fetchFunctionAccessData = options => {
  return request('post', '/hms/gh/admin/function/role/dataAccess', options)
}
// 更新功能权限
export const updateFunctionAccessData = options => {
  return request('post', '/hms/core/function/dataAccess/edit', options)
}

// 获取配置信息
export const fetchGhConfig = options => {
  return request('post', '/hms/gh/union/common/pageSystemConfig', options)
}
// 获取配置信息
export const fetchEntConfig = options => {
  return request('post', '/hms/ent/enterprise/common/pageSystemConfig', options)
}

