/*
 * @Author: 聂涛
 * @Date: 2022-04-15 20:39:10
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-07 16:36:32
 * @Description:
 */
// 全局注册element ui组件
import Vue from 'vue';
import Element from 'element-ui';
import { getMessageOpt } from '@/utils/get-message-opt';
import '@/styles/theme/index.css'
import '@/styles/index.scss'
Vue.use(Element, { size: 'small', zIndex: 4000 });


// 修改message 默认值
Vue.prototype.$message = {
  warning (options) {
    setTimeout(() => {
      const opt = getMessageOpt.getOptions(options)
      return Element.Message.warning(opt)
    })
  },
  success (options) {
    setTimeout(() => {
      const opt = getMessageOpt.getOptions(options)
      return Element.Message.success(opt)
    })
  },
  error (options) {
    setTimeout(() => {
      const opt = getMessageOpt.getOptions(options)
      return Element.Message.error(opt)
    })
  },
  info (options) {
    setTimeout(() => {
      const opt = getMessageOpt.getOptions(options)
      return Element.Message.info(opt)
    })
  }
}
