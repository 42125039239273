<!--
 * @Author: 建辉
 * @Date: 2021-04-27 14:40:51
 * @LastEditors: 建辉
 * @LastEditTime: 2021-04-27 14:42:14
 * @Description: 输入银行卡号限制最多19位
-->
<template lang="pug">
el-input(
  v-bind="$attrsAll"
  v-on="$listenersAll"
  @input="fnInput"
  v-model.trim="val"
)
</template>

<script>
export default {
  name: 'input-bank-id',
  props: {
    value: {
      type: [String],
      default: "",
    },
  },
  data() {
    return {
      val: "",
    };
  },
  computed: {
    $attrsAll() {
      return {
        ...this.$attrs,
      };
    },
    $listenersAll() {
      return {
        ...this.$listeners,
      };
    },
  },
  watch: {
    value: {
      handler(val) {
        this.fnBsnSetVal(val);
      },
      immediate: true,
    },
  },

  methods: {
    fnInput(val) {
      this.fnBsnSetVal(val);
    },
    fnBsnSetVal(val = "") {
      let sVal = val.replace(/[^0-9]/gi, "");
      if (sVal.length > 19) sVal = sVal.substring(0, 19)
      this.val = sVal;
      this.$emit("input", this.val);
    },
  },
};
</script>

<style>
</style>
