<!--
 * @Author: 聂涛
 * @Date: 2022-06-08 15:02:28
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-04-01 18:02:36
 * @Description: 素材弹窗
-->
<template lang="pug">
el-dialog(
    :visible="show"
    top="5vh"
    width="920px"
    :append-to-body="true"
    :showClose="true"
     @close="fnClickClose"
    center
  )
    .flex.flex-middle.px-30
      el-input.w-300(
        prefix-icon="el-icon-search"
        placeholder="请输入关键字或素材标题"
        v-model="form.name"
        @input="fnClickSearch"
        clearable
      )
      fn-render(code="sys-material-manage-btn")
        xh-btn.ml-15(type="primary" @click="fnClickGoMaterialManager") 去素材管理
    .flex.flex-between.mt-20.px-30
      .flex
        select-tag.mr-15.flex-shrink-0(clearable v-model="form.tagList" tagCate="MATERIAL"  multiple :checkStrictly="false" placeholder="请选择标签" @input="fnClickSearch")
        enum-pick.mr-15(clearable enumKey="SelectDateTypeEnum"  v-model="form.dateType" placeholder="请选择时间" @input="fnClickSearch")
        enum-pick.mr-15(clearable enumKey="ChatScrmMaterialTypeEnum" v-model="form.type"  placeholder="请选择类型" @input="fnClickSearch")
        enum-pick.mr-15(clearable enumKey="MaterialScopeQueryEnum" v-model="form.scopeType" placeholder="请选择范围" @input="fnClickSearch")
      .flex.flex-shrink-0.flex-middle
        xh-icon.mr-10(icon="tupianliebiao" :class="layout === 'column' ? 'theme-color':'' " size="22" @click.native="fnClickLayout('column')")
        xh-icon(icon="caidan" size="22" :class="layout === 'row' ? 'theme-color':''" @click.native="fnClickLayout('row')")
    .flex.mt-20.pl-20(style="border-top:  2px solid #f5f7fa; ")
      .w-200.flex-shrink-0.p-10(style="border-right:  2px solid #f5f7fa;")
        el-scrollbar.y-bar(
          style="height: 60vh;"
        )
          CateTree.mt-10.y-bar(
            :id="repoCode"
            :form="form"
            :edit="false"
            :inChat="inChat"
            @select-tree="fnSelectTree"
          )
      .mt-15.ml-15(
        style="overflow: auto;height: 60vh;min-width: calc(920px - 290px);"
      )
        .flex.flex-wrap.flex-between(
          v-if="data && data.length"
          v-loading="loading"
        )
          MaterialItem.mr-10.mb-10(
            :class=" [layout === 'row'  ? 'w-full' : 'w-310',  checked === item ? 'active' : '' ] "
            style="height: fit-content;"
            v-for="(item, idx) in data"
            :key="idx"
            :data="item"
            :layout="layout"
            :inDialog="true"
            @read-item="fnSelectChecked(item)"
          )
        .text-center.p-30(v-else)
          span 暂无数据
    .text-right.pt-20.top-1.pr-30.flex.flex-right
      el-pagination(
        style="text-align: right;"
        background
        :hide-on-single-page="true"
        layout="total,prev, pager, next"
        :total="total"
        :page-size="form.size"
        @current-change="handleCurrentChange"
      )
      xh-btn.ml-15(type="primary" :disabled="!checked" @click="fnClickSend" :loading="sendLoading") {{sTitle}}

</template>

<script>
import CateTree from '@/components-pages/content-manager/material-manager/categroy-tree.vue'
import MaterialItem from "@/components-pages/content-manager/material-item/index.vue"
import { materialPageChat, materialRepoCode } from '@/api'
import { createTxtMsg, EventBus, getFileName, randomString } from '@/utils'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  components: {
    CateTree,
    MaterialItem,
  },
  props: {
    show: {
      type: Boolean,
      default: true
    },
    inChat: {
      type: Boolean,
      default: false
    },
    sTitle: {
      type: String,
      default: '发送'
    }
  },
  data() {
    return {
      layout: 'column',
      repoCode: "",
      loading: true,
      sendLoading: false,
      checked: null,
      cateCode: '', // 所属分类Code
      total: 0,
      current: 0,
      form: {
        size: 30,
        dateType: '', // 时间类型
        name: '', // 素材名
        scopeType: '', // 域范围
			  tagList: [],
			  type: "" // 素材类型
      },
      data: []
    }
  },
  computed: {
    ...mapState({
      wxID: state => state.chat.currentCoversationId,
      isTransfer: state => state.user.isTransfer,
      batchOperationGroup: state => state.chat.batchOperationGroup,
    }),
  },
  watch: {
    show: {
      handler(val) {
        if (val) this.fnClickSearch()
      }
    }
  },

  mounted() {
    this.fnGetmaterialRepoCode()
  },
  methods: {
    fnClickClose() {
      this.$emit('update:show', false)
      this.checked = null
      this.form = {
        size: 30,
        dateType: '', // 时间类型
        name: '', // 素材名
        scopeType: this.repoCode === 'CONVERT_REPO' ? 'PRIVATE' : '', // 域范围
			  tagList: [],
			  type: "" // 素材类型
      }
    },
    async fnGetmaterialRepoCode() {
      const [err, data] = await materialRepoCode()
      if (err) return
      this.repoCode = data
      if (this.repoCode === 'CONVERT_REPO') {
        this.form.scopeType = 'PRIVATE'
      }
    },
    fnClickGoMaterialManager() {
      window.open('/content-manager/material-manager/list', '_blank')
    },
    fnSelectTree(node) {
      // debugger
      // this.form.cateCode = node.id
      this.cateCode = node.id
      this.current = 0
      this.fnNetMaterialPage()
    },
    fnClickSearch() {
      this.fnNetMaterialPage()
    },
    async fnNetMaterialPage() {
      this.loading = true
      const { size, ...args } = _.cloneDeep(this.form)
      const [err, data] = await materialPageChat({
        data: {
          current: this.current,
          size,
          byCondition: {
            ...args,
            cateCode: this.cateCode,
            repoCode: this.repoCode,
          },
        }
      })
      this.loading = false
      if (err) return
      this.data = data.records || []
      this.total = data.total
    },
    fnClickLayout(layout) {
      this.layout = layout
    },
    fnSelectChecked( item) {
      this.checked = item
    },
    fnTaskModel(msgType) {
      switch (msgType) {
      case 'txt':
      case 'knowledge':
      case 'img':
      case 'file':
      case 'mp4':
      case 'mp3':
        {
          const msg = {
            content: this.checked.content,
            msgType
          }
          EventBus.$emit('business-plan-task-add', msg)
        }
        break;
      case 'H5':
        {
          const msg = {
            linkTitle: this.checked.linkTitle,
            linkDesc: this.checked.linkDesc,
            linkUrl: this.checked.linkUrl,
            linkIconUrl: this.checked.linkIconUrl,
            msgType
          }
          EventBus.$emit('business-plan-task-add', msg)
        }
        break;
      case 'sph':
      case 'sphlive':
        {
          const msg = {
            avatar: this.checked.avatar,
            coverUrl: this.checked.coverUrl,
            desc: this.checked.desc,
            extras: this.checked.extras,
            feedType: this.checked.feedType,
            nickname: this.checked.nickname,
            ossUrl: this.checked.ossUrl,
            thumbUrl: this.checked.thumbUrl,
            url: this.checked.url,
            content: null,
            msgType
          }
          EventBus.$emit('business-plan-task-add', msg)
        }
        break;
      default: {
        this.$message.warning('该类型不支持发送')
      }
      }
      this.fnClickClose()
    },
    handleCurrentChange(current) {
      this.current = current
      this.fnNetMaterialPage()
    },
    async fnClickSend() {
      this.sendLoading = true
      const msgType = this.checked.msgType
      console.log(this.checked);
      const msg = {}
      if(!this.inChat) {
        this.fnTaskModel(msgType)
        this.sendLoading = false
        return
      }
      switch (msgType) {
      case 'txt':
      case 'knowledge':
        EventBus.$emit('quill-insert-text', this.checked.content)
        break;
      case 'img':
        EventBus.$emit('quill-insert-file', {
          content: this.checked.content,
          type: 'image'
        })
        break;
      case 'file':
      case 'mp4':
      case 'mp3':
      case 'H5':
      case 'sph':
      case 'sapp':
      case 'sphlive':
        {
          if(!this.wxID && !this.batchOperationGroup) {
            this.sendLoading = false
            this.$message.warning('先选择发送对象')
            return
          }
          let content = this.checked.content
          if (msgType === 'H5') {
            content = JSON.stringify({
              title: this.checked.linkTitle,
              description: this.checked.linkDesc,
              linkUrl: this.checked.linkUrl,
              imageUrl: this.checked.linkIconUrl
            })
          } else if (msgType === 'sph' || msgType === 'sphlive' ) {
            content = JSON.stringify({
              avatar: this.checked.avatar,
              coverUrl: this.checked.coverUrl,
              desc: this.checked.desc,
              extras: this.checked.extras,
              feedType: this.checked.feedType,
              nickname: this.checked.nickname,
              ossUrl: this.checked.ossUrl,
              thumbUrl: this.checked.thumbUrl,
              url: this.checked.url
            })
          }
          const params = {
            type: msgType,
            content,
            sender: '',
            img: 'https://i-static.ygibao.com/qkt/img/logo.png', // TODO 头像
            receiverId: this.wxID,
            time: Date.now(),
            isSend: 1,
            uuId: randomString()
          }
          if (this.batchOperationGroup?.id){
            const [err, data] = await this.$store.dispatch('chat/sendMsgByGroup', {
              ...params,
              contactGroupId: this.batchOperationGroup.id
            })
            this.sendLoading = false
            if (err) return
            this.$message.success('发送成功')
          } else {
            const msgInfo = createTxtMsg(params)
            this.$store.commit('chat/socketSend', msgInfo)
          }
        }
        break;
      default:
        break;
      }
      this.sendLoading = false
      this.fnClickClose()
    }
  },
}
</script>

<style lang="scss" scoped>
.w-full {
  width: 100%;
}
.w-310 {
  width: 215px;
}
.active {
  border:  1px solid #2581FA;
}
.top-1 {
  border-top: 1px solid #E4E7ED;
}
::v-deep .el-dialog--center .el-dialog__body {
  padding: 0 0 20px 0;
}
</style>

<style>
.px-30{padding-left:30px;padding-right:30px;}.w-300{width:300px;}.ml-15{margin-left:15px;}.mt-20{margin-top:20px;}.mr-15{margin-right:15px;}.mr-10{margin-right:10px;}.pl-20{padding-left:20px;}.w-200{width:200px;}.p-10{padding:10px;}.mt-10{margin-top:10px;}.mt-15{margin-top:15px;}.mb-10{margin-bottom:10px;}.p-30{padding:30px;}.pt-20{padding-top:20px;}.pr-30{padding-right:30px;}</style>
