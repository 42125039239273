import {request} from "@/api/http";

export const fetchAgentOrgTree = async options => {
  return await request('post', `/hms/agent/org/tree`, options)
}

// 组织详情
export const reqRAgentOrg = options => {
  return request('post', '/hms/agent/org/orgInfo/:orgId', options)
}
// C 组织
export const reqCAgentOrg = options => {
  return request('post', '/hms/agent/org/add', options)
}
// U 组织
export const reqUAgentOrg = options => {
  return request('post', '/hms/agent/org/edit', options)
}
// D 组织
export const reqDAgentOrg = options => {
  return request('post', '/hms/agent/org/removeOrg/:orgId', options)
}
// R  组织用户list
export const reqRAgentOrgUserList = options => {
  return request('post', '/hms/agent/user/list', options)
}

// 删除用户
export const removeAgentUserId = options => {
  return request('post', '/hms/agent/user/remove/:userId', options)
}

// 更改用户状态
export const switchAgentState = options => {
  return request('post', '/hms/agent/user/switchState', options)
}

// 获取用户详情
export const getAgentUserInfo = options => {
  return request('post', '/hms/agent/user/info/:userId', options)
}

// 修改用户信息
export const updateAgentUserInfo = options => {
  return request('post', '/hms/agent/user/edit', options)
}

// 修改用户信息
export const createAgentUserInfo = options => {
  return request('post', '/hms/agent/user/add', options)
}
