<!--
 * @Author: 建辉
 * @Date: 2020-09-23 18:50:01
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-11-04 15:49:11
 * @Description: 上传单张图片
-->
<template lang="pug">
.dspl-inbl.fs-0.vtal-md(:style="style")
  .pst-rlt.wp-100.hp-100.box-single-img
    div.fs-22.lh-22.theme-color.vh(v-if="bLoading")
      i.el-icon-loading
      div.fs-14.fw-bold 上传中...
    el-upload(
      v-else-if="!value"
      :show-file-list="false"
      v-bind="$attrs"
      action="#"
      :before-upload="fnHandleBeforeUpload"
      :http-request="fnHandleHttpRequest"
    )
      div.flex.flex-middle.flex-center.pst-rlt.fs-0(
        :style="style"
      )
        .action-area-add.flex.flex-middle.fs-14.color-white.lh-0
          icon.theme-color(icon="tianjia2" :size="22")
    .pst-absl.l-0.r-0.b-0.t-0(v-else)
      .cover
      .action-area.flex.flex-middle
        div.flex.flex-middle.w-25.h-25(@click="fnClickViewImg")
          icon.color-white(v-if="value" icon="chakan1" :size="22")
        div.flex.flex-middle.w-25.h-25(@click="fnClickDelImg")
          icon.color-white(v-if="value" icon="delete" :size="22")
      .wp-100.hp-100.flex.flex-middle.flex-center
        img.img-preview(v-if="value" :src="value" class="avatar")
</template>

<script>
import {mapState} from 'vuex'
// import { uploadCompanyFile, uploadGhFile} from '@/api'
import { uploadFile } from '@/api';
import { uploadToOss } from '@/api/oss';
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '120'
    },
    height: {
      type: String,
      default: '120'
    },
    waterMarkFlag: {
      type: Boolean,
      default: true // 默认需要水印
    },
    fileStorageType: { // 是否要上传到oss
      type: String,
      default: '' // OSS
    },
    fileTypeName:{
      type:String,
      default:''
    },
    oss: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      bLoading: false,
      isHover: false,
    }
  },
  computed: {
    style(){
      return {
        width: this.width + 'px',
        height: this.height + 'px',
        textAlign: 'center',
      }
    },
    ...mapState({
      roles: state => state.permission.roles
    })
  },
  methods: {
    fnHandleBeforeUpload(file){
      this.file = file
    },
    async fnHandleHttpRequest(){
      if(this.bLoading) {
        this.$message.warning('请稍后...')
        return;
      }
      // 校验文件格式
      const reg = /^.*\.(?:jpg|jpeg|png)$/i;
      if(!reg.test(this.file.name)){
        this.$message.warning('请上传.jpg/.png格式的文件')
        return;
      }
      if(this.file.size > 10 * 1024 * 1024){
        this.$message.warning('文件最大为10M，请检查后重新上传')
        return;
      }
      // const sReq = this.roles === 'union' ? uploadGhFile : uploadCompanyFile
      this.bLoading = true;
      let result = null
      if (this.oss) {
        result = await uploadToOss(this.file)
      } else {
        result = await uploadFile({
          data: {
            file: this.file,
            waterMarkFlag: this.waterMarkFlag,
            fileStorageType: this.fileStorageType,
            fileTypeName: this.fileTypeName
          }
        })
      }
      const [err, data] = result
      this.bLoading = false;
      if(err) return;
      const parentVm = this.$parent.$parent;
      // 清空校验选项
      if(parentVm && parentVm.resetField){
        parentVm.clearValidate();
      }
      this.$emit('input', this.oss ? data.url : data)
    },
    fnHnadleChangeHover(val){
      this.isHover = val
    },
    fnClickViewImg(){
      this.$preview({
        list: [this.value],
        index: 0
      })
    },
    fnClickDelImg(){
      this.$emit('input', '')
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';
.box-single-img {
  box-sizing: content-box;
  border: 1px solid #E0E6ED;
  border-radius: 5px;
  overflow: hidden;
  background-color: #F5F7FA;
  .action-area{
    transition: all ease-out .2s;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }
  .cover {
    background: rgba(0,0,0,0.7);
    position: absolute;
    transition: all ease-out .2s;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    opacity: 0;
  }
  &:hover{
    border: 1px solid $themeColor;
    .action-area {
      opacity: 1;
    }
    .cover {
      opacity: 1;
    }
  }
  .img-preview{
    max-width: 100%;
    max-height: 100%;
  }
}
</style>

<style>
.fs-0{font-size:0px;}.wp-100{width:100%;}.hp-100{height:100%;}.fs-22{font-size:22px;}.lh-22{line-height:22px;}.fs-14{font-size:14px;}.lh-0{line-height:0px;}.l-0{left:0px;}.r-0{right:0px;}.b-0{bottom:0px;}.t-0{top:0px;}.w-25{width:25px;}.h-25{height:25px;}</style>
