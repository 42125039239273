<!--
 * @Author: 建辉
 * @Date: 2021-06-01 15:58:34
 * @LastEditors: 建辉
 * @LastEditTime: 2021-07-01 11:25:28
 * @Description: qwt 依赖
-->
<template lang="pug">
div.m-auto.font-default-color.ovfl-x-hidden(
  style="max-width: 750px;min-height: 100vh;background: #ffffff;width: 100vw;overflow-x: hidden;"
)
  transition(name="fade-transform")
    nuxt(:key="$route.fullpath")
</template>


<script>
export default {
  head: {
    link: [
      // { rel: 'stylesheet', type: 'text/css', href: `https://res.wx.qq.com/open/libs/weui/1.1.4/weui-for-work.min.css` },
    ],
    script: [
      { src: '//res.wx.qq.com/open/js/jweixin-1.2.0.js' },
      { src: '//open.work.weixin.qq.com/wwopen/js/jwxwork-1.0.0.js' },
    ],
  },
}
</script>

<style>
</style>
