/*
 * @Author: 聂涛
 * @Date: 2022-08-11 17:28:02
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-08-24 20:02:38
 * @Description: 录音功能
 */

import { uploadToOss } from "@/utils/record";

export class Recorder {
  rec = null
  recBlob = null
  content = null
  type = null
  sampleRate = 0
  bitRate = 0
  constructor(type = 'mp3', sampleRate = 44100, bitRate = 64) {
    // mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；
    // 注意:是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
    this.type = type
    this.sampleRate = sampleRate
    this.bitRate = bitRate
  }

  init() {
    this.rec = window.Recorder({
      type: this.type,
      sampleRate: this.sampleRate,
      // 围48000, 44100, 32000, 24000, 22050, 16000, 12000, 11025, 8000
      bitRate: this.bitRate,
      onProcess(buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd){
        // // 录音实时回调，大约1秒调用12次本回调
        // document.querySelector(".recpowerx").style.width=powerLevel+"%";
        // document.querySelector(".recpowert").textContent=formatMs(bufferDuration, 1)+" / "+powerLevel;
        // // 可视化图形绘制
        // wave.input(buffers[buffers.length-1], powerLevel, bufferSampleRate);
      }
    })
  }

  // 打开了录音后才能进行start、stop调用
  recStart(){
    if (!this.rec) return
    this.rec.open( () => {
      // 打开麦克风授权获得相关资源
      if(this.rec && window.Recorder.IsOpen()){
        this.rec.start();
      }
    }, (msg, isUserNotAllow) => {// 用户拒绝未授权或不支持
      this.$message.error('用户拒绝未授权或不支持')
    });
  }

  recStop(callBack){
    if(!(this.rec&& window.Recorder.IsOpen())){
      if (callBack) {
        callBack(['', null])
      }
      return;
    };
    this.rec.stop((blob, duration) => {
      console.log("已录制mp3:"+this.formatMs(duration)+"ms "+blob.size+"字节，可以点击播放、上传了", 2);
      const content = (window.URL).createObjectURL(blob)
      if (callBack) {
        callBack([null, {
          blob,
          content
        }])
      }
    }, (msg) => {
    });
  }

  formatMs(ms, all){
    const f=Math.floor(ms/60000); const m=Math.floor(ms/1000)%60;
    const s=(all||f>0?(f<10?"0":"")+f+":":"")
  +(all||f>0||m>0?("0"+m).substr(-2)+"″":"")
  +("00"+ms%1000).substr(-3);
    return s;
  }

}
