<!--
 * @Author: 赵伟
 * @Date: 2020-11-12 17:44:47
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-06-20 11:25:14
 * @Description: 404
-->
<template lang="pug">
.box.bg-white
    .content.flex.flex-middle.flex-center.flex-column
      img.w-397(src="@/assets/images/404.png")
      .mt-40.fs-54(style='color: #8492A6')
        span 啊呀，页面找不到了…
      .mt-37.fs-18.text-center(style="line-height: 1.5em;color: #8492A6;")
        div 链接可能有误或已失效
        div
          span 请检查链接重试
          span(v-if="this.notIndex * 1 !== 1") 或者
          span.cs-pt(
            v-if="this.notIndex * 1 !== 1"
            @click="fnClickGoIndex"
            style="color: #00CF90") 返回首页
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },
  props: {
    notIndex: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
  },
  methods: {
    fnClickGoIndex(){
      this.$router.replace(`/`)
    }
  }
}
</script>


<style lang="scss" scoped>
  .box {
    height: 100vh;
  }
  .content{
    height: calc(100vh - 61px);
  }
</style>

<style>
.w-397{width:397px;}.mt-40{margin-top:40px;}.fs-54{font-size:54px;}.mt-37{margin-top:37px;}.fs-18{font-size:18px;}</style>
