/*
 * @Author: 建辉
 * @Date: 2021-05-13 13:47:52
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-01-30 14:01:37
 * @Description: file content
 */
import { request } from './http'


/* ========== 企微配置 ============ */
// 查询企微配置列表
export const getQywxSettingList = options => {
  return request('post', '/hms/sys/qywx/corp/list', options)
}

export const getQywxSettingListEnum = async options => {
  const [err, data] = await request('post', '/hms/sys/qywx/corp/list', options)
  if(err) return [err, data];
  const result = data.map(item => {
    return {
      ...item,
      desc: item.corpName,
      code: item.corpId
    }
  })
  return [err, result]
}

// 查询企微配置详情
export const getQywxInfo = options => {
  return request('post', '/hms/sys/qywx/corp/info/:corpId', options)
}

// 新增企微配置信息
export const addQywxCrop = options => {
  return request('post', '/hms/sys/qywx/corp/add', options)
}

// 测试企微配置信息
export const testQywxCrop = options => {
  return request('post', '/hms/sys/qywx/corp/test/:corpId', options)
}

// 修改企微配置信息
export const editQywxCrop = options => {
  return request('post', '/hms/sys/qywx/corp/edit', options)
}

// 删除应用
export const deleteQywxCrop = options => {
  return request('post', '/hms/sys/qywx/corp/remove/:id', options)
}

/* ========== 应用配置 ============ */
// 查询系统该应用列表
export const getApplicationList = options => {
  return request('post', '/hms/sys/application/list', options)
}

// 新增应用登记
export const addApplication = options => {
  return request('post', '/hms/sys/application/add', options)
}

// 查询系统该应用详情
export const getApplicationInfo = options => {
  return request('post', '/hms/sys/application/info/:applicationId', options)
}

// 修改应用配置信息
export const editApplication = options => {
  return request('post', '/hms/sys/application/edit', options)
}

// 删除应用
export const removeApplication = options => {
  return request('post', '/hms/sys/application/remove/:id', options)
}



/* ==========组织机构============ */
// 查询组织机构树
// 质检查询 - 查询组织结构树
export const getOrgTree = options => {
  return request('post', '/hms/core/org/tree', options)
}
// 获取用户详情
export const getCoreSysUserInfo = options => {
  return request('post', '/hms/core/user/info/:userId', options)
}
// 修改用户信息
export const updateCoreSysUserInfo = options => {
  return request('post', '/hms/core/user/edit', options)
}
// 修改用户信息
export const createCoreSysUserInfo = options => {
  return request('post', '/hms/core/user/add', options)
}
// 查询账号关联的业务信息
export const createCorerelBusinessByUserId = options => {
  return request('post', '/hms/core/user/relBusiness/:userId', options)
}
// 更改用户状态
export const createCoreSwitchState = options => {
  return request('post', '/hms/core/user/switchState', options)
}
// 删除用户
export const removeUserId = options => {
  return request('post', '/hms/core/user/remove/:userId', options)
}
// 创建人
export const createAllCoreSysUserInfo = options => {
  return request('post', '/hms/core/user/all', options)
}

// 组织:获取地区json
export const fetchGhAreaList = async (options) => {
  return await request('post', '/hms/gh/common/area/list', options)
}

// 质检查询 - 获取角色绑定的用户列表
export const getCoreUserAllList = (options) => request('post', '/hms/core/role/user/allList', options)



// 查询用户常用模块
export const frequentMenu = async (options) => {
  return await request('post', '/hms/sys/oper/frequentMenu', options)
}

// 常用模块埋点
export const markFrequentMenu = async (options) => {
  return await request('post', '/hms/sys/oper/markFrequentMenu', options)
}

