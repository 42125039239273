<!--
 * @Author: 建辉
 * @Date: 2020-10-12 16:41:19
 * @LastEditors: 建辉
 * @LastEditTime: 2021-05-25 13:56:20
 * @Description: 选择区域列表
-->
<template lang="pug">
//- 任意一级
el-cascader(
  v-if="checkStrictly"
  ref="oCascaderRef"
  v-model="val"
  :options="areaList"
  @change="handleChange"
  :props="propsKey"
  v-bind="$attrs"
)
  div(slot-scope='{node,data}' @click='fnClickNode(node, data)') {{ data.name }}

el-cascader(
  v-else
  :options="areaList"
  :props="propsKey"
  v-bind="$attrs"
  @change="handleChange"
  :value="val"
  clearable
)
</template>

<script>
import {mapState} from 'vuex';
export default {
  name: 'select-area',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    // 是否可选任意一级
    checkStrictly: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      val: 0,
      defaultPropsKey: {
        label: 'name',
        value: 'code',
        expandTrigger: 'hover',
        emitPath: false
      }
    }
  },
  computed: {
    ...mapState({
      areaList: state => state.enum.system.areaList
    }),
    propsKey(){
      return {
        ...this.defaultPropsKey,
        checkStrictly: this.checkStrictly
      }
    }
  },
  watch: {
    value: {
      handler(val){
        this.val = val || 0
        // 任意一级时 选择后可隐藏 cascader
        if (this.$refs.oCascaderRef) this.$refs.oCascaderRef.dropDownVisible = false;
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetAreaList();
  },
  methods: {
    fnClickNode(node, data) {
      this.val = data.code
      this.handleChange(this.val)
    },
    handleChange(val){
      this.$emit('input', val)
      this.$emit('change', val)
      const parent = this.$parent;
      if(val && parent && parent.clearValidate){
        parent.clearValidate();
      }
    },
    fnNetGetAreaList(){
      this.$store.dispatch('enum/fetchAreaList')
    }
  },
}
</script>

<style>
</style>
