/*
 * @Author: 建辉
 * @Date: 2021-08-05 13:43:27
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-08-30 13:42:37
 * @Description: 聊天数据结构
 */

/**
 * 创建文本消息
 * @param {Object} options 消息体
 * @params String options.type 消息类型 // img mp3 mp4 file txt
 * @params String options.content 消息内容
 * @params String options.sender 当前会话的小助手的nickName
 * @params String options.img 当前小助手的头像
 * @params String options.receiver 接受者名称
 * @params String options.receiverId 接受者id
 * @params String options.time 当前时间戳
 * @params String options.isSend 1 已经发送
 * @params String options.uuId 当前会话的uuid
 * @returns
 */
export const createTxtMsg = (options) => {
  const token = localStorage.getItem('chat-token');
  const res = {
    code: 10001,
    data: options,
    token
  }
  return JSON.stringify(res);
}

export const createAckMsg = (options) => {
  const token = localStorage.getItem('chat-token');
  const res = {
    code: 10011,
    data: options,
    token
  }
  return JSON.stringify(res);
}
// 获取小助手在线列表
export const createWorkFetch = (options) => {
  const token = localStorage.getItem('chat-token');
  const res = {
    code: 40002,
    data: options,
    token
  }
  return JSON.stringify(res);
}


/**
 * 新建撤回消息
 * @param {Sting} msgId 消息id
 * @param {String} talker 当前会话的id
 */
export const createRevokeMsg = (msgId, talker) => {
  const token = localStorage.getItem('token');
  const res = {
    code: 31004,
    data: {
      id: msgId,
      talker
    },
    token
  }
  return JSON.stringify(res)
}

/**
 * this, data.avatar, lastTime, data.aliasName, data.nickName, 0, data.aliasID, 0, '', false, data.deleteFlag, 'INVALID', data.ascription, data.noRemind
 * 新建会话
 * @param {Objecet} options 新建会话配置
 * @param {String} options.avatar 头像
 * @param {String} last_time 时间
 * @param {String} aliasName 新建会话配置
 * @param {String} nickname 新建会话配置
 * @param {String} lastTime 新建会话配置
 * @param {String} wxID 新建会话配置
 * @param {String} topping 新建会话配置
 * @param {String} message 新建会话配置
 * @param {String} deleteFlag 新建会话配置
 * @param {String} robotStatus 新建会话配置
 * @param {String} ascription 新建会话配置
 * @param {String} noRemind 新建会话配置
 * @returns options
 */
export const createConversation = (data) => {
  const lastTime = new Date().getTime()
  return {
    ...data,
    avatar: data.avatar,
    lastTime: data.lastTime,
    last_time: data.lastTime,
    aliasName: data.aliasName,
    nickname: data.nickName,
    unread: 0,
    wxID: data.wxID,
    message: '[新会话]',
    deleteFlag: false,
    robotStatus: 'INVALID',
    ascription: '',
    noRemind: data.noRemind
  }
}

let myAudio = null
export const chatMessageAudio = () => {
  if (!myAudio) {
    myAudio = new Audio("https://static.fostars.com/h5/message.mp3");
  }
  localStorage.getItem('musicON') && myAudio && myAudio.play()
}


