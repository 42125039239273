<!--
 * @Author: 聂涛
 * @Date: 2022-06-10 15:18:08
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-07-12 16:02:22
 * @Description: Description
-->
<template lang="pug">
div(style="min-height:248px;")
  .flex
    .mr-10
      el-input.mb-5(
        size="medium"
        type="textarea"
        placeholder="问题"
        v-model="defaultItem.question"
      )
      el-input.my-15(
        size="medium"
        type="textarea"
        placeholder="答案"
        v-model="defaultItem.content"
      )
</template>
<script>
import _ from 'lodash'
export default {
  name: 'knowledge',
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => ({
    defaultItem: {
      question: '',
      content: ''
    }
  }),
  created() {},
  mounted() {},
  methods: {
    fnSetEditor(val) {
      if (val.materialType === 'knowledge') {
        this.defaultItem = {
          question: val.question,
          content: val.content
        }
      }
    },
    // 获取输入的内容
    fnGetEditor() {
      const tmp = _.cloneDeep(this.defaultItem)
      return tmp || '';
    },
    // 清除输入的内容
    fnClearEditor() {
      this.defaultItem = this.$options.data().defaultItem;
    }
  }
}
</script>
<style scoped lang='scss'>
</style>
<style>
.mr-10{margin-right:10px;}.mb-5{margin-bottom:5px;}.my-15{margin-top:15px;margin-bottom:15px;}</style>
