/*
 * @Author: 聂涛
 * @Date: 2021-05-13 11:45:15
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-08-16 11:07:58
 * @Description: 
 */
// 封装获取toast提示配置，合并默认值
export const getMessageOpt = {
  // get the screen height https://segmentfault.com/a/1190000010443608
  getScreenHeight(){
    return window.innerHeight
  },
  // get the message offset val
  getOffsetHeight() {
    const screenHeight = this.getScreenHeight();
    return screenHeight/2 - 50;
  },
  getOptions(options) {
    const offset = this.getOffsetHeight();
    let result = {};
    if(typeof options === 'string') {
      result = {
        offset,
        message: options,
        duration: 1500
      };
    }else {
      result = {
        ...options,
        offset,
        duration: 1500
      }
    }
    return result
  }
}

