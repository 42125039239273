<!--
 * @Author: 聂涛
 * @Date: 2022-04-14 17:15:52
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-29 11:04:01
 * @Description: 表情组建
-->
<template lang="pug">
div
    div.pre-line(v-html="emojTxt")

</template>

<script>
import { emojiChange, emojiTextList, emojiValList } from '@/utils';
import {mapState} from 'vuex';

export default {
  props: {
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      wxID: state => state.chat.currentCoversationId
    }),
    emojTxt() {
      const res = this.content;
      if (!res) return
      return emojiChange(res)
    }
  },
  methods: {

  }

}
</script>

<style lang="scss" scoped>

.pre-line {
  white-space: pre-line
}
</style>
<style>
</style>
