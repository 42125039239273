/*
 * @Author: 聂涛
 * @Date: 2021-08-04 10:55:12
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-11-07 10:20:36
 * @Description: 专门的微信模块
 */

import { awaitWrap } from "./await-wrap";
import { EventBus } from '@/utils'

// 粘贴值到输入框
export const copyTextToInput = (text) => {
  EventBus.$emit('quill-insert-text', text)
}


// 获取icon名称
export const getFileIconName = (fileUrl) => {
  let res = 'wenjian';
  if (!fileUrl) {
    return res
  }
  fileUrl = fileUrl.trim();
  const fileType = fileUrl.replace(/.+\./, "")
  switch (fileType) {
  case 'xlsx':
  case 'xls':
    res = 'xlsx'
    break;
  case 'doc':
  case 'docx':
    res = 'doc'
    break;
  case 'zip':
    res = 'zip'
    break;
  case 'rar':
    res = 'rar'
    break;
  case 'pdf':
    res = 'pdf1'
    break;
  default:
    break;
  }
  return res
}

// 获取file名称
export const getFileName = (fileUrl) => {
  const arr = fileUrl.split('/');
  const len = arr.length;
  const lastIndex = len -1;
  const name = decodeURIComponent(arr[lastIndex])
  return name;
}

// 根据type获取对应名称
export const getMsgConversationTxt = (msg) => {
  let messageText = ''
  switch (msg.type) {
  case 'txt':
    messageText = msg.content
    break;
  case 'mp4':
    messageText = '[视频]'
    break;
  case 'img':
    messageText = '[图片]'
    break;
  case 'H5':
    messageText = '[分享]'
    break;
  case 'sph':
  case 'sphlive':
    messageText = '[视频号]'
    break;
  case 'mp3':
    messageText = '[语音]'
    break;
  case 'sapp':
    messageText = '[小程序]'
    break;
  case 'system_self':
  case 'system_del':
    messageText = '[系统消息]'
    break;
  default:
    console.log('msg.type = ', msg.type);
    messageText = '[未知]'
    break;
  }
  return messageText
}

// 处理联系人分组至菜单
export const contactGroupToMenuMap = (data) => {
  const children = data.map( item => {
    return {
      name:item.groupName,
      data:{
        name: `${item.groupName}(${item.contactNum || 0}人)`,
        icon:"",
        accessUrl:`/chat?groupID=${item.id}&type=${item.type}`,
        unread: item.unreadMsgNum,
      },
      id:item.id,
      type: item.type
    }
  })
  const dynamicGroup = children.filter( item => {
    return item.type === 2
  })
  const staticGroup = children.filter( item => {
    return item.type === 1
  })

  let isTransfer = null
  if (process.client) {
    isTransfer = window.localStorage.getItem('isTransfer')
  }
  const obj = {
    children: [],
    name:"自定义分组",
    data:{
      name:"自定义分组",
      icon:"xh-group-out-l",
      accessUrl:"/chat?type=0",
      disabled: true,
    },
    id:"5"
  }
  if(isTransfer) {
    obj.children.push( {
      children: dynamicGroup,
      name:"动态分组",
      data:{
        name:"动态分组",
        icon:"",
        accessUrl:"/chat?type=2",
        disabled: true,
      },
      id:"5.1"
    })
  }
  obj.children.push( {
    children: staticGroup,
    name:"静态分组",
    data:{
      name:"静态分组",
      icon:"",
      accessUrl:"/chat?type=1",
      disabled: true,
    },
    id:"5.2"
  })
  return obj
}


// 处理固定客户状态分组至菜单 递归处理成对应数据
export const customizeGroupsToMenuMap = (data) => {
  return {
    children:childListToTree(data),
    name:"客户状态",
    data:{
      name:"客户状态",
      icon:"xh-dynamic-out-l",
      accessUrl:"/chat?type=khzt",
      disabled: true,
    },
    id:"4"
  }
}

export const childListToTree = (data) => {
  if (!data) return null
  return data.map( (item) => {
    return {
      name: item.groupName,
      children: childListToTree(item.childList),
      data: {
        name:  item.groupName,
        icon: "",
        disabled: true,
        unread: item.unreadMsgNum,
        accessUrl: '/chat?groupID=xh' + (item.parentGroupName ? item.parentGroupName + '@' : '') + item.groupName
      },
      id: item.groupName,
    }
  })
}
