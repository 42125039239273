/*
 * @Author: 建辉
 * @Date: 2021-07-28 14:14:38
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-09-09 18:25:09
 * @Description: oss上传
 */

import { isProd } from '@/constants';
import { awaitWrap, randomString } from '@/utils';
import OSS from 'ali-oss'
import { request } from './http';

const staticHost = isProd ? "https://static.fostars.com" : "https://int-static.fostars.com"

export const getSecret = () => request('post', '/nuxt-api/sts', {
  baseURL: ''
})

// 上传文件 https://help.aliyun.com/document_detail/32077.htm?spm=a2c4g.11186623.2.26.74365d60mMWPeD#section-zkq-3rq-dhb
export const uploadToOss = async (file, folderName = 'oms') => {
  const [err, data] = await getSecret()
  const fileName = randomString() + '/' + file.name
  const options = {
    accessKeyId: data.AccessKeyId,
    accessKeySecret: data.AccessKeySecret,
    stsToken: data.SecurityToken,
    region: 'oss-cn-beijing',
    bucket: isProd ? 'pub-fostars-pro' : 'pub-fostars-int',
    refreshSTSToken: async () => {
      const [refreshErr, refreshToken] = await getSecret()
      return {
        accessKeyId: refreshToken.AccessKeyId,
        accessKeySecret: refreshToken.AccessKeySecret,
        stsToken: refreshToken.SecurityToken,
      }
    }
  }
  const client = new OSS(options);
  const folderUrl = `/${folderName}/` + fileName
  const pm = client.put( `${folderUrl}`, file).then(data => {
    const path = data.url.split('aliyuncs.com')[1];
    const url = staticHost + path
    data.url = url;
    return data
  }).catch(err => {
    console(err)
  });
  return awaitWrap(pm)
}
