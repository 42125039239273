<template lang="pug">
.pt-60(style="background: #fff; height: 100vh;")
  HeaderTop
  .flex.border-top-10.menu
    .bg-white.bs-bd.pst-rlt.border-right-10
      xh-menu
      .pst-absl.h-50.b-0.l-0.r-0.pr-10.text-right.flex.flex-middle.flex-right(style="border-top: 1px solid #e6e6e6;")
        throttle-btn.toggle.theme-hover(
          :class="[collapseMenu ? 'open' : false]"
          @clickEmit="fnClickToggleMenu")
          icon(icon="zhankai1-copy" size="18")
    el-scrollbar.y-bar.container.pr-10(ref="myScroller" :style="{width: collapseMenu ? 'calc(100vw - 64px)' : 'calc(100vw - 210px)'}")
      .content
        bread(v-if="bShow")
        transition(name="fade-transform" mode="out-in")
          nuxt(:key="$route.path")
  CopyRight

  img-viewer(ref="viewer" :inline="false")
</template>

<script>
import HeaderTop from '@/components/layouts/header-top'
import CopyRight from '@/components/copyright';
import {mapState} from 'vuex';
import { bShowBreadTitle } from '@/utils';

export default {
  name: 'default',
  components: {
    HeaderTop,
    CopyRight,
  },
  computed: {
    ...mapState({
      collapseMenu: state => state.client.collapseMenu
    }),
    key(){
      return this.$route.fullpath
    },
    bShow(){
      console.log(this.$route.path)
      return bShowBreadTitle(this.$route.path);
    }
  },
  watch: {
    "$route"(){
      // - 滑动重置
      if(this.$refs.myScroller && this.$refs.myScroller.wrap){
        this.$refs.myScroller.wrap.scroll(0, 0);
      }
    }
  },
  mounted(){
    this.$store.dispatch('user/fetchPermission')
  },
  methods: {
    fnClickToggleMenu(){
      this.$store.commit('client/ToggleCollapseMenu')
    }
  }
}
</script>

<style lang="scss" scoped>
.toggle {
  transition: all linear .15s;
  &:hover{
    transform: scale(1.2);
    transform-origin: center;
  }
  &.open {
    transform: rotate(180deg);
    &:hover{
      transform: rotate(180deg) scale(1.2);
    }
  }
}
.border-top-10{
  border-top: 10px solid #f5f7fa;
}
.border-right-10{
  border-right: 10px solid #f5f7fa;
}
.content{
  min-height: 100%;
  min-width: 1050px;
}
.menu{
  height: calc(100vh - 60px - 50px);
  border-left: 10px solid #f5f7fa;
}
.container{
  height: calc(100vh - 60px - 60px);
  background: #f5f7fa;
}

// - 设置最小高度，页面不够的填充白色
::v-deep .el-scrollbar__view{
  height: 100%!important;
}
</style>

<style>
.pt-60{padding-top:60px;}.h-50{height:50px;}.b-0{bottom:0px;}.l-0{left:0px;}.r-0{right:0px;}.pr-10{padding-right:10px;}</style>
