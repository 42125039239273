<!--
 * @Author: 建辉
 * @Date: 2020-10-12 14:31:40
 * @LastEditors: 建辉
 * @LastEditTime: 2021-05-10 15:56:56
 * @Description: 渲染
-->
<template lang="pug">
span(:style="statusStyle")
    slot
</template>

<script>
import {mapState} from 'vuex'
// 状态整理文档:https://www.yuque.com/xhkj/wus4l3/eobvoh#oGEu
export default {
  name: 'cell-color',
  props: {
    status: {
      type: [Number, String],
      default: ''
    },
    // 业务线
    bsnLine: {
      type: String,
      default: ''
    },
    disagree: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapState({
      colorList: state => state.permission && state.permission.highlightColorList || []
    }),
    startKey(){
      return [this.bsnLine, this.status, this.disagree ? 'DISAGREE' : ''].filter(item => item).join('-')
    },
    targetItem(){
      const targetItem = this.colorList.find(item => {
        return item.substring(0, item.lastIndexOf('-')) === this.startKey
      })
      return targetItem;
    },
    colorStringVal(){
      if(this.targetItem){
        const colorStringArr = this.targetItem.split('-');
        const index = colorStringArr.length-1;
        const colorStringVal = colorStringArr && colorStringArr[index] || '';
        return colorStringVal
      }
      return ''
    },
    statusStyle(){
      if(this.status){
        return {
          color: this.colorStringVal
        }
      }
      return {
      }
    }
  }
}
</script>

<style>
</style>
