import _ from 'lodash'

// 处理tree
const loop = (arr, fn, parentItem = {}) => {
  arr.forEach(item => {
    fn(item, parentItem)
    if(item.children && item.children.length > 0){
      loop(item.children, fn, item)
    }
  })
}

export const loopHandler = (tree, fn) => {
  loop(tree, fn)
  return tree;
}

const filterLoop = (arr, fn, parentItem = {}) => {
  return arr.filter(item => {
    if(item.children && item.children.length > 0){
      item.children = filterLoop(item.children, fn, item)
    }
    const res = fn(item, parentItem)
    return res;
  })
}

export const loopFilterHandler = (tree = [], fn) => {
  const newTree = _.cloneDeep(tree)
  const res = filterLoop(newTree, fn)
  return res;
}
