<!--
 * @Author: wangyaqiang
 * @Date: 2022-04-15 17:03:10
 * @LastEditors: 聂涛
 * @LastEditTime: 2023-06-07 18:33:20
 * @Description: Description
-->
<template lang="pug">
div(style="min-height:248px;")
  upload(width="152" height="152" type="MultiImg" :max="max" @input="fnGetImgUrl" v-model="defaultImg" :oss="true")
</template>
<script>
export default {
  name: 'h-img',
  inheritAttrs: true,
  props: {
    max: {
      type: Number,
      default: 1,
    }
  },
  data: () => ({
    defaultImg: '',
  }),
  created() {},
  mounted() {},
  methods: {
    fnSetEditor(val) {
      if (val.materialType === 'img') {
        if (typeof val.content === 'object') {
          this.defaultImg = val.content
        } else {
          this.defaultImg = [val.content]
        }
      }
    },
    // 获取输入的内容
    fnGetImgUrl(url) {
      console.log('url:--', url, this.defaultImg)
    },
    // 获取输入的内容
    fnGetEditor() {
      return this.defaultImg || '';
    },
    // 清除输入的内容
    fnClearEditor() {
      this.defaultImg = [];
    }
  }
}
</script>
<style scoped lang='scss'>
</style>
<style>
</style>
