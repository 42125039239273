<!--
 * @Author: 聂涛
 * @Date: 2022-06-02 10:31:39
 * @LastEditors: 聂涛
 * @LastEditTime: 2024-02-07 10:15:49
 * @Description:
-->
<template lang="pug">
el-dialog(
    :visible="show"
    :showClose="false"
    width="750px"
    :append-to-body="true"
    top="15vh"
    v-bind="$attrs"
    :title="dialogTitle"
    center
  )
    .flex.flex-row.flex-between.fs-15.flex-wrap
      .flex.flex-shrink-0.flex-middle.mb-10
        .lh-22.mr-15.cs-pt(:class="activeName === idx ? 'active' :''" v-for="(group, idx) in groupList" :key="idx" @click="fnChangeActive(idx)") {{ group.categoryName }}
      .flex.mt-10
        el-input(suffix-icon="el-icon-search" placeholder="搜索标签"  v-model="tagName" @input="fnBsnGetTagList")
        xh-btn.ml-10(icon="biaoqian" type="primary" @click="showAddTag = true") 创建标签
    .mt-20(v-loading="loading")
      //- dark effect
      el-scrollbar.y-bar(
        style="height:50vh;"
        v-if="groupList.length"
      )
        h-space
          h-card(
            :title="`${group.groupName}${group.conflictFlag ? '(斥)' : ''}`"
            v-for="group in groupList[activeName].list"
            :no-style="true"
            :key="group.groupId"
          )
            h-space(direction="horizontal")
              el-tag.cs-pt(
                size="medium"
                v-for="tag in group.tagList"
                :key="tag.tagId"
                @click="fnTagAdd(tag, group)"
                :effect=" tag.selectFlag ? 'dark' : 'plain'"
              ) {{ tag.tagName }}
    .text-center.mt-20
      div(v-if="multiple")
        xh-button( @click="fnClickCancel" icon="Rectangle") 取消
        xh-button(type="primary" @click="fnClickSure" icon="xuanze")  确定
      xh-button(v-else type="primary" @click="fnClickSure" icon="Rectangle") 关闭
    TagAdd(:show.sync="showAddTag" @success="fnAddSuccess" :tagCate="categoryId")
</template>

<script>

import { tagV2Add, tagV2List, tagV2Remove } from '@/api'
import TagAdd from "@/components-pages/operation-center/tag-create/index.vue"
import { mapState } from 'vuex'

export default {
  components: {
    TagAdd
  },
  props: {
    show: {
      type: Boolean,
      default: () => true
    },
    tagCate: {
      type: String,
      default: ''
    },
    // - 通用id
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      activeName: 0,
      tagName: '',
      groupList: [],
      showAddTag: false,
    }
  },

  computed: {

    ...mapState({
      cateList: state => state.user.cateList
    }),
    cate() {
      return this.cateList.find( item => {
        return this.tagCate === item.id
      }) || {}
    },
    categoryId() {
      return this.groupList[this.activeName]?.categoryId || ''
    },
    dialogTitle() {
      // :title="'打标签-' + (cate.name || '雷达')"
      if (!this.cate?.name) {
        return '打标签'
      }
      return '打标签-' + this.cate.name
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.$store.dispatch('user/fetchCateList')
          this.fnBsnGetTagList()
        }
      }
    }
  },
  methods: {
    fnClickSure() {
      const checked = []
      this.groupList.forEach(group => {
        group.list.forEach( item => {
          item.tagList.map( tag => {
            if (tag.selectFlag) {
              checked.push(tag)
            }
          })
        })
      })
      this.$emit('fnTagSuccess', checked)
      this.fnClickCancel()
    },
    fnClickCancel() {
      this.activeName = 0
      this.tagName = ''
      this.$emit('update:show', false)
    },
    fnChangeActive(active) {
      this.activeName = active
    },
    async fnTagAdd(tag, group) {
      const method = tag.selectFlag ? tagV2Remove : tagV2Add
      if (!tag.selectFlag && group.conflictFlag) {
        group.tagList.forEach( tag => {
          tag.selectFlag = false
        })
      }
      tag.selectFlag = !tag.selectFlag
      if (this.multiple) return
      const [err, data] = await method({
        data: {
          id: this.id,
          businessType: this.tagCate,
          tagId: tag.tagId,
          tagName: tag.tagName,
          // type: this.type
        }
      })
      if (err) {
        this.$message.error('操作失败')
        tag.selectFlag = !tag.selectFlag
      }
    },
    async fnBsnGetTagList() {
      this.loading = true
      this.groupTagList = []
      const [err, data] = await tagV2List({
        data: {
          id: this.id,
          businessType: this.tagCate,
          // tagCate: this.tagCate,
          tagId: "",
          tagName: this.tagName,
          // type: this.type
        }
      })
      this.loading = false
      if (err) return
      this.groupList = data || []
    },
    fnAddSuccess() {
      this.fnBsnGetTagList()
    }
  },
}
</script>

<style lang="scss" scoped>
.active {
  color: #2874F7;
  font-weight: 600;
  border-bottom: 2px solid #2874F7;
}

</style>

<style>
.fs-15{font-size:15px;}.mb-10{margin-bottom:10px;}.lh-22{line-height:22px;}.mr-15{margin-right:15px;}.mt-10{margin-top:10px;}.ml-10{margin-left:10px;}.mt-20{margin-top:20px;}</style>
