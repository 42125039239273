<!--
 * @Author: 建辉
 * @Date: 2020-09-16 15:37:24
 * @LastEditors: 建辉
 * @LastEditTime: 2021-05-13 16:40:49
 * @Description: 用户状态
-->
<template lang="pug">
div
  el-radio-group(
    v-if="type === 'radio'"
    v-model="val"
    v-bind="$attrs"
    @change="fnHandleChangeVal"
  )
    el-radio(key="" label="" v-if="showAll") 全部
    el-radio(v-for="(item, i) in aList" :key="item.id" :label="item.value") {{ item.name }}
  el-select(
    v-if="type === 'select'"
    v-model="val"
    @change="fnHandleChangeVal"
  )
    el-option(v-if="showAll" value="" label="全部")
    el-option(v-for="(item, i) in aList" :value="item.value" :label="item.name" :key="item.id")
</template>

<script>
import {fetchDictTypeList} from '@/api';

export default {
  name: 'select-org-type',
  props: {
    type: {
      type: String,
      default: 'radio'
    },
    showAll: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      val: '',
      aList: []
    }
  },
  watch: {
    value: {
      handler(val){
        this.val = val
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetUserStatus();
  },
  methods: {
    fnHandleChangeVal(val){
      this.$emit('input', val)
      this.$emit('change', val)
    },
    async fnNetGetUserStatus(){
      const [err, data] = await fetchDictTypeList({
        data: {
          type: 'org_type'
        }
      });
      if(err) return;
      console.log(data)
      this.aList = data;
    }
  }
}
</script>

<style>
</style>
