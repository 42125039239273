import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _01238a6c = () => interopDefault(import('../pages/order/list/index.vue' /* webpackChunkName: "pages/order/list/index" */))
const _5f762c9e = () => interopDefault(import('../pages/refund/list/index.vue' /* webpackChunkName: "pages/refund/list/index" */))
const _270cba6b = () => interopDefault(import('../pages/settle/list/index.vue' /* webpackChunkName: "pages/settle/list/index" */))
const _696fbf1c = () => interopDefault(import('../pages/agent/org/add.vue' /* webpackChunkName: "pages/agent/org/add" */))
const _fe77f3e0 = () => interopDefault(import('../pages/agent/org/detail.vue' /* webpackChunkName: "pages/agent/org/detail" */))
const _3a3a6d9d = () => interopDefault(import('../pages/agent/org/list.vue' /* webpackChunkName: "pages/agent/org/list" */))
const _461381d1 = () => interopDefault(import('../pages/order/list/dialog-group-order.vue' /* webpackChunkName: "pages/order/list/dialog-group-order" */))
const _6d239b13 = () => interopDefault(import('../pages/order/list/dialog-policy-underwrite.vue' /* webpackChunkName: "pages/order/list/dialog-policy-underwrite" */))
const _1d1d4912 = () => interopDefault(import('../pages/sys/menu/list.vue' /* webpackChunkName: "pages/sys/menu/list" */))
const _7bcbd3ba = () => interopDefault(import('../pages/sys/org/add.vue' /* webpackChunkName: "pages/sys/org/add" */))
const _a23fa470 = () => interopDefault(import('../pages/sys/org/detail.vue' /* webpackChunkName: "pages/sys/org/detail" */))
const _cba5b956 = () => interopDefault(import('../pages/sys/org/list.vue' /* webpackChunkName: "pages/sys/org/list" */))
const _fbe2e6fc = () => interopDefault(import('../pages/sys/roles/add-function.vue' /* webpackChunkName: "pages/sys/roles/add-function" */))
const _c9a37ac8 = () => interopDefault(import('../pages/sys/roles/list.vue' /* webpackChunkName: "pages/sys/roles/list" */))
const _570788f7 = () => interopDefault(import('../pages/order/list/detail/_.vue' /* webpackChunkName: "pages/order/list/detail/_" */))
const _22d1f42c = () => interopDefault(import('../pages/sys/roles/_id.vue' /* webpackChunkName: "pages/sys/roles/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/order/list",
    component: _01238a6c,
    name: "order-list"
  }, {
    path: "/refund/list",
    component: _5f762c9e,
    name: "refund-list"
  }, {
    path: "/settle/list",
    component: _270cba6b,
    name: "settle-list"
  }, {
    path: "/agent/org/add",
    component: _696fbf1c,
    name: "agent-org-add"
  }, {
    path: "/agent/org/detail",
    component: _fe77f3e0,
    name: "agent-org-detail"
  }, {
    path: "/agent/org/list",
    component: _3a3a6d9d,
    name: "agent-org-list"
  }, {
    path: "/order/list/dialog-group-order",
    component: _461381d1,
    name: "order-list-dialog-group-order"
  }, {
    path: "/order/list/dialog-policy-underwrite",
    component: _6d239b13,
    name: "order-list-dialog-policy-underwrite"
  }, {
    path: "/sys/menu/list",
    component: _1d1d4912,
    name: "sys-menu-list"
  }, {
    path: "/sys/org/add",
    component: _7bcbd3ba,
    name: "sys-org-add"
  }, {
    path: "/sys/org/detail",
    component: _a23fa470,
    name: "sys-org-detail"
  }, {
    path: "/sys/org/list",
    component: _cba5b956,
    name: "sys-org-list"
  }, {
    path: "/sys/roles/add-function",
    component: _fbe2e6fc,
    name: "sys-roles-add-function"
  }, {
    path: "/sys/roles/list",
    component: _c9a37ac8,
    name: "sys-roles-list"
  }, {
    path: "/order/list/detail/*",
    component: _570788f7,
    name: "order-list-detail-all"
  }, {
    path: "/sys/roles/:id?",
    component: _22d1f42c,
    name: "sys-roles-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
