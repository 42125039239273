<!--
 * @Author: 建辉
 * @Date: 2021-05-07 09:57:23
 * @LastEditors: 聂涛
 * @LastEditTime: 2022-10-13 10:36:04
 * @Description: 左侧菜单组件
-->

<template lang="pug">
div
  el-menu.el-menu-vertical-demo(
    ref="menu"
    :router="true"
    @select="fnHandleSelect"
    :default-active="defaultActive"
    :collapse="collapseMenu"
    )
    menu-children(:menuList="navList")
</template>

<script>
import MenuChildren from './menu-children';
import {mapState} from 'vuex';
const routeList = [
  '/account',
  '/pwd/reset',
  '/qwt/business-plan/daily',
  '/business/'
]
export default {
  name: 'xh-menu',
  components: {
    MenuChildren,
  },
  data(){
    return {
      defaultActive: '',
      selfNavList: [],
    }
  },
  computed: {
    ...mapState({
      collapseMenu: state => state.client.collapseMenu,
      menuList: state => state.user.permission.menuList,
      topActiveTabIndex: state => state.user.menu.topActiveTabIndex,
      chatMenuList: state => state.chat.chatMenuList
    }),
    navList(){
      return this.menuList.children[this.topActiveTabIndex] && this.menuList.children[this.topActiveTabIndex].children || []
    },
    chatList() {
      return this.chatMenuList
    }

  },
  watch: {
    $route: {
      handler(val){
        setTimeout(() => {
          if(val.path.startsWith('/chat')) {
            this.defaultActive = val.fullPath
          } else if(val.path.endsWith('list') || routeList.includes(val.path)){
            this.defaultActive = val.path
          }else if (val.path.startsWith('/third')) {
            this.defaultActive = val.query.ref
          } else {
            const arr = val.path.split('/');
            arr[arr.length - 1] = 'list'
            this.defaultActive = arr.join('/')
          }
        }, 30);
      },
      immediate: true
    }
  },
  methods: {
    fnHandleSelect(index, indexPath){
      // console.log(index, indexPath)
      this.defaultActive = index;
    }
  }
}
</script>

<style>
  .el-menu {
    border-right: none;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 210px;
    min-height: 400px;
  }
  .el-menu--collapse .el-menu-item span, .el-menu--collapse .el-submenu>.el-submenu__title span,
  .el-menu--collapse .el-menu-item .el-submenu__icon-arrow, .el-menu--collapse .el-submenu>.el-submenu__title .el-submenu__icon-arrow{
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline-block;
  }
</style>

<style>
</style>
