<!--
 * @Author: 建辉
 * @Date: 2020-09-17 11:19:55
 * @LastEditors: 建辉
 * @LastEditTime: 2021-05-10 19:36:10
 * @Description: 选择数据权限类型
-->
<template lang="pug">
el-select(v-model="val" @change="fnHandleChange")
    el-option(v-for="(item, i) in aList" :key="item.id" :label="item.name" :value="item.id")
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'select-data-type',
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data(){
    return {
      val: '',
    }
  },
  computed: {
    ...mapState('enum', {
      aList: state => state.system && state.system.accessDataEnumList || []
    })
  },
  watch: {
    value: {
      handler(val){
        this.val = val
      },
      immediate: true
    }
  },
  mounted(){
    this.fnNetGetAccessData()
  },
  methods: {
    fnHandleChange(val){
      this.$emit('input', val)
    },
    fnNetGetAccessData(){
      this.$store.dispatch('enum/fetchSysAccessDataEnumList')
    }
  }
}
</script>

<style>
</style>
