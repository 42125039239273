import { request } from './http'

// 菜单设置
export const menuTree = options => {
  return request('post', '/hms/menu/tree', options)
}

// 菜单下 的功能点
export const menuFunctionList = options => {
  return request('post', '/hms/menu/functionList', options)
}

// 删除菜单下的功能点
export const delCoreSysMenuFunc = async (options) => {
  return await request('post', '/hms/core/function/del', options)
}

// 增加菜单下的功能点
export const addCoreSysMenuFunc = async (options) => {
  return await request('post', '/hms/core/function/add', options)
}

// 修改菜单下的功能点
export const updateCoreSysMenuFunc = async (options) => {
  return await request('post', '/hms/core/function/edit', options)
}


// 新增菜单
export const addCoreSysMenu = async (options) => {
  return await request('post', '/hms/menu/add', options)
}

// 修改菜单
export const updateCoreSysMenu = async (options) => {
  return await request('post', '/hms/menu/edit', options)
}

// 移动菜单
export const MoveCoreSysMenu = async (options) => {
  return await request('post', '/hms/menu/moveLevel', options)
}

