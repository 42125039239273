<!--
 * @Author: 建辉
 * @Date: 2021-05-07 18:30:05
 * @LastEditors: wangyaqiang
 * @LastEditTime: 2022-10-17 17:55:26
 * @Description: 面包屑
-->

<template lang="pug">
div.layout-page-head(v-if="!['/', '/dashboard/list'].includes($route.path)")
  el-breadcrumb(separator-class="el-icon-arrow-right" v-if="breadList.length > 1")
    el-breadcrumb-item(
      v-for="(item, i) in breadList"
      :key="i"
    )
      span(
        @click="handleClick(item, i)"
        :class="[isLink(item, i) ? 'theme-hover cs-pt fw-bold' : '']") {{ item.title }}
  div.title(v-if="lastNavItem") {{ lastNavItem.title }}
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'bread',
  computed: {
    ...mapState({
      breadList: state => state.client.breadList
    }),
    slicedBradList(){
      return this.breadList?.slice(2) || []
    },
    lastNavItem(){
      return this.breadList?.length > 0 ? this.breadList[this.breadList.length-1] : null
    },
  },
  methods: {
    isLink(item, i){
      if(item.fullPath && i < this.breadList.length - 1){
        return true
      } else {
        return false;
      }
    },

    handleClick(item, i){
      if(i === this.slicedBradList.length - 1) return;
      if(item.fullPath){
        this.$router.replace(item.fullPath)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.is-link {
  &:hover {
    color: #208dfd;
  }
}
.layout-page-head {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  position: relative;
  background-color: transparent;
  // padding-block: 6px;
  padding-block-start: 8px;
  padding-block-end: 12px;
  .el-breadcrumb + .title {
    margin-top: 8px;
  }
  .title {
    margin-block-end: 0;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>


<style>
</style>
